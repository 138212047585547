<template>
  <div class="sentence re-container">
    <div class="unit-bar">
      <div class="bar-l">
        <i class="fa fa-book"></i>
        <template v-if="studyType == 1">
            <span>{{ course.name }}-</span>-<span>{{ danyuan.name }}</span>
          </template>
        <!--          <span v-if="studyType==2">单词复习中</span>-->
        <!--          <span v-if="studyType==3">错词学习中</span>-->
      </div>
      <div class="bar-r">
        <i class="audio"></i>
        <i class="iconfont icon-tuichu2" @click="emitClose"></i>
      </div>
    </div>
    <div class="unit-bar__progress">
      <el-progress
        :percentage="processSpeed"
        :show-text="textShow"
      ></el-progress>
      <img
        class="sport-img"
        src="@/assets/images/study/sport.png"
        alt=""
        style="width: 40px; height: 40px; left: 0"
      />
    </div>
    <div
      class="sentence-body translate"
      v-if="study_mk[study_mk_index] === 0 && !is_show_result"
    >
      <div class="re-body" v-if="study_child_mk[study_child_mk_index] === 0">
        <div class="re-ready">
          <div class="re-text">
            <span class="item-dot">●</span>
            <span>即将开始学习, let's go</span>
          </div>
        </div>
        <el-button class="rem-btn" type="primary" @click="startXuexi"
          >开始学习
        </el-button>
      </div>      
      <div class="sen-body" v-if="study_child_mk[study_child_mk_index] === 1">
        <div class="sen-section">
          <div class="section__title">
            <p>{{now_words.info}}</p>
            <i class="fa fa-volume-up" @click="audioPlay_3(now_words.audio_link, now_words.id)"></i>
          </div>
          <el-divider></el-divider>
          <div class="section__text">
            <p :style="{ 'font-size': (/[\u4e00-\u9fa5]/).test(now_words.chinese) ? '24px' : '28px' }">{{now_words.chinese}}</p>
            <div class="section-time">
              <div class="faArrow">
                <i class="fa fa-arrow-circle-right" @click="yuxiNext"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 选择题，目前不需要，请隐藏 -->
      <div class="sen-body" v-if="study_child_mk[study_child_mk_index] === 100">
        <div class="countdown">
          <i class="fa fa-sun-o time-icon"></i>
          <span class="custom-textshadow">5</span>
        </div>
        <div class="sen-section">
          <div class="section__title">
            <p>I like that apple.</p>
            <i class="fa fa-volume-up" @click="audioPlay_3(now_words.audio_link, now_words.id)"></i>
          </div>
          <el-divider></el-divider>
          <div class="section__text">
            <ul class="section-choose">
              <template v-for="(item, index) in chooseItem">
                <li
                  class="
                    section-choose__option
                    item-success
                    d-flex
                    justify-content-between
                    align-items-center
                  "
                  :key="index"
                >
                  <span>
                    <span class="section-choose__item">{{ item.title }}</span>
                    <span class="section-choose__answer">{{
                      item.answer
                    }}</span>
                  </span>
                  <!--                                    <i class="fa fa-close"></i>-->
                  <i class="fa fa-check"></i>
                </li>
              </template>
            </ul>
            <div class="section-time">
              <div class="faArrow">
                <i class="fa fa-arrow-circle-right" @click="next"></i>
              </div>
              <div class="emmo d-flex flex-column align-items-center">
                <i class="fa fa-frown-o"></i>
                <span>我不会</span>
              </div>
              <div class="emmo d-flex flex-column align-items-center">
                <i class="iconfont icon-lianlianlian-01"></i>
                <span>背一背</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sen-body" v-if="study_child_mk[study_child_mk_index] === 2">
        <div class="sen-section">
<!--            <p class="title-result" style="visibility: hidden;">占位隐藏区域</p>-->
          <div class="section__title section-title" style="flex-direction: column">
            <p class="title-result" :style="{ 'font-size': (/[\u4e00-\u9fa5]/).test(now_words.info) ? '24px' : '28px' }">
                {{now_words.info}}
            </p>
            <div class="title-answer__container">
              <div class="title-answer">
                <template v-for="(item, index) in zh_limit_word_list">
                  <span
                     :class="['wait-clear', {'clear-border':item.value==''}]"
                     :style="(/[\u4e00-\u9fa5]/).test(item.value) ? zhStyleObj : ''"
                    :key="index"
                    @click="del_word(index,item)"
                    v-if="item.type==0"
                    >{{item.value}}</span>
                  <span class="dot" v-if="item.type==1" :key="index">{{item.value}}</span>
                </template>
                <!--<span class="dot">.</span>-->
                <i class="iconfont icon-broom" v-if="words_xuexi_status==0 || words_xuexi_status==4" @click="clearAllWords"></i>
                <i class="iconfont icon-V" style="color: #2bc2a1" v-if="words_xuexi_status==1"></i>
                <i class="iconfont icon-V1" style="color: #d81e06" v-if="words_xuexi_status==2"></i>
              </div>
              <i class="fa fa-volume-up" @click="audioPlay_3(now_words.audio_link, now_words.id)"></i>
            </div>
          </div>
          <el-divider></el-divider>
          <div class="section__text">
            <div class="text-word__container">
              <p :style="{ 'font-size': (/[\u4e00-\u9fa5]/).test(now_words.chinese) ? '24px' : '28px' }" v-if="words_xuexi_status==3 || words_xuexi_status==4">{{now_words.chinese}}</p>
              <div class="text-word__content" v-if="words_xuexi_status==0 || words_xuexi_status==4">
                <span
                  class="text-word"
                  :class="[in_array(index, xz_words) >= 0 ? 'word-choose' : '']"
                  :style="{ 'font-size': (/[\u4e00-\u9fa5]/).test(item) ? '24px' : '28px' }"
                  v-for="(item, index) in now_words.zh_zimu_list"
                  :key="index"
                  @click="set_words(index)"
                >
                  {{ item }}
                </span>
              </div>
            </div>
            <div class="section-time">
              <div class="faArrow" v-if="words_xuexi_status==0 || words_xuexi_status==2" @click="foget01">
                <i class="fa fa-frown-o"></i>
              </div>
              <div class="emmo">
                <i class="iconfont icon-lianlianlian-01" v-if="words_xuexi_status==3" @click="lianyxi"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sen-body" v-if="study_child_mk[study_child_mk_index] === 3">
        <div class="sen-section">
          <div class="section__title section-title" style="flex-direction: column">
<!--              <p class="title-result" style="visibility: hidden;" v-if="words_xuexi_status!=4">占位隐藏区域</p>-->
            <p class="title-result" :style="{ 'font-size': (/[\u4e00-\u9fa5]/).test(now_words.chinese) ? '24px' : '28px' }" >
                {{now_words.chinese}}
            </p>
            <div class="title-answer__container">
              <div class="title-answer">
                <template v-for="(item, index) in limit_word_list">
                <span
                    :class="['wait-clear',{'clear-border':item.value==''}]"
                    :style="(/[\u4e00-\u9fa5]/).test(item.value) ? zhStyleObj : ''"
                    :key="index"
                    @click="del_en_word(index,item)"
                    v-if="item.type==0"
                    >{{item.value}}</span>
                  <span class="dot" v-if="item.type==1" :key="index">{{item.value}}</span>
                </template>

                <!--<span class="dot">.</span>-->
                <i class="iconfont icon-broom" v-if="words_xuexi_status == 0 || words_xuexi_status==4" @click="clearAllWords"></i>
                <i class="iconfont icon-V" style="color: #2bc2a1" v-if="words_xuexi_status==1"></i>
                <i class="iconfont icon-V1" style="color: #d81e06" v-if="words_xuexi_status==2"></i>
              </div>
              <i class="fa fa-volume-up" @click="audioPlay_3(now_words.audio_link, now_words.id)"></i>
            </div>
          </div>
          <el-divider></el-divider>
          <div class="section__text">
            <div class="text-word__container">
              <p :style="{ 'font-size': (/[\u4e00-\u9fa5]/).test(now_words.info) ? '24px' : '28px' }" v-if="words_xuexi_status==3 || words_xuexi_status==4">{{now_words.info}}</p>
              <div class="text-word__content" v-if="words_xuexi_status==0 || words_xuexi_status==4">
                <span
                   class="text-word"
                  :class="[in_array(index, xz_words) >= 0 ? 'word-choose' : '']"
                   :style="{ 'font-size': (/[\u4e00-\u9fa5]/).test(item) ? '24px' : '28px' }"
                  v-for="(item, index) in now_words.zimu_list"
                  :key="index"
                  @click="set_words_en(index)"
                >
                  {{ item }}
                </span>
              </div>
            </div>
            <div class="section-time">
              <div class="faArrow" v-if="words_xuexi_status==0 || words_xuexi_status==2" @click="foget01">
                <i class="fa fa-frown-o"></i>
              </div>
              <div class="emmo">
                <i class="iconfont icon-lianlianlian-01" v-if="words_xuexi_status==3" @click="lianyxi"></i>
              </div>
            </div>
          </div>
        </div>
      </div>      
      <div class="re-body" v-if="study_child_mk[study_child_mk_index] === 4">
          <div class="re-ready">
              <div class="re-text">
                  <span class="item-dot">●</span>
                  <span>进入下一组,继续学习，go!go!go!</span>
              </div>
          </div>
          <el-button class="rem-btn" type="primary" @click="studyNextLun">继续学习</el-button>
      </div>

    </div>
    <div
      class="sentence-body listen"
      v-if="study_mk[study_mk_index] === 1 && !is_show_result"
    >
      <div class="re-body" v-if="study_child_mk[study_child_mk_index] === 0">
        <div class="re-ready">
          <div class="re-text">
            <span class="item-dot">●</span>
            <span>即将开始学习, let's go</span>
          </div>
        </div>
        <el-button class="rem-btn" type="primary" @click="startXuexi">开始学习 </el-button>
      </div>
      <div class="sen-body" v-if="study_child_mk[study_child_mk_index] === 1">
        <div class="sen-section">
          <div class="section__title">
            <p>{{now_words.info}}</p>
            <i class="fa fa-volume-up" @click="audioPlay_3(now_words.audio_link, now_words.id)"></i>
          </div>
          <el-divider></el-divider>
          <div class="section__text">
            <p :style="{ 'font-size': (/[\u4e00-\u9fa5]/).test(now_words.chinese) ? '24px' : '28px' }">{{now_words.chinese}}</p>
            <div class="section-time">
              <div class="faArrow">
                <i class="fa fa-arrow-circle-right" @click="yuxiNext"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sen-body" v-if="study_child_mk[study_child_mk_index] === 100">
        <!--<div class="countdown">
                        <i class="fa fa-sun-o time-icon"></i>
                        <span class="custom-textshadow">5</span>
                    </div>-->
        <div class="sen-section">
          <div class="section__title section-title">
<!--                                    <p>I like that apple.</p>-->
            <i class="fa fa-volume-up" @click="audioPlay_3(now_words.audio_link, now_words.id)"></i>
          </div>
          <el-divider></el-divider>
          <div class="section__text">
            <ul class="section-choose">
              <template v-for="(item, index) in chooseItems">
                <li
                  class="
                    section-choose__option
                    item-success
                    d-flex
                    justify-content-between
                    align-items-center
                  "
                  :key="index"
                >
                  <span class="section-choose__option-english">
                    <span class="section-choose__item">{{ item.title }}</span>
                    <span class="section-choose__answer">{{
                      item.answer
                    }}</span>
                  </span>
                  <!--<i class="fa fa-close"></i>-->
                  <i class="fa fa-check"></i>
                </li>
              </template>
            </ul>
            <div class="section-time">
              <!--<div class="faArrow">
                                <i class="fa fa-arrow-circle-right"></i>
                            </div>-->
              <div class="emmo d-flex flex-column align-items-center">
                <i class="fa fa-frown-o"></i>
                <span>我不会</span>
              </div>
              <div class="emmo d-flex flex-column align-items-center">
                <i class="iconfont icon-lianlianlian-01"></i>
                <span>背一背</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 多选, 目前不需要，请隐藏！！！ -->
      <div class="sen-body" v-if="study_child_mk[study_child_mk_index] === 100">
        <div class="sen-section">
          <div class="section__title section-title">
            <i class="fa fa-volume-up" @click="audioPlay_3(now_words.audio_link, now_words.id)"></i>
          </div>
          <div class="section__text">
            <ul class="section-checkbox">
              <template v-for="(item, index) in checkbox">
                <li class="section-checkbox__option" :key="index">
                  <!--                                <el-checkbox v-model="checked">那个苹果</el-checkbox>-->
                  <label
                    :for="item.title"
                    class="d-flex align-items-center"
                    @click.stop="changeChecked(index)"
                  >
                    <input type="checkbox" :id="item.title" />
                    <span :class="{ 'item-success': checked }">{{
                      item.answer
                    }}</span>
                  </label>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>
      <!-- 听力填空 -->
      <div class="sen-body" v-if="study_child_mk[study_child_mk_index] === 2">
        <div class="sen-section">
          <div class="section__title section-title" style="flex-direction: column">
              <p class="title-result" style="visibility: hidden;" v-if="words_xuexi_status!=4">占位隐藏区域</p>
            <p class="title-result word-check" v-if="words_xuexi_status==3 || words_xuexi_status==4">{{now_words.info}}</p>
            <div class="title-answer__container">
               <div class="title-answer">
                <template v-for="(item, index) in limit_word_list">
                  <span
                    :class="['wait-clear',{'clear-border':item.value==''}]"
                    :style="(/[\u4e00-\u9fa5]/).test(item.value) ? zhStyleObj : ''"
                    :key="index"
                    @click="del_en_word(index,item)"
                    v-if="item.type==0"
                    >{{item.value}}</span>
                  <span class="dot" v-if="item.type==1" :key="index">{{item.value}}</span>
                </template>
                <!--<span class="dot">.</span>-->
                <i class="iconfont icon-broom" v-if="words_xuexi_status == 0 || words_xuexi_status == 4" @click="clearAllWords"></i>
                <i class="iconfont icon-V" style="color: #2bc2a1" v-if="words_xuexi_status==1"></i>
                <i class="iconfont icon-V1" style="color: #d81e06" v-if="words_xuexi_status==2"></i>
              </div>
              <i class="fa fa-volume-up" @click="audioPlay_3(now_words.audio_link, now_words.id)"></i>
            </div>
          </div>
          <el-divider></el-divider>
          <div class="section__text">
            <div class="text-word__container">
              <p :style="{ 'font-size': (/[\u4e00-\u9fa5]/).test(now_words.chinese) ? '24px' : '28px' }" v-if="words_xuexi_status==3 || words_xuexi_status==4">{{now_words.chinese}}</p>
              <div class="text-word__content" v-if="words_xuexi_status==0 || words_xuexi_status==4">
                <span
                   class="text-word"
                  :class="[in_array(index, xz_words) >= 0 ? 'word-choose' : '']"
                   :style="{ 'font-size': (/[\u4e00-\u9fa5]/).test(item) ? '24px' : '28px' }"
                  v-for="(item, index) in now_words.zimu_list"
                  :key="index"
                  @click="set_words_en(index)"
                >
                  {{ item }}
                </span>
              </div>
            </div>
            <div class="section-time">
              <div class="faArrow" v-if="words_xuexi_status==0 || words_xuexi_status==2" @click="foget01">
                <i class="fa fa-frown-o"></i>
              </div>
              <div class="emmo">
                <i class="iconfont icon-lianlianlian-01" v-if="words_xuexi_status==3" @click="lianyxi"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sen-body" v-if="study_child_mk[study_child_mk_index] === 3">
        <div class="sen-section">
          <div class="section__title section-title" style="flex-direction: column">
              <p class="title-result" style="visibility: hidden;" v-if="words_xuexi_status!=4">占位隐藏区域</p>
            <p class="title-result word-check" v-if="words_xuexi_status==3 || words_xuexi_status==4">{{now_words.info}}</p>
            <div class="title-answer__container">
              <div class="title-answer">
                <template v-for="(item, index) in zh_limit_word_list">
                  <span
                    :class="['wait-clear',{'clear-border':item.value==''}]"
                    :style="(/[\u4e00-\u9fa5]/).test(item.value) ? zhStyleObj : ''"
                    :key="index"
                    @click="del_word(index,item)"
                    v-if="item.type==0"
                    >{{item.value}}</span>
                  <span class="dot" v-if="item.type==1" :key="index">{{item.value}}</span>
                </template>
                <!--<span class="dot">.</span>-->
                <i class="iconfont icon-broom" v-if="words_xuexi_status == 0 || words_xuexi_status == 4" @click="clearAllWords"></i>
                <i class="iconfont icon-V" style="color: #2bc2a1" v-if="words_xuexi_status==1"></i>
                <i class="iconfont icon-V1" style="color: #d81e06" v-if="words_xuexi_status==2"></i>
              </div>
              <i class="fa fa-volume-up" @click="audioPlay_3(now_words.audio_link, now_words.id)"></i>
            </div>
          </div>
          <el-divider></el-divider>
          <div class="section__text">
            <div class="text-word__container">
              <p :style="{ 'font-size': (/[\u4e00-\u9fa5]/).test(now_words.chinese) ? '24px' : '28px' }" v-if="words_xuexi_status==3 || words_xuexi_status==4">{{now_words.chinese}}</p>
              <div class="text-word__content" v-if="words_xuexi_status==0 || words_xuexi_status==4">
                <span
                  class="text-word"
                  :class="[in_array(index, xz_words) >= 0 ? 'word-choose' : '']"
                  :style="{ 'font-size': (/[\u4e00-\u9fa5]/).test(item) ? '24px' : '28px' }"
                  v-for="(item, index) in now_words.zh_zimu_list"
                  :key="index"
                  @click="set_words(index)"
                >
                  {{ item }}
                </span>
              </div>
            </div>
            <div class="section-time">
              <div class="faArrow" v-if="words_xuexi_status==0 || words_xuexi_status==2" @click="foget01">
                <i class="fa fa-frown-o"></i>
              </div>
              <div class="emmo">
                <i class="iconfont icon-lianlianlian-01" v-if="words_xuexi_status==3" @click="lianyxi"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
       <div class="re-body" v-if="study_child_mk[study_child_mk_index] === 4">
          <div class="re-ready">
              <div class="re-text">
                  <span class="item-dot">●</span>
                  <span>进入下一组,继续学习，go!go!go!</span>
              </div>
          </div>
          <el-button class="rem-btn" type="primary" @click="studyNextLun">继续学习</el-button>
      </div>
    </div>
    <div
      class="sentence-body write"
      v-if="study_mk[study_mk_index] === 2 && !is_show_result"
    >
      <div class="re-body"  v-if="study_child_mk[study_child_mk_index] === 0">
        <div class="re-ready">
          <div class="re-text">
            <span class="item-dot">●</span>
            <span>即将开始学习, let's go</span>
          </div>
        </div>
        <el-button class="rem-btn" type="primary" @click="startXuexi">开始学习 </el-button>
      </div>
      <div class="sen-body" v-if="study_child_mk[study_child_mk_index] === 1">
        <div class="sen-section">
          <div class="section__title">
            <p>{{now_words.info}}</p>
            <i class="fa fa-volume-up" @click="audioPlay_3(now_words.audio_link, now_words.id)"></i>
          </div>
          <el-divider></el-divider>
          <div class="section__text">
            <p :style="{ 'font-size': (/[\u4e00-\u9fa5]/).test(now_words.chinese) ? '24px' : '28px' }">{{now_words.chinese}}</p>
            <div class="section-time">
              <div class="faArrow">
                <i class="fa fa-arrow-circle-right" @click="yuxiNext"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sen-body"  v-if="study_child_mk[study_child_mk_index] === 2">
        <div class="section-input" >
          <div class="section-input__title">
            <i class="fa fa-volume-up" @click="audioPlay_3(now_words.audio_link, now_words.id)"></i>
            <p class="section-input__text">
              <template v-for="(item,index) in xz_words">
                    <span class="dot" v-if="item.name" :key="index">{{item.input}}</span>
                    <el-input v-else v-model.trim="item.input" v-focus="true" :key="index"  style="min-width: 32px;" @input="changeWidth(index,item)"></el-input>
              </template>
            </p>
          </div>
          <div class="section-input__footer">
            <div class="section-input__btn">
              <span @click="lijuComfirm_01">确认</span>
              <span @click="lijuForget">不记得了</span>
            </div>
            <div class="section-input__tips">
              【 温馨提示：请根据上下文进行填空 】
            </div>
          </div>
        </div>
      </div>
      <div class="sen-body" v-if="study_child_mk[study_child_mk_index] === 3">
        <div class="section-input">
          <div class="section-input__title">
            <div class="section-input__text">
              <span>{{now_words.info}}</span>
              <i class="fa fa-volume-up" @click="audioPlay_3(now_words.audio_link, now_words.id)"></i>
            </div>
            <p class="section-input__info">{{now_words.chinese}}</p>
          </div>
          <el-input class="section-input__area" v-model="px_answer" v-focus v-on:input="pin_input(px_answer)" ref="pinInput"></el-input>
          <div class="section-input__footer">
            <div class="section-input__btn">
              <span @click="myMaster">我已掌握</span>
              <span @click="resetScanning">重新抄写</span>
            </div>
            <div class="section-input__tips">
              【 温馨提示：请正确抄写句子（区分大小写和标点）！ 】
            </div>
          </div>
        </div>
      </div>
      <div class="sen-body"  v-if="study_child_mk[study_child_mk_index] === 4">
        <div class="section-input">
<!--            <p class="title-result" style="visibility: hidden;">占位隐藏区域</p>-->
          <div class="section-input__content">
            <p v-if="words_xuexi_status==2 || words_xuexi_status==3">{{now_words.chinese}}</p>
              <p class="p-hidden" v-else style="visibility: hidden;"></p>
              <div class="section-input__answer">
              <i class="fa fa-headphones" @click="audioPlay_3(now_words.audio_link, now_words.id)"></i>
              <el-input v-model="px_answer" v-focus v-on:input="pin_input(px_answer)" ref="pinInput"></el-input>
              <i class="iconfont icon-V" style="color: #00b68d" v-if="words_xuexi_status == 1"></i>
              <i class="iconfont icon-V1" style="color: #d9001b" v-if="words_xuexi_status == 2"></i>
                  <i class="fa" v-if="words_xuexi_status != 1 && words_xuexi_status != 2 " style="visibility: hidden;"></i>
            </div>
            <p v-if="words_xuexi_status == 2 || words_xuexi_status==3">{{now_words.info}}</p>
          </div>
          <div class="section-input__footer">
            <div class="section-input__btn">
              <span @click="lijuComfirm_02">确认</span>
            </div>
            <div class="section-input__tips">
              【 温馨提示：请根据上下文进行填空 】
            </div>
          </div>
        </div>
      </div>
       <div class="re-body" v-if="study_child_mk[study_child_mk_index] === 5">
          <div class="re-ready">
              <div class="re-text">
                  <span class="item-dot">●</span>
                  <span>进入下一组,继续学习，go!go!go!</span>
              </div>
          </div>
          <el-button class="rem-btn" type="primary" @click="studyNextLun">继续学习</el-button>
      </div>
    </div>
    <div
      class="sentence-body dictation"
      v-if="study_mk[study_mk_index] === 3 && !is_show_result"
    >
      <div class="re-body" v-if="study_child_mk[study_child_mk_index] === 0">
        <div class="re-ready">
          <div class="re-text">
            <span class="item-dot">●</span>
            <span>即将开始学习, let's go</span>
          </div>
        </div>
        <el-button class="rem-btn" type="primary" @click="startXuexi">开始学习</el-button>
      </div>
      <div class="sen-body" v-if="study_child_mk[study_child_mk_index] === 1">
        <div class="sen-section">
          <div class="section__title">
            <p>{{now_words.info}}</p>
            <i class="fa fa-volume-up" @click="audioPlay_3(now_words.audio_link, now_words.id)"></i>
          </div>
          <el-divider></el-divider>
          <div class="section__text">
            <p :style="{ 'font-size': (/[\u4e00-\u9fa5]/).test(now_words.chinese) ? '24px' : '28px' }">{{now_words.chinese}}</p>
            <div class="section-time">
              <div class="faArrow">
                <i class="fa fa-arrow-circle-right" @click="yuxiNext"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sen-body" v-if="study_child_mk[study_child_mk_index] === 2">
        <div class="section-input" >
          <div class="section-input__title">
<!--            <i class="fa fa-volume-up"></i>-->
            <span class="title-text" :style="{ 'font-size': (/[\u4e00-\u9fa5]/).test(now_words.chinese) ? '24px' : '28px' }">
                {{now_words.chinese}}
            </span>
            <span class="title-text" style="display:none">{{now_words.info}}</span><!--这里上线要删除-->
            <p class="section-input__text">
              <template v-for="(item,index) in xz_words">
                    <span class="dot" v-if="item.name" :key="index">{{item.input}}</span>
                    <el-input v-else v-model.trim="item.input" :key="index" v-focus="true"  @input="changeWidth(index,item)"></el-input>
              </template>
            </p>
          </div>
          <div class="section-input__footer">
            <div class="section-input__btn">
              <span @click="lijuComfirm_01">确认</span>
              <span @click="lijuForget">不记得了</span>
            </div>
            <div class="section-input__tips">
              【 温馨提示：请根据上下文进行填空 】
            </div>
          </div>
        </div>
      </div>
      <div class="sen-body" v-if="study_child_mk[study_child_mk_index] === 3">
        <div class="section-input">
          <div class="section-input__title">
            <div class="section-input__text">
              <span>{{now_words.info}}</span>
              <i class="fa fa-volume-up" @click="audioPlay_3(now_words.audio_link, now_words.id)"></i>
            </div>
            <p class="section-input__info">{{now_words.chinese}}</p>
          </div>
          <el-input class="section-input__area" v-model="px_answer" v-focus v-on:input="pin_input(px_answer)" ref="pinInput"></el-input>
          <div class="section-input__footer">
            <div class="section-input__btn">
              <span @click="myMaster">我已掌握</span>
              <span @click="resetScanning">重新抄写</span>
            </div>
            <div class="section-input__tips">
              【 温馨提示：请正确抄写句子（区分大小写和标点）！ 】
            </div>
          </div>
        </div>
      </div>
      <div class="sen-body" v-if="study_child_mk[study_child_mk_index] === 4">
        <div class="section-input">
          <div class="section-input__content">
            <p>{{now_words.chinese}}</p>
            <div class="section-input__answer">
                <i class="fa fa-headphones" @click="audioPlay_3(now_words.audio_link, now_words.id)"></i>
                <el-input v-model="px_answer" v-on:input="pin_input(px_answer)" ref="pinInput"></el-input>
                <i class="iconfont icon-V" style="color: #00b68d" v-if="words_xuexi_status == 1"></i>
                <i class="iconfont icon-V1" style="color: #d9001b" v-if="words_xuexi_status == 2"></i>
                <i class="iconfont icon-V1" v-if="words_xuexi_status != 2 && words_xuexi_status != 1" style="visibility: hidden;"></i>
            </div>
            <p v-if="words_xuexi_status == 2 || words_xuexi_status==3">{{now_words.info}}</p>
          </div>
          <div class="section-input__footer">
            <div class="section-input__btn">
              <span @click="lijuComfirm_02">确认</span>
            </div>
            <div class="section-input__tips">
              【 温馨提示：请根据上下文进行填空 】
            </div>
          </div>
        </div>
      </div>
       <div class="re-body" v-if="study_child_mk[study_child_mk_index] === 5">
          <div class="re-ready">
              <div class="re-text">
                  <span class="item-dot">●</span>
                  <span>进入下一组,继续学习，go!go!go!</span>
              </div>
          </div>
          <el-button class="rem-btn" type="primary" @click="studyNextLun">继续学习</el-button>
      </div>
    </div>

    <div class="sentence-body dictation" v-if="is_show_result">
      <div class="summary sen-summary">
        <div class="summary-body">
          <div class="summary-body-l">
            <div class="summary-list__content">
              <div class="content-item">
                <span class="dot">●</span>
                <span class="item-text">翻译总数（个）：</span>
                <span class="item-num">{{wordsLists.length}}</span>
              </div>
              <div class="content-item">
                <span class="dot">●</span>
                <span class="item-text">未完成数（个）：</span>
                <span class="item-num word-num">{{wordsLists.length - rightCount}}</span>
              </div>
              <div class="content-item">
                <span class="dot">●</span>
                <span class="item-text">本次学习（个）：</span>
                <span class="item-num word-num">{{rightCount}}</span>
              </div>
            </div>
            <div class="summary-list__content" >
              <div class="content-item">
                <span class="dot">●</span>
                <span class="item-text">完成本单元可获</span>
<!--                <span class="item-text">保存后可获</span>-->
                <span class="item-num word-num gain-num">60</span>
                <i class="iconfont icon-zuanshi"></i>
              </div>
            </div>
            <div class="summary-list__content" >
              <div class="content-item">
                <span class="dot">●</span>
                <span>继续学习可获更多宝石</span>
              </div>
            </div>
          </div>
          <div class="summary-body-r">
            <div class="r-logo">
              <div class="test-logo"></div>
              <span>X</span>
              <span>30</span>
            </div>
            <div class="btn-box">
              <el-button class="btn-test" @click="jixuLearn">继续学习</el-button>
              <el-button class="btn-exit" @click="save">保存退出</el-button>
              <!--<el-button class="btn-test">单元测试</el-button>-->
              <!--<el-button class="btn-exit">保存退出</el-button>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { connect } from "echarts";
import { mapMutations, mapState } from "vuex";

export default {
  name: "sentence",
  props: {
    module: { type: Number },
    course: { type: Object },
    danyuan: { type: Object },
    wordsLists: { type: Array },
    studyType: { type: Number, default: 1 }, //类型，1学习，2复习，3错词巩固
    reviewType: { type: Number, default: 1 }, //复习类型，1复习，2一键复习
    nextFuxiType: { type: Object }, //下一个复习类型
  },
  data() {
    return {
      study_mk: [0, 1, 2, 3, 4, 5, 6],
      study_child_mk: [0, 1, 2, 3, 4, 5, 6],
      study_mk_index: 0,
      study_child_mk_index: 0,
      lun_num: 5, //每轮多少个单词
      all_lun: 0, //总共多少轮
      now_lun: 1, //当前第几轮
      now_words: {}, //当前单词
      now_words_index: 0, //当前单词索引（所有单词）
      now_lun_words_index: 0, //当前轮单词的索引
      now_lun_count: 0, //当前轮多少个
      now_lun_finish_count: 0,
      now_words_daan: 0, //当前答案是否正确，默认0，1正确，2错误
      lun_lists_id_arr: [],
      xz_words: [], //选中的单词索引列表
      words_xuexi_status:0,//当前单词学习状态，0开始，1正确，2错误，3查看答案，4练习
      error_lists_arr:[],
      px_answer:"",
      moxie_answer:false,
      px_old_ans_arr:[],
      qzfx_level:0,//是否强制拼写
      daan_lists:[],
      err_words: 0,
      use_time: 0,
      now_daan: "",
      type: 1, //学习类型，1 听组，2 听力，3 翻译，4 默写
      mode_type: 1,
      audio_obj: [],
      action_status:1,

      input4: "",
      checkIndex: 0,
      checked: true,
      input: "like",
      sen_input: "",
      textShow: false,
      process_speed: 0, //进度条 - 百分比
      chooseItem: [
        {
          title: "A.",
          answer: "我喜欢那个苹果",
        },
      ],
      chooseItems: [
        {
          title: "A.",
          answer: "I like that apple.",
        },
      ],
      checkbox: [
        {
          title: "A",
          answer: "那个苹果",
        },
      ],
      is_show_result: false,
      key_state : 0, //快捷键未按下
      d_code :"",
      is_enter:1,//快捷键是否允许回车，1是允许回车，0不允许
      limit_word_list:[],
      zh_limit_word_list:[],
      audioUrl:'',
      zhStyleObj: {
          fontSize: '24px',
          letterSpacing: '1px',
          marginRight: '0'
      },
      chooseAudios: {
        src: require("../../assets/audio/check.wav"),
        errSrc: require("../../assets/audio/error.mp3"),
      },
      jsq_obj:"",//计时器
      jsq_stop:"",
      yongshi: 0,
      yongshi_time: "",
    };
  },
    directives: {
      focus: {
          inserted(el) {
              //el.querySelector(".el-input:first-of-type > input").focus();
          }
      }
    },
  created() {
    //console.log(this.module);
    this.type = this.module + 1;
    //this.getDuanyu();
    //console.log("wordsLists",this.wordsLists);
    //this.study_child_mk_index = 3;
    this.initData();
    let str = "I have a lot, of problems.";
    let car_arr = this.$conf.char_type.split("");
    for(let i=0;i<car_arr.length;i++){
        let j = str.indexOf(car_arr[i]);
        if(j>=0){
            str = str.slice(0,j)+" "+ str.slice(j);
        }
    }
    console.log("str:"+str);
  },
  computed: {
    ...mapState({
      token: (state) => state.user.token,
      user: (state) => state.user.user,
    }),
    processSpeed() {
      let a = 0;
      a = (this.now_lun_finish_count / this.now_lun_count) * 100;
        console.log("now_lun_finish_count:" + this.now_lun_finish_count);
        console.log("now_lun_count:" + this.now_lun_count);
      console.log("a:" + a);
      return a > 100 ? 100 : a;
    },
    rightCount() {
      let right_count = 0;
      this.daan_lists.forEach((item, index) => {
        if (item.answer == 1) {
          right_count++;
        }
      });
      return right_count;
    }
  },
    updated() {
        let sportImg = document.querySelector(".sport-img");
        if (this.processSpeed === 0) {
            sportImg.style.left = 0;
        } else if (this.processSpeed === 100) {
            sportImg.style.left = 97 + "%";
        } else {
            sportImg.style.left = this.processSpeed - 3 + "%";
        }
    },
    mounted:function(){
     // 添加 resize 绑定事件
    window.addEventListener(
      "keyup",this.key_up,false
    );
    window.addEventListener(
      "keydown",this.key_down,false
    );
  },
  methods: {
      changeWidth(index,item) {
          let inWidth = document.querySelectorAll('.el-input__inner');
          /*
          if (item.input != '') {
              inWidth.style.width = (item.input.length * 16) + 'px';
              if ( parseInt(inWidth.style.width) > 300) {
                  inWidth.style.width = 300 + 'px';
              }
          } else if (item.input == '') {
              inWidth.style.width = 32 + 'px';
          }*/
          let j = 0;
          for(let i=0;i<this.xz_words.length;i++){
              console.log(this.xz_words[i].name);
              if(this.xz_words[i].name =='' && i!=index){
                    j++;
              }
              if(i==index){
                  break;
              }
          }
          for (let i = 0; i < inWidth.length; i++) {
              if(i==j){
                  if(item.input!='' && item.input.length>3){
                      inWidth[i].style.width = (item.input.length * 16 + 32) + 'px';
                      if ( parseInt(inWidth[i].style.width) > 300) {
                          inWidth[i].style.width = 300 + 'px';
                      }
                  }else if (item.input == '') {
                      inWidth[i].style.width = 32*3 + 'px';
                  }
              }
          }
      },
    initData() {
        this.audioUrl = this.$conf.audio_url;
        this.setLunListsId(this.now_lun);
        this.study_mk_index = this.module;
        //console.log("this.module",this.study_mk_index);
        this.daan_lists = this.wordsLists.map((item, index, arr) => {
            let temp_item = {
              id: item.id,
              info: item.info,
              chinese: item.chinese,
              type: this.type,
              mode_type: this.mode_type,
              answer: 0,
              levels: 0,
              right_num: 0,
              error_num: 0,
              use_time: 0,
              use_time_yuxi: 0,
              huanjie: "",
              error_type: "",
              study_type: this.studyType,
              jilu: [],
              score: [],
              is_xuexi: 0,
            };
            //{answer:0,use_time: 0,huanjie: "",error_type: "",error_type_name:""}
            //{type: "",type_name:"",desc:"描述",score:""}
            return temp_item;
      });

      //console.log("daan_lists",this.daan_lists);
    },
    changeChecked(index) {
      this.checkIndex = index;
      //console.log(this.checkIndex);
    },
    startXuexi(){     
        //计时器开始  
        this.setJsq(); 
        if (this.study_child_mk_index > 3) {
          this.study_child_mk_index = 0;
        }
        this.study_child_mk_index++;
        if(this.now_words != null){
            //this.audioPlay_3(this.now_words.audio_link, this.now_words.id);
        }
        this.nextWords();
        //this.yuxiNext();
    },
    next() {
      if (this.study_child_mk_index > 3) {
        this.study_child_mk_index = 0;
      }
      this.study_child_mk_index++;
    },
    setLunListsId(lun) {
      this.lun_lists_id_arr = [];
      //获取当前轮id
      //console.log("lun:" + lun);
      //console.log("now_lun:" + this.now_lun);
      let start_index = this.lun_num * (lun - 1);
      let end_index = this.lun_num * lun;
      let words_length = this.wordsLists.length;
      if (end_index > words_length) {
        end_index = words_length;
      }
      this.now_lun_count = end_index - start_index;
      this.all_lun = Math.ceil(words_length / this.lun_num);
      //console.log("all_lun:" + this.all_lun);
      //console.log("start:" + start_index + "end:" + end_index);
      for (let index = start_index; index < end_index; index++) {
        this.lun_lists_id_arr.push(index);
      }
      //console.log("lun_lists_id_arr", this.lun_lists_id_arr);
      //this.randNowWord();
      //console.log("setLunListsId");
      //console.log(this.lun_lists_id_arr);
      //console.log(this.wordsLists);
      //this.lun_lists_id_arr = this.unique(this.lun_lists_id_arr);//去重复
    },
    randNowWord() {
      //随机当前学的单词
      let lun_lists_count = this.lun_lists_id_arr.length;
      let randNum = this.getRandomInt(0, lun_lists_count); //获取当前随机数
      //console.log("lun_lists_id_arr",this.lun_lists_id_arr);
      this.now_words_index = this.lun_lists_id_arr[randNum];
      this.now_words = this.wordsLists[this.now_words_index];
      //console.log("this.now_words",this.now_words);
      console.log("chinese",this.now_words.chinese);
      console.log("info",this.now_words.info);

      //console.log("this.now_words_index",this.now_words_index);
      this.now_lun_words_index = randNum;
      //this.resetNowWord(); //重置当前单词释义
      //this.resetWordOrder(); //词义重新排序
      this.now_words.zimu_list = this.get_zimu_list(this.now_words.info);
      this.now_words.zh_zimu_list = this.get_zh_zimu_list(this.now_words);

      this.limit_word_list = this.getLimitWordList(this.now_words.info);
      this.zh_limit_word_list = this.getZh_LimitWordList(this.now_words.chinese_participle);


      //例句翻译与默写
      if(this.study_mk[this.study_mk_index] == 2 || this.study_mk[this.study_mk_index] == 3){
          this.xz_words = this.get_zimu_list02(this.now_words.info);
      }


      this.words_xuexi_status = 0;//当前单词学习状态，0开始，1正确，2错误，3查看答案，4练习
      //console.log(this.now_words);
      //console.log("now_words_zimu_list",this.now_words.zh_zimu_list);
      /*this.xz_words = [];
      this.now_words_daan = 0;
      if (this.xuexi_step != 0 && this.xuexi_step != 8) {
        this.audioPlay_3(this.now_words.audio_link, this.now_words.id);
      }*/
    },
    strCharAddSpace(str){
      //符号前加空格
      let car_arr = this.$conf.char_type.split("");
      for(let i=0;i<car_arr.length;i++){
          let index = str.indexOf(car_arr[i]);
          if(index>=0){
              //str = str.slice(0,index)+" "+ str.slice(index);
              //console.log("car_arr："+car_arr[i]); 
              if(car_arr[i] == ","){
                str = str.replace(/\,/g, ' ,');
                str = str.replace(/\,(?=[^\s])/g, ', ');
              }else if(car_arr[i] == "."){
                str = str.replace(/\./g, ' .'); 
                str = str.replace(/\.(?=[^\s])/g, '. ');
                               
              }else if(car_arr[i] == "?"){
                str = str.replace(/\?/g, ' ?');
                str = str.replace(/\?(?=[^\s])/g, '? ');
              }else if(car_arr[i] == "!"){
                str = str.replace(/\!/g, ' !');
                str = str.replace(/\!(?=[^\s])/g, '! ');
              }
              
              console.log("str---"+str);
          }     
      }
      return str;
    },
    getLimitWordList(word){
      let space_word = this.strCharAddSpace(word);
      let words = space_word.split(" ");
      let word_list = [];
      for (var i = 0; i < words.length; i++) {
        let new_word = {};
        
        if(this.isSymbol(words[i])){
          //判断是否是符号
          new_word.value = words[i];
          new_word.type = 1;
          new_word.index=-1;//选择项的id
          //console.log(words[i]+"=是==");
        }else{
          //console.log(words[i]+"=不是==");
          new_word.value = "";
          new_word.type = 0;
          new_word.index=-1;//选择项的id
        }        
        word_list.push(new_word);
      }
      return word_list;
    },
    getZh_LimitWordList(word){
      let words = word.split(" ");
      let word_list = [];
      for (var i = 0; i < words.length; i++) {
        let new_word = {};
        
        if(this.isSymbol(words[i])){
          //判断是否是符号
          new_word.value = words[i];
          new_word.type = 1;
          new_word.index=-1;//选择项的id
          //console.log(words[i]+"=是==");
        }else{
          //console.log(words[i]+"=不是==");
          new_word.value = "";
          new_word.type = 0;
          new_word.index=-1;//选择项的id
        }        
        word_list.push(new_word);
      }
      return word_list;
    }
    ,
    delLunListsWord(now_lun_words_index) {
      //删除当前轮里能过的单词
      this.lun_lists_id_arr.splice(now_lun_words_index, 1);
    },
    nextWords() {
      ////console.log(this.all_lun + "----" + this.now_lun);
      //console.log("this.lun_lists_id_arr" + this.lun_lists_id_arr);
      if (this.lun_lists_id_arr.length <= 0) {
        return 0; //当前轮没有数据了
      } else {        
        this.randNowWord();
        if(this.study_mk[this.study_mk_index] == 3 && this.study_child_mk[this.study_child_mk_index] == 2){          
        }else{
          if(this.now_words != null){
              this.audioPlay_3(this.now_words.audio_link, this.now_words.id);
          }
        }        
        return 1;
      }
    },
    yuxiNext(){
        //预习
        this.delLunListsWord(this.now_lun_words_index);
        this.addProgress();
        if(!this.nextWords()){
            this.study_child_mk_index++;
            this.nextLun(this.now_lun);
            this.nextWords();
        }
        /*if(this.now_words != null){
            this.audioPlay_3(this.now_words.audio_link, this.now_words.id);
        }*/ 
    },
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min)) + min; //不含最大值，含最小值
    },
    //根据单词获取字母
    get_zimu_list(word) {
      var a = "abcdefghijklmnopqrstuvwxyz";
      let words = this.strCharAddSpace(word);
      word = words.split(" ");
      /*for (var i = 0; i < 2; i++) {
        word.push(a[Math.floor(Math.random() * 26)]);
      }*/
      let rand_word = this.now_words.rand_word;
      if(rand_word.length>0){
          word = word.concat(rand_word);
      }      
      for(let i=0;i<word.length;i++){
          if(this.isSymbol(word[i])){
            word.splice(i,1);
          }
      }

      return this.shuzu_daluan(word);
    },
    //根据单词获取字母
    get_zh_zimu_list(now_word) {
      var a = "abcdefghijklmnopqrstuvwxyz";
      let word = now_word.chinese_participle.split(" ");
      let chinese_disturbs = [];
      if(now_word.chinese_disturb!="" && now_word.chinese_disturb!=null){
          chinese_disturbs = now_word.chinese_disturb.split(" ");
          word = word.concat(chinese_disturbs);
      }      
      /*for (var i = 0; i < 2; i++) {
        word.push(a[Math.floor(Math.random() * 26)]);
      }*/
      for(let i=0;i<word.length;i++){
          if(this.isSymbol(word[i])){
            word.splice(i,1);
          }
      }

      return this.shuzu_daluan(word);
    },

    //根据单词获取字母（选择项）
    get_zimu_list02(word) {
      let words = this.strCharAddSpace(word);
      word = words.split(" ");
      let word_list = [];
      for (var i = 0; i < word.length; i++) {
        let new_word = {};
        new_word.name = word[i];
        new_word.input = word[i];
        new_word.index = "";
        word_list.push(new_word);
      }

      let ids= [];
      let new_ids=[];
      for (var i = 0; i < word.length; i++) {
        if(!this.isSymbol(word_list[i].name)){
          ids.push(i);
        }
      }

      let j = Math.ceil(word_list.length/3);

      for(var i = 0; i < j; i++) {
          let randNum = this.getRandomInt(0, ids.length);
          new_ids.push(ids[randNum]);
          ids.splice(randNum, 1);
      }
      //console.log("new_ids",new_ids);

      let temp_new_id = -1;
      for(var i=0;i<new_ids.length;i++){
          if(temp_new_id > new_ids[i]){
              temp_new_id = new_ids[i];
          }else if(temp_new_id==-1){
              temp_new_id = new_ids[i];
          }
          word_list[new_ids[i]].name = "";
          word_list[new_ids[i]].input = "";          
      }
      word_list[temp_new_id].index = 1;
      //console.log("word_list",word_list);
      return word_list;
      //return this.shuzu_daluan(word_list);
    },

    nextLun(now_lun) {
      this.now_lun_finish_count = 0;
      //下一轮
      if (now_lun > this.all_lun) {
        return 0; //没有下一轮了
      } else {
        this.setLunListsId(now_lun);        
        return 1;
      }
    },
    studyNextLun(){
        //学习下一轮
        this.xz_words = [];
        this.study_child_mk_index = 1;
        this.nextWords();
    }
    ,
    del_word(index = -1,item) {
      if(this.words_xuexi_status==0 || this.words_xuexi_status==4){
          if (index >= 0) {
            let del_index = item.index;
            console.log("item.index",item.index);

            for(let i =0;i<this.xz_words.length;i++){
                if(this.xz_words[i] == del_index){
                  this.xz_words.splice(i, 1);
                }
            }
            this.zh_limit_word_list[index].value = "";
            this.zh_limit_word_list[index].index = -1;
            //this.xz_words.splice(del_index, 1);
            /*let name = this.now_words.zh_zimu_list[del_index];
            for(let i=0;i<this.zh_limit_word_list.length;i++){
                if(this.zh_limit_word_list[i].value == name){
                    this.zh_limit_word_list[i].value = "";
                    this.zh_limit_word_list[i].index = -1;
                    break;
                  }
            }*/
          } else {
            this.xz_words.splice(this.xz_words.length - 1, 1);            
          }
      }
    },
    del_en_word(index = -1,item) {
      console.log("item",item);
      if(this.words_xuexi_status==0 || this.words_xuexi_status==4){
          if (index >= 0) {
            let del_index = item.index;
            //console.log("item.index",item.index);
            for(let i =0;i<this.xz_words.length;i++){
                if(this.xz_words[i] == del_index){
                  this.xz_words.splice(i, 1);
                }
            }
            this.limit_word_list[index].value = "";
            this.limit_word_list[index].index = -1;
            //this.xz_words.splice(del_index, 1);
            /*let name = this.now_words.zimu_list[del_index];
            for(let i=0;i<this.limit_word_list.length;i++){
                if(this.limit_word_list[i].value == name){
                    this.limit_word_list[i].value = "";
                    this.limit_word_list[i].index = -1;
                    break;
                  }
            }*/
          } else {
            this.xz_words.splice(this.xz_words.length - 1, 1);            
          }
      }
    },
    /**
     * [set_words 选中取消单词]
     * @param {[type]} index [description]
     * 英译中
     */
    set_words(index) {
      var j = this.in_array(index, this.xz_words);
      if (j >= 0) {
        this.xz_words.splice(j, 1);
      } else {
        this.xz_words.push(index);
      }
      let name = this.now_words.zh_zimu_list[index];
      //return;
      for(let i=0;i<this.zh_limit_word_list.length;i++){
          if(j>=0){
            //console.log(">j:"+j+"name:"+name);
            if(this.zh_limit_word_list[i].value == name){
              this.zh_limit_word_list[i].value = "";
              this.zh_limit_word_list[i].index = -1;
              break;
            }
          }else{
            //console.log("<j:"+j+"name:"+name);
            if(this.zh_limit_word_list[i].value == ""){
              this.zh_limit_word_list[i].value = name;
              this.zh_limit_word_list[i].index = index;
              break;
            }
          }
      }

      let is_end = 1;
      for(let i=0;i<this.zh_limit_word_list.length;i++){
        if(this.zh_limit_word_list[i].value ==""){
            is_end = 0;
        }
      }

      if(is_end){
          if(this.xzIsRight()){
            this.chooseAudio();
            this.addProgress();
            this.words_xuexi_status = 1;
                setTimeout(() => {
                    if(this.hasErrorWords()){
                      this.delErrorWords();//删除错误单词
                    }
                    if (!this.hasErrorWords()) {
                      //不在错误单词中，才可以删除
                      this.delLunListsWord(this.now_lun_words_index); //刪除正確的單詞
                      if(this.study_mk[this.study_mk_index]==1){
                        this.addRightAnswer(1);//添加正确答案
                      }
                      //this.addProgress();
                    }
                    if (this.nextWords()) {
                      this.xz_words = [];
                    } else {
                      if(this.study_mk[this.study_mk_index]==0){//听组
                        //进入下一环节
                        this.study_child_mk_index++;
                        if (this.nextLun(this.now_lun)) {
                            this.xz_words = [];
                            this.nextWords();
                        } else {
                          //this.study_child_mk_index++; //进入下一个环节
                          //没有下一轮，显示结果
                        }
                      }else{//听力
                        //进入下一轮
                        this.now_lun++;
                        if (this.nextLun(this.now_lun)) {
                            //显示继续学习页面
                            this.study_child_mk_index++;
                            this.xz_words = [];
                            //this.randNowWord();
                            //this.study_child_mk_index = 1;
                        } else {
                          //this.study_child_mk_index++; //进入下一个环节
                          this.is_show_result = true;//显示结果
                        }
                      }
                    }
                    this.words_xuexi_status = 0;
                }, 1000);
          }else{
            this.chooseAudio(0);
            this.addErrorWords();//添加到错误
            this.words_xuexi_status = 2;
            setTimeout(() => {
                //this.study_child_mk_index++;
                if(this.words_xuexi_status==2){
                    this.words_xuexi_status = 3;
                }else{
                    this.words_xuexi_status = 4;
                }
            }, 1000);

          }
      }

      //console.log(this.xz_words);
    },
    set_words_en(index) {
      //中译英
      var j = this.in_array(index, this.xz_words);
      if (j >= 0) {
        this.xz_words.splice(j, 1);
      } else {
        this.xz_words.push(index);
      }
      let name = this.now_words.zimu_list[index];
      
      //return;
      for(let i=0;i<this.limit_word_list.length;i++){
          if(j>=0){
            //console.log(">j:"+j+"name:"+name);
            if(this.limit_word_list[i].value == name){
              this.limit_word_list[i].value = "";
              this.limit_word_list[i].index = -1;
              break;
            }
          }else{
            //console.log("<j:"+j+"name:"+name);
            if(this.limit_word_list[i].value == ""){
              this.limit_word_list[i].value = name;
              this.limit_word_list[i].index = index;
              break;
            }
          }
      }


     let is_end = 1;
      for(let i=0;i<this.limit_word_list.length;i++){        
        if(this.limit_word_list[i].value ==""){
            is_end = 0;
        }
      }

      if(is_end==1){
          if(this.xzIsRightEn()){
            this.chooseAudio();
            this.addProgress();
            this.words_xuexi_status = 1;
                setTimeout(() => {
                    if(this.hasErrorWords()){
                      this.delErrorWords();//删除错误单词
                    }
                    if (!this.hasErrorWords()) {
                      //不在错误单词中，才可以删除
                      this.delLunListsWord(this.now_lun_words_index); //刪除正確的單詞

                      if(this.study_mk[this.study_mk_index]==0){
                        this.addRightAnswer(1);//添加正确答案
                      }
                      //console.log("daan_lists",this.daan_lists);
                      //this.addProgress();
                    }
                    if (this.nextWords()) {
                      this.xz_words = [];
                    } else {
                      //进入下一环节
                      //this.study_child_mk_index++;
                      if(this.study_mk[this.study_mk_index]==0){//听组
                        this.now_lun++;
                        if (this.nextLun(this.now_lun)) {
                            //显示继续学习页面
                            this.study_child_mk_index++;                            
                            this.xz_words = [];
                            //this.nextWords();
                            //this.study_child_mk_index = 1;
                        } else {
                            //this.study_child_mk_index++; //进入下一个环节(英译中)
                            this.is_show_result = true;//显示结果
                        }
                      }else{//听力
                          //进入下一环节
                          this.study_child_mk_index++;
                          if (this.nextLun(this.now_lun)) {
                              this.xz_words = [];
                              this.nextWords();
                          } else {
                            //this.study_child_mk_index++; //进入下一个环节
                            this.is_show_result = true;
                          }
                      }

                    }
                    //this.delLunListsWord(this.now_lun_words_index);
                    this.words_xuexi_status = 0;
                }, 1000);
          }else{
            this.chooseAudio(0);
            this.addErrorWords();//添加到错误
            this.words_xuexi_status = 2;
            setTimeout(() => {
                //this.study_child_mk_index++;
                if(this.words_xuexi_status==2){
                    this.words_xuexi_status = 3;
                }else{
                    this.words_xuexi_status = 4;
                }
            }, 1000);
          }
      }

      //console.log(this.xz_words);
    },
    //根据已选字母生成单词
    get_word() {
      var that = this;
      var word = "";
      this.limit_word_list.forEach(function (t, y) {
        if (word) {
          word = word + " "+ t.value;
        } else {
          word = t.value;
        }
      });
      return word;
    },
    //根据已选字母生成单词
    get_zh_word() {
      var that = this;
      var word = "";
      this.zh_limit_word_list.forEach(function (t, y) {
        if (word) {
          word = word + t.value;
        } else {
          word = t.value;
        }
      });
      return word;
    },
    xzIsRight(is_t = 0) {
      var word = this.get_zh_word();
      console.log("word:",word);
      //console.log("chinese:",this.now_words.chinese);
      if (is_t == 1 || word == this.now_words.chinese) {
        return 1;
      } else {
        //this.show_word();
        return 0;
      }
    },
    xzIsRightEn(is_t = 0) {
      var word = this.get_word();
      //console.log("word:",word);
      //console.log("chinese:",this.now_words.info);
      let info = this.strCharAddSpace(this.now_words.info);
      if (is_t == 1 || word == info) {
        return 1;
      } else {
        //this.show_word();
        return 0;
      }
    },
    //数组随机排序
    shuzu_daluan(arr) {
      var len = arr.length;
      for (let i = 0; i < len; i++) {
        let index = parseInt(Math.random() * (len - i)) + i;
        if (index != i) {
          let temp = arr[i];
          arr[i] = arr[index];
          arr[index] = temp;
        }
      }
      return arr;
    },
    /**
     * [in_array 字符串是否在数组内]
     * @param  {[type]} v   [字符串]
     * @param  {[type]} arr [数组]
     * @return {[type]}     [返回位置  -1不在数组内]
     */
    in_array(v, arr) {
      for (var i = 0; i < arr.length; i++) {
        if (v == arr[i]) {
          return i;
        }
      }
      return -1;
    },
    foget01(){
      if(this.words_xuexi_status==2){
          this.words_xuexi_status = 3;
      }else{
          this.words_xuexi_status = 4;
      }
      this.addErrorWords();//添加到错误
      //console.log(this.words_xuexi_status);
    },
    clearAllWords(){
      this.xz_words = [];
      for(let i=0;i<this.zh_limit_word_list.length;i++){
            if(this.zh_limit_word_list[i].type == 0){
                this.zh_limit_word_list[i].value = "";
                this.zh_limit_word_list[i].index = -1;
            }
      }

      for(let i=0;i<this.limit_word_list.length;i++){
            if(this.limit_word_list[i].type == 0){
                this.limit_word_list[i].value = "";
                this.limit_word_list[i].index = -1;
            }
      }

    },
    lianyxi(){
        this.clearAllWords();
        this.words_xuexi_status = 4;
    },
    addErrorWords(num = 2) {
      //添加错误单词
      //console.log("err_words");
      let err_words = {
        id: this.now_words_index,
        word_id: this.now_words.id,
        num: num,
        error_num: 1,
      };
      let err_index = -1;
      let error_lists = this.error_lists_arr;
      for (let i = 0; i < error_lists.length; i++) {
        if (error_lists[i] && error_lists[i].word_id == this.now_words.id) {
          err_words = error_lists[i];
          err_index = i;
          break;
        }
      }
      if (err_index >= 0) {
        err_words.num = num;
        err_words.error_num = err_words.error_num + 1; //错误次数
        this.error_lists_arr[err_index] = err_words;
      } else {
        this.error_lists_arr.push(err_words);
        //this.error_lists_log.push(err_words);
      }
      //console.log("error_lists_arr");
      //console.log(this.error_lists_arr);
    },
    delErrorWords() {
      //消除错误单词
      //this.error_lists_arr.splice(this.now_words_index,1);
      let error_lists = this.error_lists_arr;
      let error_index = -1;
      this.err_words = 0;
      for (let i = 0; i < error_lists.length; i++) {
        if (error_lists[i].word_id == this.now_words.id) {
          this.err_words = error_lists[i];
          error_index = i;
          break;
        }
      }
      //console.log("delErrorWords");
      //console.log(err_words);
      if (this.err_words) {
        this.err_words.num = this.err_words.num - 1;
        if (this.err_words.num <= 0) {
          this.error_lists_arr.splice(error_index, 1);
          //console.log("delErrorWords:1");
          return 1;
        } else {
          //console.log("delErrorWords:2");
          return 0;
        }
      } else {
        //console.log("delErrorWords:3");
        return 1;
      }
    },
    hasErrorWords() {
      let err_words = "";
      let error_lists = this.error_lists_arr;
      for (let i = 0; i < error_lists.length; i++) {
        if (error_lists[i].word_id == this.now_words.id) {
          err_words = error_lists[i];
          break;
        }
      }
      if (err_words) {
        return true;
      } else {
        return false;
      }
    },
    addRightAnswer(answer) {
      if (this.err_words) this.err_words.error_num;
      let daan_words = this.daan_lists[this.now_words_index];
      if (daan_words) {
        daan_words.answer = answer ? answer : 0;
        daan_words.is_xuexi = 1; //后台数据筛选用
      }
      //console.log("err_words",this.daan_lists);
    },
    addCaozuLog(answer) {
      let huanjie="";
      //添加操作记录
      let daan_words = this.daan_lists[this.now_words_index];
      //console.log('添加操作记录');
      //console.log(daan_words);
      //console.log("this.use_time:"+this.use_time);
      if (daan_words) {
        let jilu = {
          word_id: daan_words.id,
          answer: answer,
          type: this.type,
          mode_type: this.mode_type,
          study_type: this.studyType,
          daan: this.now_daan,
          huanjie: huanjie,
          use_time: this.use_time,
          error_type: 0,
        };
        daan_words.jilu.push(jilu);
        //right_num:0,
        //error_num:0,
        if (answer) {
          daan_words.right_num++;
        } else {
          daan_words.error_num++;
        }
      }
    },
    save() {
        if (this.action_status == 0) return;
        this.action_status = 0;
        let danyuan_id = 0;
        if (this.studyType == 1) {
          danyuan_id = this.danyuan.id;
        }
        let data = {
          user_id:this.user.id,
          token: this.token,
          danyuan_id: danyuan_id,
          type: this.type,
          mode_type: this.mode_type,
          daan: this.daan_lists,
          study_type: this.studyType,
          yongshi:this.yongshi,
          yongshi_time:this.yongshi_time
        };
        this.axios
          .post("/api/learn/save_duanyu_data", data,{
            loading:true
          })
          .then((res) => {
            //console.log(res);
            if (res.data.status_code == 200) {
              //this.$message('提交成功');
              //this.emitClose(this.type, 1);
              this.clearJsq();
              this.$emit("close");
              //let data = res.data.data
              //this.$store.commit('login',data)
              //this.$message('登录成功')
              //this.$router.push({name:'study_ai_word_ai_word_index'})
            } else if (res.data.status_code == 202) {
              //this.$message('用户名或者密码错误')
            } else {
              this.$message(res.data.msg);
            }
          })
          .catch((err) => {
            this.loading = false;
          });
    },
    emitClose() {
      this.is_show_result = true;
      //暂停计时器
      this.stopJsq();
      //this.$emit("close");
    },
    lijuComfirm_01(){
      let no_next = 0;
      this.xz_words.forEach(function (t, y) {
        if(t.input == ""){
            no_next = 1;
        } 
      });

      if(no_next){
          return;
      }

      if(this.xtIsRight()){
          this.chooseAudio();
          this.addProgress();
          //console.log("right");
          if(this.hasErrorWords()){
            this.delErrorWords();//删除错误单词
          }
          if (!this.hasErrorWords()) {
            //不在错误单词中，才可以删除
            this.delLunListsWord(this.now_lun_words_index); //刪除正確的單詞
            //this.addProgress();
          }
          if (this.nextWords()) {
            //this.xz_words = [];
            let inWidth = document.querySelectorAll('.el-input__inner');
            for (let i = 0; i < inWidth.length; i++) {
                  inWidth[i].style.width = 32*3 + 'px';
            }
          } else {
            //进入下一环节
              if (this.nextLun(this.now_lun)) {
                  this.xz_words = [];
                  this.study_child_mk_index = this.study_child_mk_index+2;
                  this.nextWords();
                  setTimeout(() => {
                      this.$refs["pinInput"].focus();
                  },1500);
                  
              } else {
                  //this.study_child_mk_index++; //进入下一个环节(英译中)
                  this.is_show_result = true;//显示结果
              }
          }
      }else{
          this.chooseAudio(0);
          //console.log("error");
          this.addErrorWords();
          this.study_child_mk_index ++;
          setTimeout(() => {
              this.$refs["pinInput"].focus();
          },1500);
      }
    },
    lijuForget(){
      this.study_child_mk_index ++;
      this.addErrorWords();
      setTimeout(() => {
          this.$refs["pinInput"].focus();
      },500);
    },
    myMaster(){
      //我已掌握
      if(this.trim(this.px_answer) != this.trim(this.now_words.info)){
          this.$message({
          showClose: true,
          message: "句子输入有误！",
          type: 'error',
          offset:400
        });
      }else{
        this.px_answer = "";
        this.study_child_mk_index--;
        this.nextWords();
      }
    },
    resetScanning(){
      this.px_answer="";
    },
    lijuComfirm_02(){
        //console.log("px_answer",this.px_answer);
        //console.log("now_words_info",this.now_words.info);
        //console.log("this.words_xuexi_status:"+this.words_xuexi_status);        
        if(this.words_xuexi_status == 2){
          this.px_answer = "";
          this.words_xuexi_status = 3;
          return;
        }
        if(this.trim(this.px_answer)==""){
          return;
        }
        if(this.trim(this.px_answer) == this.trim(this.now_words.info)){
          this.addProgress();
          this.chooseAudio();
          //console.log("ok");
          this.words_xuexi_status = 1;
          if(!this.qzfx_level){
              if(this.hasErrorWords()){
                  this.delErrorWords();//删除错误单词
              }
          }else{
              this.qzfx_level = 0;
          }
          
          setTimeout(() => {                    
                    if (!this.hasErrorWords()) {
                      //不在错误单词中，才可以删除
                      this.delLunListsWord(this.now_lun_words_index); //刪除正確的單詞
                      this.addRightAnswer(1);//添加正确答案
                      //this.addProgress();
                    }
                    this.px_answer = "";
                    if (this.nextWords()) {
                      this.xz_words = [];
                      this.$refs["pinInput"].focus();
                      // this.$nextTick(() => {
                      //   this.$refs.pinInput.focus();
                      // });
                    } else {
                      //进入下一轮
                        this.now_lun++;
                        if (this.nextLun(this.now_lun)) {
                            //显示继续学习
                            this.study_child_mk_index++;
                            //this.xz_words = [];
                            //this.randNowWord();
                            //this.study_child_mk_index = 1;
                            
                        } else {
                            //this.study_child_mk_index++; //进入下一个环节(英译中)
                            this.is_show_result = true;//显示结果
                        }
                    }
                    //this.delLunListsWord(this.now_lun_words_index);
                    this.words_xuexi_status = 0;
                }, 1500);
        }else{
          this.chooseAudio(0);
          this.addErrorWords();
          //console.log("error");
          this.qzfx_level = 1;
          this.words_xuexi_status = 2;
          setTimeout(() => {
              this.lijuComfirm_02();
              this.$refs["pinInput"].focus();
          },1500);
        }

        //console.log("this.words_xuexi_status:"+this.words_xuexi_status);
    },
    xtIsRight(is_t = 0) {
      var word = this.get_liju_word();
      //console.log("word:",word);
      //console.log("chinese:",this.now_words.info);
      if (is_t == 1 || word == this.now_words.info) {
        return 1;
      } else {
        //this.show_word();
        return 0;
      }
    },
    //根据已选字母生成单词
    get_liju_word() {
      var that = this;
      var word = "";
      var lastIsSymbol = false;//上一个是否是字符
      //console.log("this.xz_words",this.xz_words);
      this.xz_words.forEach(function (t, y) {
        //console.log("y:"+y);
        if (word) {
          if(that.isSymbol(t.input)){
              word = word + t.input;
              lastIsSymbol = true;
          }else{
              if(lastIsSymbol){
                word = word + t.input;
              }else{
                word = word +" "+ t.input;
              }
              lastIsSymbol = false;
          }
          
        } else {
          word = t.input;
        }
      });
      //console.log("word:"+word);
      return word;
    },
    pin_input(value) {
      /*var pin_study_rec = this.pin_study_rec[this.pin_words.id]||{qzfx_level:0,ture_num:0,error_num:0,lx_t_num:0,n_qzfx_level:0,};
              var qzfx_level = pin_study_rec.qzfx_level||0;
              if(qzfx_level==0){return false;}*/
      //console.log(value);
      //this.removeAnimate('.re-body-info');
      if (this.qzfx_level == 0) {
        return false;
      }
      var px_old_ans_arr = this.px_old_ans_arr;
      var inp_arr = value.split("");
      var zimu_arr = this.now_words.info.split("");
      var index = inp_arr.length - 1;
      if (index < 0) {
        index = 0;
      }
      //console.log(zimu_arr);
      if (inp_arr.length >= px_old_ans_arr.length) {
        //输入字母
        if (inp_arr[index] !== zimu_arr[index]) {
          // console.log('huanyuan')
          this.px_answer = px_old_ans_arr.join("");
          //this.moxie_answer = 2;
          //this.yyts = 1;
          return false;
        } else {
          //this.moxie_answer = 0;
          //this.yyts = 0;
        }
      } else {
        //console.log("del");
        // 删除字母
      }
      this.px_old_ans_arr = inp_arr;
    },
    trim(str) {
      return str.replace(/(^\s*)|(\s*$)/g, "");
    },
    isSymbol(char,type=0){
        //判断是否是符号
        var str = this.$conf.char_type;//中文与英文符号
        if(type==1){
            str = ",!?.";//英文符号
        }else if(type==2){
            str = "，！？。";//中文符号
        }

        return str.indexOf(char) != -1 ? true : false;
    },
    addProgress() {
      let err_word = this.hasErrorWords();
      if (err_word) {
        if (err_word.num == 1) {
          this.now_lun_finish_count++; //进度条
        }
      } else {
        this.now_lun_finish_count++; //进度条
      }
      //console.log("addProgress:",this.now_lun_finish_count);
    },
    jixuLearn(){
      this.is_show_result = false;
      this.goOnJsq();
    },
    chooseAudio(type = 1) {
      let audio = new Audio();
      audio.controls = false;
      let src = this.chooseAudios.src;
      if (type === 0) {
        src = this.chooseAudios.errSrc;
      }
      audio.src = src;
      audio.play();
    },
    audioPlay_3(audioLink, id) {
      try {
        console.log("播放音频");
        var that = this;
        var audio = that.audio_obj[id];
        if (!audio) {
          audio = new Audio();
          that.audio_obj[id] = audio;
        }

        audio.src = this.audioUrl + audioLink;
        audio.play(); //播放
        //监听播放结束
        audio.addEventListener(
          "ended",
          function () {
            that.audio_obj[id] = null;
          },
          false
        );
      } catch (err) {
        console.log("播放音频失败");
      }
    },//监听键盘事件
    key_down(event){
        if (this.key_state != 1) {
          this.key_state = 1;
          this.d_code = event.keyCode;
        }
    },
    key_up(event) {
      //console.log("code:", code);
      if (event.keyCode == this.d_code) {
        this.key_state = 0;
        this.d_code = "";
      }

      var code = event.keyCode;
      if (this.d_code == 16) {
        code = 1000 + code;
      } else if (this.d_code && this.d_code != 18) {
        //禁止其他组合键(不包括ctrl）
        return false;
      }

      //console.log("code",code);

      let step = this.study_mk[this.study_mk_index];      
      let step_child = this.study_child_mk[this.study_child_mk_index];

      //console.log("step:" + step);
      //Ctrl键
      if (code == 17) {
        if(step == 3 && step_child == 2){          
        }else{
          if(this.now_words != null){
              this.audioPlay_3(this.now_words.audio_link, this.now_words.id);
          } 
        }
      }

      if(step==0){
          if(step_child==1){
             if (code == 13) {
                this.yuxiNext();
             }
          }
      }

      if(step==1){
          if(step_child==1){
             if (code == 13) {
                this.yuxiNext();
             }
          }
      }

      if(step==2){
          if(step_child==1){
             if (code == 13) {
                this.yuxiNext();
             }
          }else if(step_child==2){
            //console.log("step_child:"+step_child);
            if(code == 13){
              //console.log("code:"+step_child);
              this.lijuComfirm_01();
            }
          }else if(step_child==3){
            if(code == 13){
              this.myMaster();
            }
          }else if(step_child==4){
            if(code == 13){
              this.lijuComfirm_02();
            }
          }
      }

      if(step==3){
          if(step_child==1){
             if (code == 13) {
                this.yuxiNext();
             }
          }
          if(step_child==2){
            if(code == 13){
              this.lijuComfirm_01();
            }
          }else if(step_child==3){
            if(code == 13){
              this.myMaster();
            }
          }else if(step_child==4){
            if(code == 13){
              this.lijuComfirm_02();
            }
          }
      }

    },
    /**
     * [study_djs 计时器]
     * @param  {Number} value [description]
     * @return {[type]}       [description]
     */
    setJsq() {
        let that = this;
        this.jsq_obj = setInterval(function () {
            if (that.jsq_stop == 1) {
                return;
            }            
            that.zj_yongshi();
        }, 1000);        
    },
    zj_yongshi() {
        var that = this;
        that.yongshi++;
        var hour = Math.floor(that.yongshi / 3600);
        var minute = Math.floor((that.yongshi - hour * 3600) / 60);
        var second = that.yongshi - hour * 3600 - minute * 60;
        if (hour > 0) {
            that.yongshi_time = hour + "时" + minute + "分" + second + "秒";
        } else {
            that.yongshi_time = minute + "分" + second + "秒";
        }
        console.log(that.yongshi_time);
    },
    stopJsq(){
      //暂停计时器
      this.jsq_stop = 1;
    },
    goOnJsq(){
      //继续计时器
      this.jsq_stop = 0;
    },
    clearJsq() {
        if (this.jsq_obj) {
            clearInterval(this.jsq_obj);
        }
    },
  },
  destroyed(){
    this.now_words = null;
    window.removeEventListener("keyup", this.key_up);
    window.removeEventListener("keydown", this.key_down);
    this.clearJsq();
  }
};
</script>

<style lang="scss">
@import "../../assets/css/sentence.scss";
</style>