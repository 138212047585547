<template>
    <div class="avatar container-fluid">
        <div
                class="avatar-title row d-flex justify-content-between"
                style="z-index: -20"
        >
            <span>切换头像</span>
            <i class="fa fa-close align-self-center" @click="handleClose"></i>
        </div>
        <div class="avatar-content row">
            <div class="content-l col-lg-4">
                <div
                        class="
            content-l__border
            row
            d-flex
            flex-column
            justify-content-center
            align-items-center
          "
                >
                    <span>头像预览</span>
                    <div
                            class="avatar-user d-flex justify-content-center align-items-center"
                            ref="userAva"
                    >
                        <img class="user-avatar" :src="src" alt="我的头像"/>
                        <img class="user-box" :src="borderSrc" alt="头像框">
                    </div>
                </div>
                <div
                        class="
            content-l__nickname
            d-flex
            flex-column
            justify-content-center
            align-items-center
          "
                >
                    <span>历史头像</span>
                    <div
                            class="avatar-user d-flex justify-content-center align-items-center"
                    >
                        <img class="user-avatar" :src="user.avatar" alt="我的头像"/>
                        <img class="user-box" :src="borderSrc" alt="头像框">
                    </div>
                </div>
            </div>
            <div class="content-r col-lg-8">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="我的头像" name="first">
                        <ul class="avatar-list">
                            <li
                                    class="avatar-list__item"
                                    v-for="(c_item, c_index) in avatarList"
                                    :key="c_index"
                                    @click="preview(c_item, c_index)"
                            >
                                <img :src="c_item.pic" alt="头像"/>
                            </li>
                        </ul>
                    </el-tab-pane>
                    <el-tab-pane label="头像框" name="second">
                        <div class="area-border">
                            <ul class="avatar-list">
                                <li
                                        class="avatar-list__item"
                                        v-for="(d_item, d_index) in borderList"
                                        :key="d_index"
                                        @click="previewBorder(d_item, d_index)"
                                >
                                    <img :src="d_item.pic" alt="头像"/>
                                </li>
                            </ul>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <div
                class="avatar-footer row d-flex justify-content-center align-items-center"
                @click="avatarUpdate"
        >
            <span>保存</span>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "avatar",
    data() {
        return {
            activeName: "first",
            avatarList: [],
            borderList: [],
            borderItem: null,
            imgIndex: 0,
            borderIndex: 0,
            border_pic_id: null,
            src: require("../../assets/images/study/tou.jpg"),
            borderSrc: require("../../assets/images/study/border.png"),
            // borderList: [
            //     {src: require('../../assets/images/study/3.gif')},
            //     {src: require('../../assets/images/study/3.gif')},
            //     {src: require('../../assets/images/study/3.gif')},
            //     {src: require('../../assets/images/study/border.png')},
            //     {src: require('../../assets/images/study/border03.png')},
            // ],
            pic_id: null,
            nowItem: null,
            tab_index: 0,
        };
    },
    created() {
        this.avatarGet();
        this.borderGet();
        this.src = this.user.avatar;
        this.borderSrc = this.user.avatar_box;
    },
    mounted() {
        // this.$refs.userAva.style.backgroundImage = 'url('+ this.borderSrc +')'
    },
    computed: {
        ...mapState({
            token: (state) => state.user.token,
            user: (state) => state.user.user,
        }),
    },
    methods: {
        handleClick(tab, event) {
            // console.log("event", event);
            // console.log("tab", tab.index);
            this.tab_index = tab.index;
            console.log("tab_index", this.tab_index);
        },
        handleClose() {
            this.$emit("close");
        },
        // 获取头像列表
        avatarGet() {
            this.axios
                    .post("/api/order/order_list", {token: this.token, pid: 1})
                    .then((res) => {
                        if (res.data.status_code === "200") {
                            this.avatarList = res.data.data.lists;
                            console.log(this.avatarList);
                        }
                    });
        },
        // 更新头像
        avatarUpdate() {
            let type = 0;
            //console.log("tab_index",this.tab_index);
            if (this.tab_index == 0) {
                type = 1;
            } else {
                type = 2;
            }
            this.savaData(type);
        },
        savaData(type) {
            let data = {};
            if (type == 1) {
                data = {token: this.token, pic_id: this.pic_id, add_type: type};
            } else {
                data = {
                    token: this.token,
                    pic_id: this.border_pic_id,
                    add_type: type,
                };
            }
            this.axios.post("/api/user/set_avatar", data).then((res) => {
                if (res.data.status_code === "200") {
                    this.$emit("close", 1);
                } else {
                    this.$message.error({message: res.data.msg, offset: 300});
                }
            });
        },
        // 预览头像
        preview(item, imgIndex) {
            this.imgIndex = imgIndex;
            this.src = item.pic;
            this.pic_id = item.id;
            console.log(this.pic_id);
            this.nowItem = item;
        },
        // 获取头像框
        borderGet() {
            this.axios
                    .post("/api/order/order_list", {token: this.token, pid: 2})
                    .then((res) => {
                        if (res.data.status_code === "200") {
                            this.borderList = res.data.data.lists;
                            console.log(this.borderList, "获取头像框");
                            // console.log(this.borderList[0].list[0].pic)
                        }
                    });
        },
        // 预览头像框
        previewBorder(borderItem, borderIndex) {
            this.borderIndex = borderIndex;
            this.borderItem = borderItem;
            this.borderSrc = this.borderItem.pic;
            this.border_pic_id = borderItem.id;
            console.log(this.border_pic_id);
        },
    },
};
</script>

<style lang="scss" scoped>
$borderColor: #ddd;
.avatar {
    &-title {
        span {
            padding: 8px 20px;
            font-family: youshe, serif;
            color: #4f95f4;
            font-size: 28px;
        }

        i {
            padding-right: 10px;
            font-size: 24px;
            cursor: pointer;
        }
    }

    &-content {
        height: 430px;
        border-top: 1px solid $borderColor;

        .content-l {
            border-right: 1px solid $borderColor;

            &__border {
                height: 50%;
                border-bottom: 1px solid $borderColor;
            }

            &__nickname {
                height: 50%;
            }
        }

        .content-r {
            height: 100%;

            .el-tabs {
                .el-tabs__content {
                    .el-tab-pane {
                        overflow-y: scroll;
                        height: 370px;

                        &::-webkit-scrollbar {
                            width: 8px;
                            height: 345px;
                            border-radius: 4px;
                            background-color: #fff;
                        }

                        &::-webkit-scrollbar-track {
                            border-radius: 4px;
                        }

                        &::-webkit-scrollbar-thumb {
                            border-radius: 10px;
                            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                            background-color: #def4ff;
                        }
                    }

                    .area-title,
                    .area-border {
                        margin-bottom: 10px;
                        font-size: 15px;

                        img {
                            margin-right: 8px;
                            width: 18px;
                            height: 18px;
                            vertical-align: -2px;
                        }
                    }

                    .avatar-list {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: flex-start;
                        gap: 10px;
                        margin-bottom: 20px;

                        &__item {
                            height: 80px;

                            img {
                                width: 80px;
                                height: 80px;
                                //border-radius: 50%;
                                border: 1px solid #def4ff;
                                cursor: pointer;
                                transition: all 0.3s;

                                &:hover {
                                    opacity: 0.5;
                                }
                            }
                        }
                    }
                }
            }
        }

        .avatar-user {
            position: relative;
            margin-bottom: 20px;
            background-repeat: no-repeat;
            background-size: 100% 100%;

            .user-avatar {
                position: absolute;
                margin-top: 9px;
                width: 82px;
                height: 82px;
                border-radius: 50%;
            }

            .user-box {
                position: absolute;
                width: 130px;
                height: 130px;
            }
        }

        .avatar-price {
            img {
                padding-right: 5px;
                width: 18px;
                height: 18px;
            }
        }
    }

    &-footer {
        height: calc(540px - 430px - 56px);
        border-top: 1px solid $borderColor;

        span {
            padding: 8px 60px;
            color: #fff;
            background-color: #4f95f4;
            letter-spacing: 1px;
            border: 1px solid #4f95f4;
            border-radius: 4px;
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
                color: #4f95f4;
                background-color: #fff;
            }
        }
    }
}
</style>