<template>
    <div>
        <div class="course">
            <div class="course-title">
                <span class="course-my changeColor">我的课程</span>
                <span class="course-all">所有课程</span>
                <i class="fa fa-close" @click="$emit('close')"></i>
            </div>
            <div class="my-list">
                <div
                    class="my-list-info"
                    v-for="(item, index) in courseList" :key="index"
                    @click="changCourse(item)">
                    <div class="info-book">
                        <div class="info-book__content">
                            <span class="info-book__text" title="item.name">{{item.name}}</span>
                        </div>
                    </div>
                    <span class="info-change" v-if="item.id!=nowCourseId">切换课程</span>
                    <span class="info-change check" v-if="item.id==nowCourseId">正在学习</span>
                    <i class="fa fa-check-circle" v-if="item.id==nowCourseId"></i>
                </div>
            </div>
            <div class="all-list" style="display: none">
                <div class="list-title">
                    <span>学段</span>
                    <span>版本</span>
                    <span>年级</span>
                    <span>教材</span>
                </div>
                <el-cascader-panel :options="options" size="mini"></el-cascader-panel>
            </div>
            <div class="course-footer" style="display: none">
                <div class="course-btn">
                    <el-button class="try">试用</el-button>
                    <el-button class="subscribe">订阅</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        courseList: { type: Array},
        nowCourseId:{type:Number}
    },
    data() {
        return {
            options: [{
                value: 'shaoer',
                label: '少儿',
                children: [{
                    value: 'waiyanban',
                    label: '外研版',
                    children: [{
                        value: 'qinianji',
                        label: '七年级',
                        children: [{
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }]
                    }, {
                        value: 'banianji',
                        label: '八年级',
                        children: [{
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }]
                    }, {
                        value: 'jiunianji',
                        label: '九年级',
                        children: [{
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }]
                    }]
                }, {
                    value: 'renjiaoban',
                    label: '人教版',
                    children: [{
                        value: 'qinianji',
                        label: '七年级',
                        children: [{
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }]
                    }, {
                        value: 'banianji',
                        label: '八年级',
                        children: [{
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }]
                    }, {
                        value: 'jiunianji',
                        label: '九年级',
                        children: [{
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }]
                    }]
                }, {
                    value: 'renaiban',
                    label: '仁爱版',
                    children: [{
                        value: 'qinianji',
                        label: '七年级',
                        children: [{
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }]
                    }, {
                        value: 'banianji',
                        label: '八年级',
                        children: [{
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }]
                    }, {
                        value: 'jiunianji',
                        label: '九年级',
                        children: [{
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }]
                    }]
                }]
            }, {
                value: 'xiaoxue',
                label: '小学',
                children: [{
                    value: 'waiyanban',
                    label: '外研版',
                    children: [{
                        value: 'qinianji',
                        label: '七年级',
                        children: [{
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }]
                    }, {
                        value: 'banianji',
                        label: '八年级',
                        children: [{
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }]
                    }, {
                        value: 'jiunianji',
                        label: '九年级',
                        children: [{
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }]
                    }]
                }, {
                    value: 'renjiaoban',
                    label: '人教版',
                    children: [{
                        value: 'qinianji',
                        label: '七年级',
                        children: [{
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }]
                    }, {
                        value: 'banianji',
                        label: '八年级',
                        children: [{
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }]
                    }, {
                        value: 'jiunianji',
                        label: '九年级',
                        children: [{
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }]
                    }]
                }, {
                    value: 'renaiban',
                    label: '仁爱版',
                    children: [{
                        value: 'qinianji',
                        label: '七年级',
                        children: [{
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }]
                    }, {
                        value: 'banianji',
                        label: '八年级',
                        children: [{
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }]
                    }, {
                        value: 'jiunianji',
                        label: '九年级',
                        children: [{
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }]
                    }]
                }]
            }, {
                value: 'xiaoxue',
                label: '初中',
                children: [{
                    value: 'waiyanban',
                    label: '外研版',
                    children: [{
                        value: 'qinianji',
                        label: '七年级',
                        children: [{
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }]
                    }, {
                        value: 'banianji',
                        label: '八年级',
                        children: [{
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }]
                    }, {
                        value: 'jiunianji',
                        label: '九年级',
                        children: [{
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }]
                    }]
                }, {
                    value: 'renjiaoban',
                    label: '人教版',
                    children: [{
                        value: 'qinianji',
                        label: '七年级',
                        children: [{
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }]
                    }, {
                        value: 'banianji',
                        label: '八年级',
                        children: [{
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }]
                    }, {
                        value: 'jiunianji',
                        label: '九年级',
                        children: [{
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }]
                    }]
                }, {
                    value: 'renaiban',
                    label: '仁爱版',
                    children: [{
                        value: 'qinianji',
                        label: '七年级',
                        children: [{
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }]
                    }, {
                        value: 'banianji',
                        label: '八年级',
                        children: [{
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }]
                    }, {
                        value: 'jiunianji',
                        label: '九年级',
                        children: [{
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }, {
                            value: 'fengyeguojicihui',
                            label: '枫叶国际词汇'
                        }]
                    }]
                }]
            }]
        };
    },
    methods: {
        con() {
            //console.log(111);
        },
        changCourse(item){
            //this.nowCourseId = item.id;
            //console.log(item.id);
            this.$emit("changCourse",item.id);
        }
    }
};
</script>

<style lang="scss">
$btnColor: #66aaf5;
.course {
    position: relative;
    height: 425px;
    border-radius: 6px;

    .course-title {
        z-index: -1;
        padding-left: 20px;
        height: 48px;
        line-height: 45px;
        font-size: 16px;
        font-weight: bold;
        border-bottom: 1px solid #ddd;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px; 

        > span {
            display: inline-block;
            cursor: pointer;
        }

        .changeColor {
            color: #69adf2;
            border-bottom: 3px solid #69adf2;
        }
        .course-my {
            margin-right: 50px;
        }
        i {
            float: right;
            margin-top: 10px;
            margin-right: 20px;
            font-size: 20px;
            cursor: pointer;
            -webkit-font-smoothing: antialiased
        }
    }
    .my-list {
        display: flex;
        flex-wrap: wrap;
        overflow-y: auto;
        margin-top: 20px;
        margin-left: 25px;
        height: 340px;
        .my-list-info {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 0 18px 18px 0;
            width: 22%;
            height: 192px;
            border: 1px solid #ddd;
            cursor: pointer;
            font-size: 16px;

            &.isChecked {
                color: #33A9AB;
                border: 1px solid #33A9AB !important;
            }

            &:nth-of-type(4n) {
                margin-right: 0;
            }
            .isChange {
                color: white;
                background: #33A9AB;
            }

            .info-book {
                
                margin-bottom: 20px;
                width: 90px;
                height: 120px;
                font-size: 13px;
                text-align: center;
                transform: scale(.835);
                -webkit-transform: scale(.835);
                background: url(~@/assets/images/study/book.png) no-repeat;
                background-size: 100% 100%;
                &__content {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 84px;
                }
                &__text {
                    // overflow: hidden;
                    // display: -webkit-box;
                    // -webkit-box-orient: vertical;
                    // -webkit-line-clamp: 3;
                    line-height: 1.2;
                    width: 70px;
                    height: 46px;
                    color: #fff;
                    letter-spacing: 1px;
                }
            }
            .info-change {
                display: inline-block;
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 28.8px;
                line-height: 28.8px;
                font-size: 12px;
                text-align: center;
                letter-spacing: 1px;
                background: #eee;
            }
            .fa-check-circle {
                position: absolute;
                top: 5px;
                right: 10px;
                color: #34a9ab;
            }
            .check {
                color: #fff;
                background: #34a9ab;
            }
        }
        &::-webkit-scrollbar {
            width: 10px;
            height: 300px;
            background: #f0f0f0;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 20px;
            box-shadow: inset 0 0 5px #dcdcdc;
            background: #dcdcdc;
        }
    }
    .all-list {
         .list-title {
             display: flex;
             span {
                 width: 127px;
                 height: 33px;
                 line-height: 33px;
                 text-align: center;
                 font-weight: bold;
                 border-right: 1px solid #ddd;
                 box-sizing: border-box;
                 background: #f2f2f2;

                 &:last-of-type {
                     width: 281px;
                     border-right: none;
                 }
                 &:nth-last-child(2) {
                     width: 126px;
                 }
             }
         }
         .el-cascader-panel {
             .el-scrollbar {
                 min-width: 125px;
             }
             .el-cascader-menu {
                 height: 310px;
                 .el-cascader-menu__wrap {
                     overflow: hidden;
                 }
             }
             .el-cascader-node__postfix {
                 right: 28px;
             }
         }
         .el-cascader-node:hover {
             color: #fff;
             background: $btnColor;
         }
         .elClick {
             color: #fff;
             background: $btnColor;
         }
    }
    .course-footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 42px;
        line-height: 42px;
        text-align: center;
        background: #f2f2f2;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        .course-btn {
            .el-button {
                padding: 0;
                width: 80px;
                height: 28px;
                font-size: 12px;
                border-radius: 20px;
            }
            .try {
                color: $btnColor;
                border: 1px solid $btnColor;
            }
            .subscribe {
                color: #fff;
                background: $btnColor;
                border: 1px solid $btnColor;
            }
        }
    }
}
</style>