<template>
  <div>
    <div class="wr-container">
      <audio ref="audio">
        <source :src="src" />
      </audio>
      <div class="unit-bar">
        <div class="bar-l">
          <i class="fa fa-book"></i>
          <template v-if="studyType == 1">
            <span>{{ course.name }}-</span>-<span>{{ danyuan.name }}</span>
          </template>
          <span v-if="studyType == 2">单词复习中</span>
          <span v-if="studyType == 3">错词学习中</span>
        </div>
        <div class="bar-r">
          <i class="audio" ref="audioImg" @click="audioControl"></i>
          <i class="iconfont icon-tuichu2" @click="emitClose(type)"></i>
        </div>
      </div>
      <div class="unit-bar__progress">
        <el-progress
          :percentage="processSpeed"
          :show-text="textShow"
        ></el-progress>
        <img
          class="sport-img2"
          src="@/assets/images/study/sport.png"
          alt=""
          style="width: 40px; height: 40px; left: 0"
        />
      </div>
      <div
        class="re-body"
        :class="[xuei_step_arr[xuexi_step] == 0 && last_page_state==0 ? 'show' : 'hide']"
      >
        <div class="re-ready">
          <div class="re-text">
            <span class="item-dot">●</span>
            <span>即将开始学习, let's go</span>
          </div>
        </div>
        <el-button class="rem-btn" type="primary" @click="start"
          >开始学习</el-button
        >
      </div>
      <div
        class="re-body"
        :class="[xuei_step_arr[xuexi_step] == 8 && last_page_state==0 ? 'show' : 'hide']"
      >
        <div class="re-ready">
          <div class="re-text">
            <span class="item-dot">●</span>
            <span>进入下一组,继续学习，go!go!go!</span>
          </div>
        </div>
        <el-button class="rem-btn" type="primary" @click="start"
          >继续学习
        </el-button>
      </div>
      <div
        class="re-body"
        :class="[xuei_step_arr[xuexi_step] == 1 && last_page_state==0 ? 'show' : 'hide']"
      >
        <div class="re-detail">
          <div class="d-symbols">
<!--            <span class="s-word">{{ now_words.name }}</span>-->
            <i
              class="fa fa-volume-up"
              @click="audioPlay_3(now_words.audio_link, now_words.id)"
            ></i>
            <span class="s-text">分音节:</span>
            <span class="s-part">
              <span class="p-01 p-01-color">{{ now_words.fenyinjie }}</span>
              <span class="p-02 p-02-color"></span>
            </span>
          </div>
          <div class="d-content">
            <span class="question">?</span>
            <span class="sayChinese">快读两遍说中文</span>
          </div>
          <div class="write-body-result">
            <i class="fa fa-arrow-circle-right chArrow"></i>
          </div>
        </div>
      </div>
      <div
        class="re-body"
        :class="[xuei_step_arr[xuexi_step] == 2 && last_page_state==0 ? 'show' : 'hide']"
      >
        <div class="re-detail">
          <div class="d-symbols">
<!--            <span class="s-word">{{ now_words.name }}</span>-->
            <i
              class="fa fa-volume-up"
              @click="audioPlay_3(now_words.audio_link, now_words.id)"
            ></i>
            <span class="s-text">分音节:</span>
            <span class="s-part">
              <span class="p-01 p-01-color">{{ now_words.fenyinjie }}</span>
              <span class="p-02 p-02-color"></span>
            </span>
          </div>
          <div class="d-content sideLeft">
            <div class="d-content-info">
              <span class="info-english">{{ now_words.name }}</span>
              <!--<img src="@/assets/images/study/u811.png" alt="" />-->
              <!--幼儿-->
                <div class="d-img" v-if="nianji_level == 1">
                    <img :src="audioUrl + now_words.img_link" alt="" @error="imgError" />
                    <!--<span class="d-img__text">{{ now_words.name }}</span>-->
                </div>
            </div>
          </div>
          <div class="write-body-result">
            <i class="fa fa-check-circle-o nextOp" @click="nextOption"></i>
            <i
              class="fa fa-headphones"
              @click.stop="audioPlay_3(now_words.audio_link, now_words.id)"
            ></i>
            <i class="fa fa-times-circle-o emoji" @click="forget"></i>
          </div>
          <!--<div class="read-countdown-time">
                      <i class="fa fa-sun-o time-icon"></i>
                      <span class="custom-textshadow">{{djs_count}}</span>
                    </div>-->
        </div>
      </div>
      <div
        class="re-body"
        :class="[xuei_step_arr[xuexi_step] == 3 && last_page_state==0 ? 'show' : 'hide']"
      >
        <div class="re-detail">
          <div class="d-symbols">
            <span class="sy-text" v-if="now_words_daan == 2">{{
              now_words.name
            }}</span>
            <i
              class="fa fa-volume-up"
              @click="audioPlay_3(now_words.audio_link, now_words.id)"
            ></i>
            <!--<span class="sy-text-info">{{ now_words.analysis }}</span>-->
          </div>

          <div class="d-content sideLeft">
            <div class="spell-word">
              <span
                class="item-spelling-sel-input"
                v-for="(v, k) in xz_words"
                :key="k"
                @click="del_word(k)"
                >{{ now_words.zimu_list[v] }}</span
              >
            </div>
            <div class="spell-info" @click="removeAnimate('.spell-info')">
              <ul
                class="info-list"
                v-for="(v, k) in now_words.zimu_list"
                :key="k"
              >
                <li class="i-item">
                  <span
                    :class="[in_array(k, xz_words) >= 0 ? 'successBg' : '']"
                    @click="set_words(k)"
                    >{{ v }}
                  </span>
                </li>
              </ul>
            </div>
            <span class="spell-forget" @click="nextOption">[忘记了]</span>
          </div>
          <div class="write-body-result">
            <img
              src="@/assets/images/study/refresh.png"
              class="refresh"
              @click="refresh"
            />
            <i class="fa fa-arrow-circle-right s-right" @click="nextOption"></i>
          </div>
        </div>
      </div>
      <div
        class="re-body bodyTop"
        :class="[xuei_step_arr[xuexi_step] == 4 && last_page_state==0 ? 'show' : 'hide']"
      >
        <div class="re-body-info">
          <div class="meaning">
            <el-button class="mBtn" @click="set_yyts()">
              <template v-if="yyts">词义隐藏</template>
              <template v-else>词义提示</template>
            </el-button>
            <span class="mWord" v-if="yyts">
              <template v-for="item in now_words.cixing">
                {{ item.text }}.
              </template>
              {{ now_words.analysis }}
            </span>
          </div>
          <div class="liWrite">
            <i
              class="fa fa-headphones"
              @click.stop="audioPlay_3(now_words.audio_link, now_words.id)"
            ></i>
            <div class="liText">
              <input
                type="text"
                autocomplete="off"
                v-model="px_answer"
                v-on:input="pin_input(px_answer)"
                class="spell-txt"
                ref="pinInput"
              />
            </div>
            <el-button class="next" @click="nextOption" v-if="btn_show"
              >下一个</el-button
            >
            <i
              class="fa fa-check"
              v-if="btn_show == 0 && moxie_answer == 1"
            ></i>
            <i
              class="iconfont icon-V1"
              v-if="btn_show == 0 && moxie_answer == 2"
            ></i>
          </div>
          <!--<div class="word">
                      <span class="word-type"></span>
                      <span class="word-text"></span>
                    </div>-->
          <div class="phonetic" v-if="moxie_answer == 2">
            <span class="phonetic-word">{{ now_words.name }}</span>
            <span class="phonetic-symbols" v-if="now_words.ipa"
              >[{{ now_words.ipa }}]
            </span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="re-body memory"
      :class="[xuei_step_arr[xuexi_step] == 5 && last_page_state==0 ? 'show' : 'hide']"
    >
      <div class="memory-body">
        <div
          class="memoryBox"
          v-for="(item, index) in allLists"
          :key="index"
          v-show="huiyi_show_index > index"
          ref="con"
          @click="audioPlay_3(item.audio_link, item.id)"
        >
          <div class="memoryContainer">
            <div class="memory-body-item english">
              <span class="memory-body-item-word">{{ item.name }}</span>
            </div>
            <div class="memory-body-item chinese">
              <span class="memory-body-item-word">{{ item.analysis }}</span>
            </div>
          </div>
          <i
            class="fa fa-volume-up"
            @click.stop="audioPlay_3(item.audio_link, item.id)"
            title="发音"
          ></i>
        </div>
      </div>
      <el-button class="exitBtn" @click="emitClose(type)">Exit</el-button>
    </div>
    <div
      class="summary"
      :class="[last_page_state==1 ? 'show' : 'hide']"
    >
      <div class="summary-body">
        <div class="summary-body-l">
          <div class="summary-list__content">
            <div class="content-item">
              <span class="dot">●</span>
              <span class="item-text">单词总数（个）：</span>
              <span class="item-num">{{ wordsLists.length }}</span>
            </div>
            <div class="content-item">
              <span class="dot">●</span>
              <span class="item-text">未完成数（个）：</span>
              <span class="item-num word-num">{{
                wordsLists.length - rightCount
              }}</span>
            </div>
            <div class="content-item">
              <span class="dot">●</span>
              <span class="item-text">本次学习（个）：</span>
              <span class="item-num word-num">{{ rightCount }}</span>
            </div>
          </div>
          <div class="summary-list__content">
            <div class="content-item">
              <span class="dot">●</span>
              <span class="item-text" v-if="studyType == 1"
                >完成本单元可获</span
              >
              <span class="item-text" v-else>保存后可获</span>
              <span class="item-num word-num gain-num">{{baoshi_num}}</span>
              <i class="iconfont icon-zuanshi"></i>
            </div>
          </div>
          <div class="summary-list__content" v-if="studyType == 1">
            <div class="content-item">
              <span class="dot">●</span>
              <span>继续学习可获更多宝石</span>
            </div>
          </div>
        </div>
        <div class="summary-body-r">
          <div class="r-logo">
            <div class="test-logo"></div>
            <span>X</span>
            <span>{{baoshi_num}}</span>
          </div>
          <div class="btn-box">
            <el-button
              class="btn-test"
              v-if="rightCount < daan_lists.length"
              @click="jixuXuexi"
              >继续学习
            </el-button>
            <el-button
              class="btn-test"
              v-if="
                rightCount >= daan_lists.length &&
                studyType == 2 &&
                reviewType == 2 &&
                nextFuxiType.count > 0
              "
              @click="jixuFuxi"
              >继续复习“{{ nextFuxiType.name }}”环节
            </el-button>
            <el-button
              class="btn-test"
              v-if="rightCount >= daan_lists.length && studyType == 1"
              @click="ceshiDo"
              >单元测试
            </el-button>
            <el-button class="btn-exit" @click="addData">保存退出</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="re-footer">
      <span
        class="warm-tips"
        style="fontsize: 12px; color: #777; padding-left: 15px"
        >【温馨提示：点击“CTRL”键再播放一遍，点击回车键到下一步】</span
      >
      <div class="time-total">
        <!--<span class="t-text">记忆时间</span
                ><span class="t-time"
                  >[ <span class="t-time-text">00:03:41</span>]</span
                >-->
        <span class="t-current">当前生词数</span>
        <span class="t-num"
          >[<span class="t-num-text">{{ wordNum }}</span
          >]个</span
        >
        <span class="t-current">共</span>
        <span class="t-num"
          >[<span class="t-num-text">{{ all_lun }}</span
          >]轮</span
        >
        <span class="t-shibie">当前第</span
        ><span class="t-total"
          >[<span class="t-total-text">{{ now_lun }}</span
          >]轮</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { mapMutations, mapState } from "vuex";
import {imgError,nianjiLevel} from "@/utils/utils";

let key_code = {
  13: "回车",
  17: "ctrl",
  32: " ",
  65: "a",
  66: "b",
  67: "c",
  68: "d",
  69: "e",
  70: "f",
  71: "g",
  72: "h",
  73: "i",
  74: "j",
  75: "k",
  76: "l",
  77: "m",
  78: "n",
  79: "o",
  80: "p",
  81: "q",
  82: "r",
  83: "s",
  84: "t",
  85: "u",
  86: "v",
  87: "w",
  88: "x",
  89: "y",
  90: "z",
  190: ".",
  222: "'",
  1048: ")",
  1049: "!",
  1057: "(",
  1191: "?",
};

export default {
  name: "aiWrite",
  props: {
    title: { type: String },
    audioUrl: { type: String },
    course: { type: Object },
    danyuan: { type: Object },
    wordsLists: { type: Array },
    allLists: { type: Array, default: [] },
    studyType: { type: Number, default: 1 }, //类型，1学习，2复习，3错词
    reviewType: { type: Number, default: 1 }, //复习类型，1复习，2一键复习
    nextFuxiType: { type: Object }, //下一个复习类型
  },
  data() {
    return {
      key_code: key_code,
      textShow: false,
      cShow: true,
      bShow: true,
      sShow: true,
      xuexi_step: 0, //学习步骤,0开始学习，1读，2单选，3多选
      xuexi_step_old: 0,
      xuei_step_arr: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      last_page_state:0,//是否显示最后环节
      du_num: 2, //读环节的读次数
      testVisible: false,
      menuList: [
        { id: 1, mText: "记忆夹生词（个）：", mNum: 0 },
        { id: 2, mText: "拼写夹生词（个）：", mNum: 1 },
        { id: 3, mText: "连续识别错误（个）：", mNum: 0 },
        { id: 4, mText: "连续拼写错误（个）：", mNum: 1 },
      ],
      leter_serial: ["A", "B", "C", "D", "D"],
      study_mk: 0, //模块
      lun_num: 10, //每轮多少个
      all_lun: 0, //总共多少轮
      now_lun: 1, //当前第几轮
      now_words: {}, //当前单词
      now_words_index: 0, //当前单词索引（所有单词）
      now_lun_words_index: 0, //当前轮单词的索引
      now_words_daan: 0, //当前答案是否正确，默认0，1正确，2错误
      now_lun_count: 0, //当前轮多少个
      now_lun_finish_count: 0, //完成多少个
      process_speed: 0, //进度条 - 百分比
      process_c: 0, //进度条
      lun_lists_id_arr: [], //当前轮lists
      error_lists_arr: [], //错误单词
      now_sentence: { info: "", chinese: "" }, //当前例句
      now_sentence_index: 0, //当前例句索引
      daan_lists: [], //答案列表
      djs_obj: 1, //倒计时对象
      djs_count: 5, //答错后倒计时5秒
      djs_state: 1, //倒计时状态
      djs_stop: 0,
      xz_words: [], //选中的单词索引列表
      px_answer: "", //拼写答案
      px_old_ans_arr: [],
      moxie_answer: 0, //拼写结果 0:未选 1：对 2-错
      moxie_next_btn: 0, //默写环节，下一个按钮，点击次数，第一次确认答案，第二次到下一个
      btn_show: 1, //1显示，不显示
      yyts: false, //显示语义提示
      start_time: 0,
      end_time: 0,
      use_time: 0,
      now_daan: "",
      type: 3, //学习类型，1 记忆，2 词听，3 听写，4 默写
      mode_type: 1,
      qzfx_level: 0, //是否要强制拼写
      audio_obj: [],
      action_status: 1,
      is_goto_test: 0,
      huiyi_show_index: 0,
      playState: 1,
      src: require("../../assets/audio/Drip.mp3"),
      audioImg: {
        play: require("../../assets/images/study/exitmusic03.png"),
        paused: require("../../assets/images/study/exitmusic01.png"),
      },
      key_state : 0, //快捷键未按下
      d_code :"",
      is_enter:1,//快捷键是否允许回车，1是允许回车，0不允许
      baoshi_num:0,
      get_baoshi_type:1,//获取宝石类型，1学习，3复习
      imgError:null,
      nianji_level:0,//年纪等级，0默认，1少儿
    };
  },
  created() {
    this.imgError = imgError;
    this.nianji_level = nianjiLevel(this.user,this.course,this.studyType);
    //console.log("nianji_level:",this.nianji_level);

    this.initData();
    if(this.studyType ==1){
        this.get_baoshi_type = 1;
        this.getBaoshi();
    }else if(this.studyType ==2){
        this.get_baoshi_type = 3;
        this.getBaoshi();
    }
    //this.huiyi_ing();
  },
  updated() {
    let sportImg2 = document.querySelector(".sport-img2");
    if (this.processSpeed === 0) {
      sportImg2.style.left = 0;
    } else if (this.processSpeed === 100) {
      sportImg2.style.left = 97 + "%";
    } else {
      sportImg2.style.left = this.processSpeed - 3 + "%";
    }
  },
  computed: {
    ...mapState({
      token: (state) => state.user.token,
      user: (state) => state.user.user,
    }),
    rightCount() {
      let right_count = 0;
      this.daan_lists.forEach((item, index) => {
        if (item.answer == 1) {
          right_count++;
        }
      });
      return right_count;
    },
    processSpeed() {
      let a = 0;
      if (this.xuei_step_arr[this.xuexi_step] == 5) {
        a = (this.huiyi_show_index / this.allLists.length) * 100;
      } else {
        a = (this.now_lun_finish_count / this.now_lun_count) * 100;
        //console.log("now_lun_finish_count:" + this.now_lun_finish_count);
        //console.log("now_lun_count:" + this.now_lun_count);
      }
      //console.log("a:" + a);
      return a > 100 ? 100 : a;
    },
    wordNum() {
      return this.wordsLists.length;
    },
  },
  mounted: function () {
    this.changeVolume();
    const that = this;
    $(".memoryBox")
      .click(function () {
        const index = $(".memoryBox").index(this);
        //console.log(index);
        if ($(".memoryContainer").eq(index).hasClass("convers")) {
          $(".memoryContainer").eq(index).removeClass("convers");
        } else {
          $(".memoryContainer").eq(index).addClass("convers");
        }
      })
      .mouseenter(function () {
        const vIndex = $(".memoryBox").index(this);
        //console.log(vIndex);
        $(".memoryBox .fa").eq(vIndex).css("display", "block");
      })
      .mouseleave(function () {
        const lIndex = $(".memoryBox").index(this);
        $(".memoryBox .fa").eq(lIndex).css("display", "none");
      });
    // 右箭头 下一步
    this.animations(".chArrow", () => {
      this.duNext();
    });
    // 右箭头 下一题
    // this.animations('.nextOp', ()=>{
    //   that.nextOption();
    // })
    // 哭脸
    // this.animations('.emoji', ()=> {
    //   that.forget();
    // });

    //键盘事件
    /*var key_state = 0; //未按下
    var d_code = "";
    $(document).keydown(function (event) {
      if (key_state != 1) {
        key_state = 1;
        d_code = event.keyCode;
      }
    });
    $(document).keyup(function (event) {
      if (event.keyCode == d_code) {
        key_state = 0;
        d_code = "";
      }
      //console.log("event");
      //console.log(event.keyCode);
      that.key_event(event.keyCode, d_code);
    });*/

    window.addEventListener(
      "keyup",this.key_up,false
    );
    window.addEventListener(
      "keydown",this.key_down,false
    );
  },
  methods: {
    // 背景音乐
    audioControl() {
      let audioImg = this.$refs.audioImg;
      let audio = this.$refs.audio;
      if (this.playState === 0) {
        audioImg.style.backgroundImage = "url(" + this.audioImg.paused + ")";
        audio.pause();
        this.playState = 1;
      } else {
        audioImg.style.backgroundImage = "url(" + this.audioImg.play + ")";
        audio.play();
        this.playState = 0;
      }
    },
    // 播放器音量
    changeVolume() {
      let audio = this.$refs.audio;
      audio.volume = 0.3;
    },
    // 动画结束前禁止点击
    animations(arg, fc) {
      var fa = document.querySelector(arg); //'.faArrow'
      fa.addEventListener("animationstart", function () {
        this.style.cursor = "normal";
        //console.log("动画开始");
        this.onclick = function () {
          return false;
        };
      });
      fa.addEventListener("animationend", function () {
        this.style.cursor = "pointer";
        //console.log("动画结束");
        this.onclick = function () {
          fc();
        };
      });
    },
    fadeIns(arg) {
      let dContent = document.querySelector(arg);
      if(dContent != null){
        dContent.classList.add("fadeIns");
      }
    },
    removeAnimate(arg) {
      let dContent = document.querySelector(arg);
      if(dContent != null){
        dContent.classList.remove("fadeIns");
        //console.log(dContent.classList);
      }
    },
    initData() {
      //重新初始化数据
      /*this.du_num = 2;
            this.xuexi_step = 0;
            this.xuexi_step_old = 0;
            this.now_words_daan = 0;
            this.now_lun = 1;
            this.djs_stop = 0;
            this.djs_state = 1;
            this.now_lun_count = 0;
            this.now_lun_finish_count = 0;
            this.xz_words = [];
            this.qzfx_level = 0;
            this.action_status = 1;
            this.is_goto_test = 0;*/
      //this.studyWords(this.now_words_index);
      this.setLunListsId(this.now_lun);
      this.randNowWord();
      this.daan_lists = this.wordsLists.map((item, index, arr) => {
        let temp_item = {
          id: item.id,
          name: item.name,
          type: this.type,
          mode_type: this.mode_type,
          answer: 0,
          levels: 0,
          right_num: 0,
          error_num: 0,
          use_time: 0,
          use_time_yuxi: 0,
          huanjie: "",
          error_type: "",
          study_type: this.studyType,
          jilu: [],
          score: [],
          is_xuexi: 0,
        };
        return temp_item;
      });


      if (this.studyType == 2 && this.nextFuxiType.last_type >-1){
          //复习
          this.xuexi_step = 4;
      }
    },
    start() {
      if (this.studyType == 1) {
        if(this.user.is_yuxi==0){//关闭预习
            this.xuexi_step = 3;
        }else{
            this.xuexi_step = 1;
        }
      } else if (this.studyType == 3){
         this.xuexi_step = 3;
      }else {
        this.xuexi_step = 4;
      }
      ////console.log("this.xuexi_step",this.xuexi_step);
      this.randNowWord();
    },
    toDanxuan() {
      //单选环节(暂时取消使用)
      this.xuexi_step = 2;
      this.setDjs(0);
    },
    duNext() {
      let step_old = this.xuexi_step_old;
      //console.log("step_old：" + step_old);
      this.start_time = this.getTime(); //开始时间
      let than = this;
      if (step_old == 0 || step_old == 1) {
        step_old = 2;
      }
      switch (step_old) {
        case 0: //开始学习
          //this.xuexi_step = 0;
          //this.xuexi_step = 2;
          break;
        case 1: //读
          //this.xuexi_step = 1;
          //this.xuexi_step = 2;
          break;
        case 2:
          this.xuexi_step = 2;
          /*this.setDjs(5, function () {
                      than.forget();
                      than.state = 0;
                    });*/
          //this.randNowWord();
          break;
        case 3:
          this.randNowWord();
          this.xuexi_step = 3;
          break;
        case 4:
          this.xuexi_step = 4;
          break;
        case 5:
          this.xuexi_step = 5;
          break;
        default:
          //进入默认页
          /*this.setDjs(5, function () {
                      than.forget();
                      than.state = 0;
                    });*/
          this.xuexi_step = 2;
          break;
      }
      this.xuexi_step_old = 0;
    },
    nextOption() {
      // this.fadeIns('.sideLeft');
      this.fadeIns(".spell-info");
      //正确后，下一个选项
      //console.log("next-xuexi_step" + this.xuexi_step);
      let step = this.xuei_step_arr[this.xuexi_step];
      let than = this;
      switch (step) {
        case 0: //开始学习
          this.start_time = this.getTime();
          break;
        case 1: //读
          //this.du_num =2;//重置第一个环节数字
          //this.xuexi_step =2;
          this.start_time = this.getTime();
          break;
        case 2:
          ////console.log(this);
          //this.xuexi_step++;
          //当前轮没有单词，进入下一轮
          this.setStepOld();
          this.delErrorWords();
          if (!this.hasErrorWords()) {
            //不在错误单词中，才可以删除
            this.delLunListsWord(this.now_lun_words_index); //刪除正確的單詞
            this.addProgress();
          }
          if (this.nextWords()) {
            this.xuexi_step = 1;
          } else {
            //进入下一环节
            this.xuexi_step++;
            if (this.nextLun(this.now_lun)) {
              this.randNowWord();
            } else {
              this.xuexi_step++; //进入下一个环节-默写环节
            }
          }
          this.start_time = this.getTime();
          break;
        case 3: //听写
          if (this.next_nxhy()) {
            this.addCaozuLog(1);
            this.delErrorWords();
            if (!this.hasErrorWords()) {
              //不在错误单词中，才可以删除
              this.delLunListsWord(this.now_lun_words_index); //刪除正確的單詞
              this.addProgress();
            }
            //当前轮没有单词，进入下一轮
            if (this.nextWords()) {
              //this.xuexi_step = 4;
            } else {
              //进入下一环节
              this.xuexi_step++;
              if (this.nextLun(this.now_lun)) {
                //进入下一个环节-默写环节
                this.randNowWord();
                ////console.log("进入下一环节randNowWord");
                this.$nextTick(() => {
                  this.$refs.pinInput.focus();
                });
              } else {
                this.xuexi_step++;
              }
            }
            //console.log("sussess");
          } else {
            //错误
            //console.log("error");
            this.now_words_daan = 2;
            //this.addErrorWords(); //添加错词
            this.addCaozuLog(0);
            //this.xuexi_step = 4;
          }
          this.start_time = this.getTime();
          break;
        case 4: //默写
          //console.log("默写");
          this.$refs["pinInput"].focus();
          if (this.px_answer == "") {
            return;
          }
          if (this.px_is_t()) {
            if (this.moxie_answer == 1) {
              this.px_answer = "";
              this.delErrorWords();
              if (!this.hasErrorWords()) {
                //不在错误单词中，才可以删除
                this.delLunListsWord(this.now_lun_words_index); //刪除正確的單詞
                this.addProgress();
              }
              this.moxie_answer = 0;
              this.yyts = 0;
              if (this.nextWords()) {
                //console.log("nextWords");
                //console.log(this.now_words);
              } else {
                //进入下一轮
                this.now_lun++;
                if (this.nextLun(this.now_lun)) {
                  //this.randNowWord();//进入到界面页面，不需要随机
                  this.xuexi_step = 8;
                  this.xuexi_step_old = 0; //下一轮从第二环节开始学习
                  this.du_num = 2;
                } else {
                  //所有单词学习完成
                  //console.log("所有单词学习完成");
                  //this.xuexi_step++; //进入复习环节
                  if (this.studyType == 1) {
                    this.xuexi_step = 5;
                    this.huiyi_ing();
                  } else {
                    //this.xuexi_step = 6;
                    this.last_page_state = 1;
                  }
                }
              }
              break;
            }
            this.qzfx_level = 0;
            this.addRightAnswer(1); //添加到正确答案
            this.btn_show = 0;
            this.moxie_answer = 1;
            this.setDjs(1, function () {
              than.btn_show = 1;
              than.nextOption();
            });
          } else {
            this.qzfx_level = 1; //开启强制拼写
            this.px_answer = "";
            //console.log("错误");
            this.addErrorWords(3);
            this.yyts = true;
            this.btn_show = 0;
            this.moxie_answer = 2;
            this.setDjs(1, function () {
              than.btn_show = 1;
            });
            //this.xuexi_step = 5;
          }
          this.addUseTime();
          this.start_time = this.getTime();
          break;
        case 5: //复习
          break;
        case 6: //查看结果
          break;
        default:
          break;
      }
    },
    setLunListsId(lun) {
      this.lun_lists_id_arr = [];
      //获取当前轮id
      //console.log("lun:" + lun);
      //console.log("now_lun:" + this.now_lun);
      let start_index = this.lun_num * (lun - 1);
      let end_index = this.lun_num * lun;
      let words_length = this.wordsLists.length;
      if (end_index > words_length) {
        end_index = words_length;
      }
      this.now_lun_count = end_index - start_index;
      this.all_lun = Math.ceil(words_length / this.lun_num);
      //console.log("all_lun:" + this.all_lun);
      //console.log("start:" + start_index + "end:" + end_index);
      for (let index = start_index; index < end_index; index++) {
        this.lun_lists_id_arr.push(index);
      }
      //console.log("setLunListsId");
      //console.log(this.lun_lists_id_arr);
      //console.log(this.wordsLists);
      //this.lun_lists_id_arr = this.unique(this.lun_lists_id_arr);//去重复
    },
    randNowWord() {
      //随机当前学的单词
      let lun_lists_count = this.lun_lists_id_arr.length;
      let randNum = this.getRandomInt(0, lun_lists_count); //获取当前随机数
      this.now_words_index = this.lun_lists_id_arr[randNum];
      ////console.log('randNum:'+randNum+'now_words_index:'+this.now_words_index);
      this.now_words = this.wordsLists[this.now_words_index];
      this.now_lun_words_index = randNum;
      this.resetNowWord(); //重置当前单词释义
      this.resetWordOrder(); //词义重新排序
      ////console.log("now_words");
      ////console.log(this.now_words);
      ////console.log(this.lun_lists_id_arr);
      this.now_words.zimu_list = this.get_zimu_list(this.now_words.name);
      this.xz_words = [];
      this.now_words_daan = 0;
      if (this.xuexi_step != 0 && this.xuexi_step != 8) {
        this.audioPlay_3(this.now_words.audio_link, this.now_words.id);
      }
      this.now_sentence_index = 0;
      if(this.now_words['duanyu'].length>0){
          this.now_sentence = this.now_words['duanyu'][this.now_sentence_index];
      }else{
          this.now_sentence = [];
      }

    },
    delLunListsWord(now_lun_words_index) {
      //删除当前轮里能过的单词
      ////console.log("this.now_lun_words_index:"+this.now_lun_words_index);
      //console.log("delLunListsWord-now_lun_words_index");
      //console.log(now_lun_words_index);
      this.lun_lists_id_arr.splice(now_lun_words_index, 1);
      //console.log(this.lun_lists_id_arr);
    },
    nextWords() {
      ////console.log(this.all_lun + "----" + this.now_lun);
      //console.log("this.lun_lists_id_arr" + this.lun_lists_id_arr);
      if (this.lun_lists_id_arr.length <= 0) {
        return 0; //当前轮没有数据了
      } else {
        this.randNowWord();
        return 1;
      }
    },
    nextLun(now_lun) {
      this.now_lun_finish_count = 0;
      //下一轮
      if (now_lun > this.all_lun) {
        return 0; //没有下一轮了
      } else {
        this.setLunListsId(now_lun);
        return 1;
      }
    },
    clkDuNum() {
      if (this.du_num > 0) {
        this.du_num--;
      }
    },
    clkBeiYiBei() {
      //背一背
      this.xuexi_step = 1;
      this.now_words_daan = 0;
      this.du_num = 2;
      //下一个环节重置单词
      this.resetNowWord();
    },
    nextSence() {
      //例句索引
      this.now_lun_finish_count = 0;
      //下一轮
      if (now_lun > this.all_lun) {
        return 0; //没有下一轮了
      } else {
        this.setLunListsId(now_lun);
        return 1;
      }
    },
    forget() {
      this.djs_state = 0;
      //忘记，不记得
      //console.log("forget");
      this.setStepOld();
      let xuexi_step = this.xuexi_step;
      switch (xuexi_step) {
        case 1:
          break;
        case 2:
          this.xuexi_step = 1;
          //this.du_num = 2;
          this.addErrorWords(1);
          break;
        case 3:
          break;
        case 4:
          break;
        case 5:
          break;
        default:
          break;
      }
    },
    refresh() {
      //console.log("refresh");
      this.xz_words = [];
    },
    moXie() {
      return 0;
    },
    unique(array) {
      var n = {},
        r = [],
        len = array.length,
        val,
        type;
      for (var i = 0; i < array.length; i++) {
        val = array[i];
        type = typeof val;
        if (!n[val]) {
          n[val] = [type];
          r.push(val);
        } else if (n[val].indexOf(type) < 0) {
          n[val].push(type);
          r.push(val);
        }
      }
      return r;
    },
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min)) + min; //不含最大值，含最小值
    },
    resetNowWord() {
      var shiyi = this.now_words.shiyi;
      for (var i = 0; i < shiyi.length; i++) {
        this.now_words.shiyi[i].is_xz = 0;
      }
      var cixing = this.now_words.cixing;
      for (var i = 0; i < cixing.length; i++) {
        this.now_words.cixing[i].is_xz = 0;
      }

      var shiyi_dx = this.now_words.shiyi_dx; //单选
      for (var i = 0; i < shiyi_dx.length; i++) {
        this.now_words.shiyi_dx[i].is_xz = 0;
      }
      var shiyi_zyy = this.now_words.shiyi_zyy; //中释英
      for (var i = 0; i < shiyi_zyy.length; i++) {
        this.now_words.shiyi_zyy[i].is_xz = 0;
      }
      //this.resetWordOrder();//重新排序

      ////console.log("resetNowWord");
      ////console.log("xuexi_step:"+this.xuexi_step);
      ////console.log("shiyi.length:"+this.now_words.shiyi.length);
      ////console.log(this.now_words.shiyi);
      ////console.log("now_words");
      ////console.log(this.now_words);
    },
    resetWordOrder() {
      //重新排序
      var shiyi = this.now_words.shiyi;
      var newarr = [];
      while (shiyi.length > 0) {
        var len = parseInt(Math.random() * shiyi.length);
        newarr.push(shiyi[len]);
        shiyi.splice(len, 1);
      }
      this.now_words.shiyi = newarr;

      //重新排序
      var shiyi_dx = this.now_words.shiyi_dx;
      let item = shiyi_dx.splice(shiyi_dx.length - 1, 1);
      var newarr02 = [];
      while (shiyi_dx.length > 0) {
        var len = parseInt(Math.random() * shiyi_dx.length);
        newarr02.push(shiyi_dx[len]);
        shiyi_dx.splice(len, 1);
      }
      newarr02.push(...item);
      this.now_words.shiyi_dx = newarr02;

      //重新排序
      var shiyi_zyy = this.now_words.shiyi_zyy;
      let item02 = shiyi_zyy.splice(shiyi_zyy.length - 1, 1);
      var newarr03 = [];
      while (shiyi_zyy.length > 0) {
        var len = parseInt(Math.random() * shiyi_zyy.length);
        newarr03.push(shiyi_zyy[len]);
        shiyi_zyy.splice(len, 1);
      }
      newarr03.push(...item02);
      this.now_words.shiyi_zyy = newarr03;
    },
    /**
     * [study_djs 倒计时]
     * @param  {Number} value [description]
     * @return {[type]}       [description]
     */
    setDjs(value = 0, callback) {
      var that = this;
      if (value > 0) {
        that.djs_count = value;
      }
      if (this.djs_obj) {
        clearInterval(this.djs_obj);
      }
      //console.log("倒计时" + that.djs_count);
      that.djs_state = 1;
      this.djs_obj = setInterval(function () {
        if (that.djs_state != 1) {
          clearInterval(that.djs_obj);
          return false;
        }
        if (that.djs_stop == 0) {
          //是否暂停，1暂停
          if (that.djs_count > 0) {
            that.djs_count--;
          } else {
            clearInterval(that.djs_obj);
            if (typeof callback == "function") {
              callback();
            }
          }
        }
      }, 250);
    },
    setStepOld() {
      switch (this.xuexi_step) {
        case 1:
          this.xuexi_step_old = 1;
          break;
        case 2:
          this.xuexi_step_old = 2;
          break;
        case 3:
          this.xuexi_step_old = 3;
          break;
        case 4:
          this.xuexi_step_old = 4;
          break;
        case 5:
          this.xuexi_step_old = 5;
          break;
        default:
          this.xuexi_step_old = 0;
      }
    },
    next_nxhy(is_t = 0) {
      var word = this.get_word();
      if (is_t == 1 || word == this.now_words.name) {
        /*this.hide_word();
                        var index = this.now_words_index;
                        index++;
                        if(index>=this.lists.length){
                            this.set_progress(1,1,this.now_words.id);
                            this.next_study_mk();
                        }else{
                            this.process_c++;
                            this.set_progress(this.process_c,this.lists.length,this.now_words.id);
                            this.study_words(index);
                            this.show_fade = 0;
                            var that = this;
                            setTimeout(function(){that.show_fade = 1},1000);
                        }*/
        return 1;
      } else {
        //this.show_word();
        return 0;
      }
    },
    del_word(index = -1) {
      //console.log(index);
      if (index >= 0) {
        this.xz_words.splice(index, 1);
      } else {
        this.xz_words.splice(this.xz_words.length - 1, 1);
      }
    },
    /**
     * [set_words 选中取消单词]
     * @param {[type]} index [description]
     */
    set_words(index) {
      var i = this.in_array(index, this.xz_words);
      if (i >= 0) {
        this.xz_words.splice(i, 1);
      } else {
        this.xz_words.push(index);
      }
    },
    addRightAnswer(answer) {
      //添加正确答案
      let xuexi_step = 0;
      switch (xuexi_step) {
        case 0:
          break;
        case 1:
          break;
        case 2:
          break;
        case 3:
          break;
        case 4:
          break;
        default:
          break;
      }
      if (this.err_words) this.err_words.error_num;
      let daan_words = this.daan_lists[this.now_words_index];
      if (daan_words) {
        daan_words.answer = answer ? answer : 0;
        daan_words.type = this.type;
        daan_words.mode_type = this.mode_type;
        daan_words.right_word_num = 0;
        daan_words.error_word_num = 0;
        daan_words.use_time = this.use_time;
        daan_words.error_num = this.err_words
          ? daan_words.error_num + this.err_words.error_num
          : daan_words.error_num;
        daan_words.is_xuexi = 1; //后台数据筛选用
        //let jilu = {answer:answer,type:1,mode_type:1,study_type:1,huanjie:'记忆',use_time:this.use_time,error_type:1};
        //daan_words.jilu.push(jilu);
      }
    },
    //根据已选字母生成单词
    get_word() {
      var that = this;
      var word = "";
      this.xz_words.forEach(function (t, y) {
        if (word) {
          word = word + that.now_words.zimu_list[t];
        } else {
          word = that.now_words.zimu_list[t];
        }
      });
      return word;
    },
    addCaozuLog(answer) {
      let step = this.xuei_step_arr[this.xuexi_step];
      let huanjie = "";
      switch (step) {
        case 0:
          break;
        case 1:
          break;
        case 2:
          //huanjie = "记忆-单选";
          break;
        case 3:
          huanjie = "";
          break;
        case 4:
          huanjie = "";
          break;
        default:
          break;
      }
      //添加操作记录
      let daan_words = this.daan_lists[this.now_words_index];
      //console.log("添加操作记录");
      //console.log(daan_words);
      //console.log("this.use_time:" + this.use_time);
      if (daan_words) {
        let jilu = {
          word_id: daan_words.id,
          answer: answer,
          type: this.type,
          mode_type: this.mode_type,
          study_type: this.studyType,
          daan: this.now_daan,
          huanjie: huanjie,
          use_time: this.use_time,
          error_type: 0,
        };
        daan_words.jilu.push(jilu);
        if (answer) {
          daan_words.right_num++;
        } else {
          daan_words.error_num++;
        }
      }
    },
    //根据单词获取字母
    get_zimu_list(word) {
      var a = "abcdefghijklmnopqrstuvwxyz";
      word = word.split("");
      for (var i = 0; i < 2; i++) {
        word.push(a[Math.floor(Math.random() * 26)]);
      }
      return this.shuzu_daluan(word);
    },
    //数组随机排序
    shuzu_daluan(arr) {
      var len = arr.length;
      for (let i = 0; i < len; i++) {
        let index = parseInt(Math.random() * (len - i)) + i;
        if (index != i) {
          let temp = arr[i];
          arr[i] = arr[index];
          arr[index] = temp;
        }
      }
      return arr;
    },
    /**
     * [in_array 字符串是否在数组内]
     * @param  {[type]} v   [字符串]
     * @param  {[type]} arr [数组]
     * @return {[type]}     [返回位置  -1不在数组内]
     */
    in_array(v, arr) {
      for (var i = 0; i < arr.length; i++) {
        if (v == arr[i]) {
          return i;
        }
      }
      return -1;
    },
    /**
     * [px_is_t 判断拼写是否正确]
     * @return {[type]} [true-错 false-对]
     */
    px_is_t() {
      var that = this;
      if (this.trim(this.px_answer) == this.now_words.name) {
        return true;
      }
      return false;
    },
    isNumber(obj) {
      return typeof obj === "number" && !isNaN(obj);
    },
    pin_input(value) {
      /*var pin_study_rec = this.pin_study_rec[this.pin_words.id]||{qzfx_level:0,ture_num:0,error_num:0,lx_t_num:0,n_qzfx_level:0,};
              var qzfx_level = pin_study_rec.qzfx_level||0;
              if(qzfx_level==0){return false;}*/
      //console.log(value);
      this.removeAnimate('.re-body-info');
      if (this.qzfx_level == 0) {
        return false;
      }
      var px_old_ans_arr = this.px_old_ans_arr;
      var inp_arr = value.split("");
      var zimu_arr = this.now_words.name.split("");
      var index = inp_arr.length - 1;
      if (index < 0) {
        index = 0;
      }
      if (inp_arr.length >= px_old_ans_arr.length) {
        //输入字母
        if (inp_arr[index] !== zimu_arr[index]) {
          // //console.log('huanyuan')
          this.px_answer = px_old_ans_arr.join("");
          //this.moxie_answer = 2;
          //this.yyts = 1;
          return false;
        } else {
          //this.moxie_answer = 0;
          //this.yyts = 0;
        }
      } else {
        //console.log("del");
        // 删除字母
      }
      this.px_old_ans_arr = inp_arr;
    },
    trim(str) {
      return str.replace(/(^\s*)|(\s*$)/g, "");
    },
    addErrorWords(num = 2) {
      //添加错误单词
      //console.log("err_words");
      let err_words = {
        id: this.now_words_index,
        word_id: this.now_words.id,
        num: num,
        error_num: 1,
      };
      let err_index = -1;
      let error_lists = this.error_lists_arr;
      for (let i = 0; i < error_lists.length; i++) {
        if (error_lists[i] && error_lists[i].word_id == this.now_words.id) {
          err_words = error_lists[i];
          err_index = i;
          break;
        }
      }
      if (err_index >= 0) {
        err_words.num = num;
        err_words.error_num = err_words.error_num + 1; //错误次数
        this.error_lists_arr[err_index] = err_words;
      } else {
        this.error_lists_arr.push(err_words);
        //this.error_lists_log.push(err_words);
      }
      ////console.log("error_lists_arr");
      ////console.log(this.error_lists_arr);
    },
    delErrorWords() {
      //消除错误单词
      //this.error_lists_arr.splice(this.now_words_index,1);
      let error_lists = this.error_lists_arr;
      let error_index = -1;
      this.err_words = 0;
      for (let i = 0; i < error_lists.length; i++) {
        if (error_lists[i].word_id == this.now_words.id) {
          this.err_words = error_lists[i];
          error_index = i;
          break;
        }
      }
      ////console.log("delErrorWords");
      ////console.log(err_words);
      if (this.err_words) {
        this.err_words.num = this.err_words.num - 1;
        if (this.err_words.num <= 0) {
          this.error_lists_arr.splice(error_index, 1);
          //console.log("delErrorWords:1");
          return 1;
        } else {
          //console.log("delErrorWords:2");
          return 0;
        }
      } else {
        //console.log("delErrorWords:3");
        return 1;
      }
    },
    hasErrorWords() {
      let err_words = "";
      let error_lists = this.error_lists_arr;
      for (let i = 0; i < error_lists.length; i++) {
        if (error_lists[i].word_id == this.now_words.id) {
          err_words = error_lists[i];
          break;
        }
      }
      if (err_words) {
        return true;
      } else {
        return false;
      }
    },
    set_yyts: function () {
      this.yyts = !this.yyts;
    },
    addData() {
      if (this.action_status == 0) return;
      this.action_status = 0;

      let danyuan_id = 0;
      if (this.studyType == 1) {
        danyuan_id = this.danyuan.id;
      }

      let data = {
        user_id:this.user.id,
        token: this.token,
        danyuan_id: danyuan_id,
        type: this.type,
        mode_type: this.mode_type,
        daan: this.daan_lists,
        study_type: this.studyType,
      };
      this.axios
        .post("/api/learn/save_study_data", data,{
					loading:true
				})
        .then((res) => {
          //console.log(res);
          if (res.data.status_code == 200) {
            //this.$message("提交成功");
            this.emitClose(this.type, 1);
            //let data = res.data.data
            //this.$store.commit('login',data)
            //this.$message('登录成功')
            //this.$router.push({name:'study_ai_word_ai_word_index'})
          } else if (res.data.status_code == 202) {
            //this.$message('用户名或者密码错误')
          } else {
            this.$message(res.data.msg);
          }
        })
        .catch((err) => {
          //this.loading = false;
          this.hideLoading();
          this.action_status = 1;
        });
    },
    getBaoshi() {
      let data = {
        token: this.token,
        course_id: this.course.id,
        danyuan_id: this.danyuan.id,
        type: this.type,
        get_baoshi_type: this.get_baoshi_type,
        scorse:this.wordNum//单词数
      };
      this.axios
        .post("/api/test_center/get_baoshi", data)
        .then((res) => {
          ////console.log(res);
          if (res.data.status_code == 200) {
            this.baoshi_num = res.data.data.integral;
          } else if (res.data.status_code == 202) {
          } else {
            this.$message(res.data.msg);
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    ceshiDo() {
      //单元测试
      this.is_goto_test = 1;
      this.addData();
    },
    emitClose(id, is_close = 0) {
      this.djs_stop = 1;
      //console.log("emitClose");
      this.setStepOld();
      this.$emit("close", id, is_close, this.is_goto_test);
    },
    closeDialog() {
      //this.xuexi_step = 6;
      this.last_page_state = 1;
    },
    jixuXuexi() {
      this.djs_stop = 0;
      //this.xuexi_step = this.xuexi_step_old;
      this.last_page_state = 0;
    },
    jixuFuxi() {
      //继续复习
      this.is_goto_test = 2;
      this.addData();
    },
    getTime() {
      return new Date().getTime();
    },
    getDiffTime(end_time) {
      if (end_time) {
        this.end_time = end_time;
      } else {
        this.end_time = new Date().getTime();
      }
      let diff_time = (this.end_time - this.start_time) / 1000;
      //console.log("diff_time：" + diff_time);
      this.use_time = diff_time;
      return diff_time;
    },
    addUseTime() {
      //添加操作时间
      //添加操作记录
      let daan_words = this.daan_lists[this.now_words_index];
      //console.log("addUseTime:" + this.use_time);
      //console.log("this.use_time:" + this.use_time);
      if (daan_words && daan_words.use_time == 0) {
        daan_words.use_time = this.use_time;
      }
    },
    addProgress() {
      let err_word = this.hasErrorWords();
      if (err_word) {
        if (err_word.num == 1) {
          this.now_lun_finish_count++; //进度条
        }
      } else {
        this.now_lun_finish_count++; //进度条
      }
    },
    huiyi_ing() {
      let that = this;
      if (
        this.allLists.length > that.huiyi_show_index &&
        this.xuei_step_arr[this.xuexi_step] == 5
      ) {
        //this.set_progress(this.huiyi_show_index+1,this.all_list.length);
        // this.study_words(that.huiyi_show_index);
        setTimeout(function () {
          that.huiyi_show_index++;
          that.huiyi_ing();
        }, 1000);
      }
    },
    /**
     * [audioPlay 播放音频-α音乐波]
     * @param  {[type]} audioLink [音频链接]
     * @param  {[type]} id        [音频地址索引]
     * @param  {[type]} audio_play[播放状态]
     * @return {[type]}           [description]
     */
    audioPlay(audioLink, id, audio_play) {
      //console.log(this.audioUrl + audioLink);
      var that = this;
      var audio = that.audio_obj[id];
      if (!audio) {
        audio = new Audio();
        audio.src = this.audioUrl + audioLink;
        audio.loop = true;
        that.audio_obj[id] = audio;
      }
      if (audio_play == 0) {
        this.audio_play = 1;
        audio.volume = 0.1;
        audio.play(); //播放
      } else {
        this.audio_play = 0;
        audio.pause(); //暂停
      }
    },
    /**
     * [audioPlay_2 播放音频-单词]
     * @param  {[type]} audioLink [description]
     * @param  {[type]} id        [description]
     * @return {[type]}           [description]
     */
    audioPlay_2(audioLink, id) {
      try {
        //console.log("播放音频");
        // //console.log(audioLink)
        var that = this;
        // if(that.audio_obj[id]){
        //     return false;
        // }
        $.get(audioLink, function (data) {
          if (data) {
            var audio = new Audio();
            // //console.log(audio)
            that.audio_obj[id] = audio;
            audio.src = audioLink;
            audio.play(); //播放
            //监听播放结束
            audio.addEventListener(
              "ended",
              function () {
                that.audio_obj[id] = null;
              },
              false
            );
          }
        });
      } catch (err) {
        //console.log("播放音频失败");
      }
    },
    audioPlay_3(audioLink, id) {
      try {
        //console.log("播放音频id" + id);
        //console.log("播放音频" + this.audioUrl + audioLink);
        var that = this;
        var audio = that.audio_obj[id];
        if (!audio) {
          audio = new Audio();
          audio.src = this.audioUrl + audioLink;
          that.audio_obj[id] = audio;
        }
        // //console.log(audio)
        //that.audio_obj[id] = audio;
        //audio.src = this.audioUrl + audioLink;
        audio.play(); //播放
        //监听播放结束
        audio.addEventListener(
          "ended",
          function () {
            that.audio_obj[id] = null;
          },
          false
        );
      } catch (err) {
        //console.log("播放音频失败");
      }
    },
    //监听键盘事件
    key_down(event){
        if (this.key_state != 1) {
          this.key_state = 1;
          this.d_code = event.keyCode;
        }
    },
    key_up(event) {
      ////console.log("code:", code);
      if (event.keyCode == this.d_code) {
        this.key_state = 0;
        this.d_code = "";
      }

      var code = event.keyCode;
      if (this.d_code == 16) {
        code = 1000 + code;
      } else if (this.d_code && this.d_code != 18) {
        //禁止其他组合键(不包括ctrl）
        return false;
      }

      let step = this.xuei_step_arr[this.xuexi_step];
      if (code == 17) {
        //console.log("audio");
        if (step == 1 || step == 2 || step == 3 || step == 4) {
          
          if(this.now_words != null){
              this.audioPlay_3(this.now_words.audio_link, this.now_words.id);
          }
        }
      }
      //console.log("step" + step);
      switch (step) {
        case 1:
          if (code == 13) {
            //回车
            if(this.is_enter == 1){
              this.is_enter = 0;
              this.duNext();
              setTimeout(() => {
                  this.is_enter = 1;
                }, 10);
            }
          }
          break;
        case 2:
          if (code == 13) {
            //回车
            if(this.is_enter == 1){
              this.is_enter = 0;
              this.nextOption();
              setTimeout(() => {
                  this.is_enter = 1;
                }, 10);
            }
          }
          break;
        case 3:
          //console.log("switch-step" + step);
          if (code == 13) {
            //回车
            this.nextOption();
          }
          if (code == 8) {
            this.del_word();
            return false;
          }
          this.code_in(code);
          break;
        case 4:
          if (code == 13) {
            //回车
            this.nextOption();
          }
          break;
        default:
          return;
      }
    },
    code_in(code) {
      if (code !== 13) {
        this.removeAnimate(".spell-info");
      }
      //console.log(this.key_code);
      var i = this.key_code[code] || "";
      if (!i) {
        return;
      }
      //console.log("i" + i);
      let zimu_list = this.now_words.zimu_list;
      //console.log(this.now_words);
      //console.log("zimu_list");
      //console.log(zimu_list);
      let k;
      for (k in zimu_list) {
        if (this.in_array(k, this.xz_words) >= 0) {
          continue;
        }
        if (i == zimu_list[k].toLowerCase()) {
          this.xz_words.push(k);
          break;
        }
      }
    },
  },
  destroyed(){
    this.now_words = null;
    window.removeEventListener("keyup", this.key_up);
    window.removeEventListener("keydown", this.key_down);
  },
};
</script>

<style lang="scss">
@import "@/assets/css/write.scss";
</style>
