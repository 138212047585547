<template>
    <div class="notice">
        <div class="notice-header">
            <span>任务</span>
        </div>
        <div class="notice-tab">
            <el-tabs v-model="activeItem">
                <el-tab-pane v-if="dataList.length !== 0" label="未完成任务" name="未完成任务">
                    <div class="notice-content">
                        <template v-for="item in dataList">
                            <div :key="item.id" class="course-detail">
                                <span class="course-name">
                                    课程名称：<span>{{ item.name }}</span>
                                </span>
                                <el-row class="tasks tasks-first">
                                    <el-col v-if="item.unitLearnTestCount !== 0" :span="2">
                                        <div>学习</div>
                                    </el-col>
                                    <el-col :span="22">
                                        <div>
                                            <template v-for="(studyItem,studyIndex) in item.unitLearnTestList">
                                                <el-row :key="studyIndex" v-if="studyItem.already_list.length !== 0">
                                                    <el-col v-if="studyItem.already_list.length !== 0" :span="2">
                                                        <div>
                                                            {{studyItem.name}}
                                                        </div>
                                                    </el-col>
                                                    <el-col v-if="studyItem.already_list.length !== 0" :span="22">
                                                        <div v-for="(unitItem,unitIndex) in item.unitList" :key="unitIndex">
                                                            <div v-if="studyItem.already_list.indexOf(unitItem.id) !== -1">
                                                                {{ unitItem.name + '、' }}
                                                            </div>
                                                        </div>
                                                    </el-col>
                                                </el-row>
                                            </template>
                                        </div>
                                    </el-col>
                                </el-row>
                                <el-row class="tasks tasks-second">
                                    <el-col v-if="item.unitLeakFillingCount !== 0" :span="2">
                                        <div>查漏补缺</div>
                                    </el-col>
                                    <el-col :span="22">
                                        <div>
                                            <template v-for="(studyItem,studyIndex) in item.unitLeakFillingList">
                                                <el-row :key="studyIndex" v-if="studyItem.already_list.length !== 0">
                                                    <el-col v-if="studyItem.already_list.length !== 0" :span="2">
                                                        <div>
                                                            {{studyItem.name}}
                                                        </div>
                                                    </el-col>
                                                    <el-col v-if="studyItem.already_list.length !== 0" :span="22">
                                                        <div v-for="(unitItem,unitIndex) in item.unitList" :key="unitIndex">
                                                            <div v-if="studyItem.already_list.indexOf(unitItem.id) !== -1">
                                                                {{ unitItem.name + '、' }}
                                                            </div>
                                                        </div>
                                                    </el-col>
                                                </el-row>
                                            </template>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>
                        </template>
                    </div>
                </el-tab-pane>
                <el-tab-pane v-if="data.length !== 0" label="今日任务" name="今日任务">
                    <div class="notice-content">
                        <template v-for="item in data">
                            <div :key="item.id" class="course-detail">
                                <span class="course-name">
                                    课程名称：<span>{{ item.name }}</span>
                                </span>
                                <el-row v-if="item.reMoveError > 0" class="tasks remove-err">
                                    <el-col :span="2" >
                                        <div>消错词</div>
                                    </el-col>
                                </el-row>
                                <el-row class="tasks tasks-first">
                                    <el-col v-if="item.unitLearnTestCount !== 0" :span="2">
                                        <div>学习</div>
                                    </el-col>
                                    <el-col :span="22">
                                        <div>
                                            <template v-for="(studyItem, studyIndex) in item.unitLearnTestList">
                                                <el-row :key="studyIndex" v-if="studyItem.already_list.length !== 0">
                                                    <el-col v-if="studyItem.already_list.length !== 0" :span="2">
                                                        <div>
                                                            {{studyItem.name}}
                                                        </div>
                                                    </el-col>
                                                    <el-col v-if="studyItem.already_list.length !== 0" :span="22">
                                                        <div v-for="(unitItem,unitIndex) in item.unitList" :key="unitIndex">
                                                            <div v-if="studyItem.already_list.indexOf(unitItem.id) !== -1" :class="{'past' : studyItem.finish_list.indexOf(unitItem.id) !== -1}">
                                                                {{ unitItem.name + '、' }}
                                                            </div>
                                                        </div>
                                                    </el-col>
                                                </el-row>
                                            </template>
                                        </div>
                                    </el-col>
                                </el-row>
                                <el-row class="tasks tasks-second">
                                    <el-col v-if="item.unitLeakFillingCount !== 0" :span="2">
                                        <div>查漏补缺</div>
                                    </el-col>
                                    <el-col :span="22">
                                        <div>
                                            <template v-for="(studyItem,studyIndex) in item.unitLeakFillingList">
                                                <el-row :key="studyIndex" v-if="studyItem.already_list.length !== 0">
                                                    <el-col v-if="studyItem.already_list.length !== 0" :span="2">
                                                        <div>
                                                            {{studyItem.name}}
                                                        </div>
                                                    </el-col>
                                                    <el-col v-if="studyItem.already_list.length !== 0" :span="22">
                                                        <div v-for="(unitItem,unitIndex) in item.unitList" :key="unitIndex">
                                                            <div v-if="studyItem.already_list.indexOf(unitItem.id) !== -1" :class="{'past' : studyItem.finish_list.indexOf(unitItem.id) !== -1}">
                                                                {{ unitItem.name + '、' }}
                                                            </div>
                                                        </div>
                                                    </el-col>
                                                </el-row>
                                            </template>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>
                        </template>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
        <div class="notice-footer">
            <el-button round @click="closeCard">确 定</el-button>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: 'GlobalNotice',
    props: {
        data: {
            type: Array
        },
        dataList: {
            type: Array
        }
    },
    data() {
        return {
            activeItem: '未完成任务',
            tabItem: [
                {
                    id: 1,
                    name: '未完成任务'
                },
                {
                    id: 2,
                    name: '今日任务'
                }
            ]
        }
    },
    created() {
        console.log('任务数据：', this.data, this.dataList)
        if (this.dataList.length === 0) {
            this.activeItem = '今日任务'
        } else {
            this.activeItem = '未完成任务'
        }
    },
    computed: {
        ...mapState({
            token: (state) => state.user.token,
            user: (state) => state.user.user,
        })
    },
    methods: {
        closeCard() {
            this.$emit('closeCards', false);
        }
    }
}
</script>

<style lang="scss" scoped>
.past {
    color: orange;
    font-weight: bold;
}
.notice {
    position: relative;
    height: 5.72rem;
    .notice-header {
        height: .56rem;
        line-height: .56rem;
        text-align: center;
        background: rgba(158, 196, 242, 0.2);

        span {
            color: #333333;
            font-family: "Microsoft JhengHei UI Light", serif;
            font-size: 24px;
            font-weight: bold;
        }
    }
    .notice-tab {
        ::v-deep .el-tabs__nav {
            display: flex;
            width: 100%;
        }
        ::v-deep .el-tabs__item {
            flex: 1;
            text-align: center;
        }
    }
    .notice-content {
        padding: 10px;
        height: 4rem;
        overflow-y: auto;
        .course-detail {
            margin-top: 10px;
            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 43px;
            }
        }
        .course-name {
            font-size: 16px;
            font-weight: 500;

            span {
                font-size: 14px;
            }
        }
        .tasks {
            display: flex;
            align-items: center;
            text-align: center;
            border-left: 1px solid #DCDFE6;
            border-bottom: 1px solid #DCDFE6;
            & > .el-col {
                &:first-child {
                    border-right: none;
                }
                &:nth-child(2) {
                    div {
                        .el-row {
                            display: flex;
                            border: 1px solid #DCDFE6;
                            border-top: none;
                            .el-col {
                                display: flex;
                                align-items: center;
                                padding: 8px;
                                &:first-child {
                                    justify-content: center;
                                    border-right: none;
                                }
                                &:last-child {
                                    flex-wrap: wrap;
                                    text-align: left;
                                    border-left: 1px solid #DCDFE6;

                                    div {
                                        div {
                                            min-width: 60px;
                                        }
                                    }
                                }

                                .el-checkbox {
                                    width: 80px;
                                    text-align: left;
                                }
                            }
                            &:last-child {

                            }
                        }
                    }
                }
            }
        }
        .remove-err {
            margin-top: 4px;
            padding: 8px 0;
            border-top: 1px solid #DCDFE6;
            border-right: 1px solid #DCDFE6;
            border-bottom: none;
        }
        .tasks-first {
            border-top: 1px solid #DCDFE6;
            &>.el-col {
                &:first-child {
                    border-bottom: none;
                }
                &:nth-child(2) {
                    div {
                        .el-row {
                            &:last-child {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
        }
        .tasks-second {
            & > .el-col {
                &:nth-child(2) {
                    div {
                        .el-row {
                            &:last-child {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
        }
    }
    .notice-footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: .8rem;
        line-height: .8rem;
        text-align: center;
        .el-button {
            width: 180px;
            color: #fff;
            background: linear-gradient(180deg, #99C1F1 0%, #75A9ED 100%);
        }
    }
}
</style>
