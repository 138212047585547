<template>
    <div>
        <div class="re-container">
            <div class="unit-bar">
                <div class="bar-l">
                    <i class="fa fa-book"></i>
                    <span>外研版词汇2013——</span>
                    <span>三年级</span>-<span>unit1</span>
                </div>
                <div class="bar-r">
                    <i class="audio"></i>
                    <i class="iconfont icon-tuichu2" @click="$emit('close')"></i>
                </div>
            </div>
            <div class="re-body">
                <div class="re-detail">
                    <div class="d-symbols">
                        <span class="s-word">programme</span>
                        <span class="s-syllable">
                            <span class="s-syllable-info"></span>
                        </span>
                        <i class="fa fa-volume-up"></i>
                        <span class="s-text">分音节:</span>
                        <span class="s-part">
                            <span class="p-01 p-01-color">p.ro.g.ramme</span>
                            <span class="p-02 p-02-color"></span>
                        </span>
                    </div>
                    <div class="d-content">
                        <div class="info-body-detail">
                            <span class="info-body-detail-text">夫人</span>
                            <span class="info-body-detail-text">不同的</span>
                            <span class="info-body-detail-text">女士</span>
                            <span class="info-body-detail-text">仍然</span>
                            <span class="info-body-detail-text">还</span>
                            <span class="info-body-detail-text">电视或广播（节目）</span>
                        </div>
                    </div>
                    <div class="info-body-result">
                        <i class="fa fa-arrow-circle-right"></i>
                    </div>
                </div>
            </div>
            <div class="re-body">
                <div class="re-detail">
                    <div class="d-symbols">
                        <span class="s-word">bathrobe</span>
                        <i class="iconfont icon-yinlianglabashengyin"></i>
                        <span class="s-text">分音节:</span>
                        <span class="s-part">ba.th.robe</span>
                    </div>
                    <div class="d-content">
                        <div class="d-c-detail">
                            <p class="d-c-chinese">浴袍</p>
                            <img src="@/assets/images/study/yupao.jpg" alt="">
                        </div>
                        <div class="d-example">
                            <p class="em-English">she paused only to belt a towelling robe about her waist.</p>
                            <p class="em-Chinese">她停下来就为了束上毛巾布浴袍的腰带。</p>
                            <el-button class="text-btn" type="text">下一句</el-button>
                        </div>
                        <div class="d-number">
                            <div class="d-n-learn" v-show="arrowShow">
                                <span class="l-num">2</span>
                                <i class="fa fa-circle-thin"></i>
                            </div>
                            <i class="fa fa-arrow-circle-right" v-show="!arrowShow"></i>
                            <p class="d-n-word">读两遍中文一遍英文</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="li-body re-body">
                <div class="li-body-detail re-detail">
                    <div class="li-title">
                        <span class="li-title-text">请听读音</span>
                        <i class="fa fa-volume-up"></i>
                    </div>
                    <ul class="li-body-content" >
                        <div>
                            <li class="li-body-content-info"  v-for="item in answerList" :key="item.id">
                                <label>
                                    <input type="radio" name="fruit" id="">
                                    <div class="choose">
                                        <span class="choose-item">{{ item.cItem }}</span>
                                        <span class="choose-answer">{{ item.cAnswer }}</span>
                                        <i class="fa fa-check" style="display: none"></i>
                                        <i class="fa fa-close" style=""></i> 
                                    </div>
                                </label>
                            </li>
                        </div>
                        <li class="li-body-content-info"  v-for="item in answerList" :key="item.id" style="display: none">
                            <label>
                                <input type="radio" name="fruit" id="">
                                <div class="choose">
                                    <span class="choose-item">{{ item.cItem }}</span>
                                    <span class="choose-answer">{{ item.cAnswer_text }}</span>
                                </div>
                            </label>
                        </li>
                    </ul>
                    <div class="li-listen">
                        <i class="fa fa-headphones"></i>
                    </div>
                    <div class="li-listen" style="display: none">
                        <div class="li-practice">
                            <span>练</span>
                        </div>
                        <span class="li-re">背一背</span>
                    </div>
                </div>
                <div class="read-countdown-time">
                    <i class="fa fa-sun-o time-icon"></i>
                    <span class="custom-textshadow">0</span>
                </div>
            </div>
            <div class="re-body">
                <div class="re-body-info">
                    <div class="phonetic">
                        <span class="phonetic-word">first</span>
                        <span class="phonetic-symbols">[fɜːst] </span>
                    </div>
                    <div class="liWrite">
                        <i class="fa fa-headphones"></i>
                        <div class="liText">
                            <input type="text" value="first">
                            <i class="fa fa-check"></i>
                        </div>
                        <el-button class="next">下一个</el-button>
                    </div>
                    <div class="word">
                        <span class="word-type">adj.</span>
                        <span class="word-text">第一的</span>
                    </div>
                </div>
            </div>
            <div class="re-body">
                <div class="re-body-info">
                    <div class="meaning">
                        <el-button class="mBtn">词义隐藏</el-button>
                        <span class="mWord">火；妒火</span>
                    </div>
                    <div class="liWrite">
                        <i class="fa fa-headphones"></i>
                        <div class="liText">
                            <input type="text" value="fire">
                        </div>
                        <el-button class="next">下一个</el-button>
                        <i class="fa fa-check check" style="display: none"></i>
                    </div>
                </div>
            </div>
            <div class="re-footer">
                <span class="warm-tips" style="fontSize: 12px;color: #777; padding-left: 15px;">【温馨提示：点击“CTRL”键再播放一遍，点击回车键到下一步】</span>
                <div class="time-total">
                    <span class="t-text">记忆时间</span
                    ><span class="t-time">[ <span class="t-time-text">00:03:41</span>]</span>
                    <span class="t-current">当前生词数</span
                    ><span class="t-num">[ <span class="t-num-text">0</span>]</span>
                    <span class="t-shibie">识别总次数</span
                    ><span class="t-total">[<span class="t-total-text">0</span>]</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            cShow: true,
            bShow: false,
            sShow: false,
            arrowShow: true,
            isActive: 0,
            wordList: ['e', 'p', 'x', 'm', 'o', 't', 'r', 'a', 'r', 'g', 'm'],
            answerList: [
                { id: 1, cItem: 'A.', cAnswer: 'apple', cAnswer_text: '苹果' },
                { id: 2, cItem: 'B.', cAnswer: 'banana', cAnswer_text: '香蕉' },
                { id: 3, cItem: 'C.', cAnswer: 'mango', cAnswer_text: '芒果' },
                { id: 4, cItem: 'D.', cAnswer: '以上都不对', cAnswer_text: '以上都不对' }
            ]
        }
    },
    methods: {
        change() {
            this.cShow = !this.cShow;
        },
        changes() {
            this.bShow = !this.bShow;
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/css/word_study.scss';
@import "@/assets/css/write.scss";
$textColor: #ff6a00;
$iconColor: #8dcc64;
.error {
    color: red;
}

.root-container {
    .el-dialog__body {
        height: auto;
    }
    .fa-arrow-circle-right {
        color: $textColor;
        font-size: 50px;
        cursor: pointer;

        &:hover {
            color: #ff8833;
        }
    }
    .re-body {
        display: flex;
        justify-content: center;
    }
    .re-body-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start ;
        margin-left: 20px;
        width: 650px;

        .meaning {
            margin-bottom: 40px;

            .mBtn {
                margin-right: 20px;
                padding: 10px 15px;
                width: 80px;
                height: 35px;
                color: #fff;
                background: #8dcc64;
            }
            .mWord {
                color: #584D4D;
                font-size: 16px;
                font-weight: bold;
            }
        }
    }
    .liWrite {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 8px 0;

        .fa-headphones {
            margin-right: 15px;
            color: $textColor;
            font-size: 60px;
        }
        .liText {
            position: relative;
            width: 420px;
            height: 45px;
            line-height: 45px;
            border: 1px solid #B7B7B7;
            box-shadow: 1px 1px 5px #777;

            input {
                width: 95%;
                height: 100%;
                color: #434343;
                font-size: 24px;
                font-weight: bold;
                outline: none;
            }
            .fa-check {
                position: absolute;
                right: 10px;
                bottom: 5px;
                color: $iconColor;
                font-size: 32px;
            }
        }
        
        .el-button {
            margin-left: 15px;
            width: 100px;
            height: 45px;
            color: #fff;
            background: #8dcc64;
        }
    }
    .phonetic, .word {
        margin-left: 80px;
        color: #000;
        font-size: 20px;
        font-weight: bold;
    }
    .phonetic-symbols {
        margin-left: 20px;
        color: #5E5E5E;
    }
    &-symbols {
        margin-bottom: 10px;
        text-align: left;
        .s-word,.s-part {
            font-size: 30px;
            font-weight: bold;
        }
        .s-word {
                color: #F16625;
            }

            .s-syllable {
                margin-left: 5px;
                font-size: 16px;
                font-weight: bold;

                &-info {
                    padding: 0 3px;
                }
            }

            .s-part {
                display: inline-block;
                padding-left: 9px;
                color: #111;
                text-shadow: 2px 3px 16px #ccc;
            }

            .p-01-color {
                color: #F16625;
            }

            .p-02-color {
                color: #3FC8AA;
            }

            .fa {
                padding: 0 10px;
                vertical-align: -2px;
                color: #111;
                font-size: 26px;
                font-weight: bold;
            }

            .s-text {
                color: #111;
                font-size: 16px;
                font-weight: bold;
            }
    }
    .d-content {
        position: relative;
        display: flex;
        flex-direction: column;
        padding-top: 50px;
        height: 226px;
        border: 1px solid #ccc;
        box-shadow: 0 0 5px #ccc;
        border-radius: 5px;

        .info-body-detail {
            justify-content: flex-start;
            margin: 0 0 0 20px;
        }
        .spell-forget {
            position: absolute;
            right: 8px;
            bottom: 8px;
            color: red;
            font-size: 13px;
        }
        i {
            font-size: 75px;
        }
        span {
            font-size: 24px;   
            font-weight: 600; 
        }
        &-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .info-text {
                margin-left: 44px;
            }
            img {
                margin-right: 50px;
                width: 257px;
                height: 130px;
            }
        }
        .spell-info {
            display: flex;
            margin: 20px 45px 0 45px;
            border-top: 1px solid #979797;

            .i-item {
                    margin-top: 15px;
                    margin-right: 5px;
                    font-family:'Times New Roman', Times, serif;
                    font-weight: 100;
                span {
                    display: inline-block;
                    width: 37px;
                    height: 47px;
                    color: #fff;
                    line-height: 47px;
                    border-radius: 5px;
                    background: #9e9e9e;
                }
                .successBg {
                    background: $iconColor;
                }
            }
        }
    }
    .write-body-result {
        margin-top: 30px;
        text-align: center;

        i {
            vertical-align: middle;
            color: $textColor;
            font-size: 50px;
        }
        .fa-check-circle-o {
            color: $iconColor;
        }
        .fa-arrow-circle-right {
            &:hover {
                color: #ff8833;
            }
        }
        .fa-headphones {
            padding: 0 15px;
        }
        .fa-refresh {
            padding-right: 15px;
            color: $iconColor;
            font-size: 48px;
        }
        .s-right {
            color: $iconColor;

            &:hover {
                color: $iconColor;
            }
        }
    }
}
</style>