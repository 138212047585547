<template>
    <div class="choose-container">
        <div class="harvest-cup">
            <img src="~@/assets/images/study/cup.png" alt="奖杯">
            <i class="fa fa-close" @click="closeDialog"></i>
        </div>
        <div class="harvest-text">
            <p>恭喜你学完本单元！</p>
            <p>本测试评估该单元学习效果。</p>
        </div>
        <div class="mode-btn">
            <el-button @click="choiceItem(1)">普通模式</el-button>
            <el-button @click="choiceItem(2)" v-if="ks_level==1">极速挑战</el-button>
            <el-button @click="choiceItem(3)" v-if="ks_level==2 || ks_level==3">终极挑战</el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: "choose_mode",
    props: {
        ksLevel: { type: Number, default: 1 },
    },
    data() {
        return {
            ks_level:1
        };
    },
    created(){
        //console.log("created-----------------------------------");
        //console.log("this.ksLevel",this.ksLevel);
        this.ks_level = this.ksLevel;
    },
    methods:{
        initData(ks_level=""){
            //console.log("ksLevel",this.ksLevel);
            if(ks_level!=""){
                this.ks_level = ks_level;
            }
            
        },
        closeDialog() {
            this.$emit("close",-1);
        },
        choiceItem(level){
            this.$emit("close",level);
        }
    }
    
}
</script>

<style lang="scss" scoped>
.choose-container {
    border-radius: .2rem;
    .harvest-cup {
        display: flex;
        width: 100%;
        text-align: center;
        img {
            margin: .15rem auto .1rem;
            width: 1.4rem;
            height: 1.4rem;
        }
        i {
            margin: .05rem;
            height: .2rem;
            font-size: .2rem;
            cursor: pointer;
        }
    }
    .harvest-text {
        color: #333;
        font-family: 'Arial Negreta', 'Arial',fantasy;
        font-size: .16rem;
        text-align: center;
        letter-spacing: .01rem;
    }
    .mode-btn {
        display: flex;
        justify-content: center;
        margin-top: .2rem;

        .el-button {
            padding: 0;
            width: 1.35rem;
            height: .4rem;
            color: #20bf9c;
            font-size: .16rem;
            border: .01rem solid #20bf9c;
            transition: all .3s;

            &:first-child {
                margin-right: .25rem;
            }
            &:hover {
                color: #fff;
                background-color: #20bf9c;
            }
        }
    }
}
</style>