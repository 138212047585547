<template>
  <el-container class="speed">
    <audio autoplay="autoplay" ref="audio">
      <source :src="src" />
    </audio>
    <el-header class="speed-header header-bg">
      <el-row class="speed-header-book">
        <el-col :span="16" class="speed-header-book__title">
          <span class="speed-header-book__info">
            <span>{{ course.name }}</span
            >- <span>{{ danyuan.name }}</span
            >- <span>{{ modeTypeName[modeType - 1] }}</span
            >闯关
            <template v-if="testType == 7">（极速挑战）</template>
            <template v-else>（终极挑战）</template>
          </span>
        </el-col>
        <el-col :span="4" :offset="4" class="speed-header-book__btn">
          <!--<el-button @click="actionSave">交卷</el-button>-->
            <i class="audio" ref="audioImg" @click="audioControl"></i>
          <el-button @click="closeDialog">返回</el-button>
        </el-col>
      </el-row>
    </el-header>
    <template v-if="show_daan == 0">
      <el-main class="speed-main" v-if="study_mk[now_study_mk] == 1">
        <div class="speed-main-total">
          <span class="total-num"
            >第 <span>{{ now_words_index + 1 }}</span> 题</span
          >
          /
          <span class="total-sum"
            >共 <span>{{ words_num }}</span> 题</span
          >
        </div>
        <div class="speed-main-contains" v-if="study_mk_child == 0">
          <el-row class="speed-main-contains__title">
            <el-col class="title-total" :span="6">
              <span class="title-total-english">英译中</span>
              <span
                ><span class="title-total-num">{{ now_words_index + 1 }}</span
                ><span class="title-total-sum">/{{ words_num }}</span></span
              >
            </el-col>
            <el-col class="warm-tips" :span="13" :offset="4">
              <span
                >温馨提示：四个选项分别对应快捷键1/2/3/4。按"→"进入下一题。</span
              >
              <i class="iconfont icon-naozhong"></i>
              <span class="tips-times">{{ djs_num }}秒</span>
            </el-col>
          </el-row>
          <div class="speed-main-contains__content">
            <div class="content-box">
              <span class="content-box__text">{{ now_words.name }}</span>
            </div>
            <ul class="content-list" v-if="nianji_level==0">
              <li
                class="content-list__item"
                v-for="(item, index) in now_words.shiyi_dx"
                :key="index"
              >
                <label>
                  <span class="input-box" @click.stop="checkItem(item, index)">
                    <input type="radio" :name="item.id" />
                    <span class="i-radio" v-if="item.is_xz == 1"></span>
                  </span>
                  <span :class="['item-answer', { itemCheck: item.is_xz }]"
                    ><span>{{ item.text }}</span>
                    <!--<i class="fa fa-check"></i><i class="fa fa-close"></i>-->
                  </span>
                </label>
              </li>
              <!--<li class="content-list__item">
                    <label>
                      <span class="input-box">
                        <input type="radio" name="fruit" />
                        <i class="i-radio"></i>
                      </span>
                      <span class="item-answer failBg">B. <span>香蕉</span></span>
                    </label>
                  </li>-->
            </ul>
            <!--幼儿-->
              <ul class="content-list baby-c" v-if="nianji_level==1">
                  <li
                          class="content-list__item"
                          v-for="(item, index) in now_words.shiyi_dx"
                          :key="index"
                  >
                      <label>
                          <img :class="[{'baby-checked':item.is_xz == 1}]" :src="audioUrl + item.img_link" alt="" @error="imgError">
                          <div>
                          <span class="input-box" @click.stop="checkItem(item, index)">
                            <input type="radio" :name="item.id" />
                            <span class="i-radio" v-if="item.is_xz == 1"></span>
                          </span>
                                      <span :class="['item-answer']">
                                      <span>{{ item.text }}</span>
                          </span>
                          </div>
                      </label>
                      <!--<i class="fa fa-check" v-if="item.is_true == 1 && item.is_xz == 1"></i>
                      <i class="fa fa-close" v-if="item.is_true == 0 && item.is_xz == 1"></i>-->
                  </li>
              </ul>
          </div>
        </div>

        <div class="speed-main-contains" v-if="study_mk_child == 1">
          <el-row class="speed-main-contains__title">
            <el-col class="title-total" :span="6">
              <span class="title-total-english">中译英</span>
              <span
                ><span class="title-total-num">{{ now_words_index + 1 }}</span
                ><span class="title-total-sum">/{{ words_num }}</span></span
              >
            </el-col>
            <el-col class="warm-tips" :span="13" :offset="4">
              <span
                >温馨提示：四个选项分别对应快捷键1/2/3/4。按"→"进入下一题。</span
              >
              <i class="iconfont icon-naozhong"></i>
              <span class="tips-times">{{ djs_num }}秒</span>
            </el-col>
          </el-row>
          <div class="speed-main-contains__content">
            <div class="content-box">
              <span class="content-box__chinese">{{ now_words.analysis }}</span>
                <img :src="audioUrl + now_words.img_link" alt="" @error="imgError">
            </div>
            <ul class="content-list">
              <li
                class="content-list__item"
                v-for="(item, index) in now_words.shiyi_zyy"
                :key="index"
              >
                <label>
                  <span
                    class="input-box"
                    @click.stop="checkItemZyy(item, index)"
                  >
                    <input
                      type="radio"
                      :name="item.id"
                      :disabled="is_disable"
                    />
                    <span class="i-radio" v-if="item.is_xz == 1"></span>
                  </span>
                  <span :class="['item-answer', { itemCheck: item.is_xz }]">
                    <span class="item-english">{{ item.text }}</span>
                    <!--<i class="fa fa-check"></i><i class="fa fa-close"></i>-->
                  </span>
                </label>
              </li>
            </ul>
          </div>
        </div>
      </el-main>
      <el-main class="speed-main" v-if="study_mk[now_study_mk] == 2">
        <div class="speed-main-total">
          <span class="total-num"
            >第 <span>{{ now_words_index + 1 }}</span> 题</span
          >
          /
          <span class="total-sum"
            >共 <span>{{ words_num }}</span> 题</span
          >
        </div>

        <div class="speed-main-contains" v-if="study_mk_child == 0">
          <el-row class="speed-main-contains__title">
            <el-col class="title-total" :span="6">
              <span class="title-total-english">听选英文</span>
              <span
                ><span class="title-total-num">{{ now_words_index + 1 }}</span
                ><span class="title-total-sum">/{{ words_num }}</span></span
              >
            </el-col>
            <el-col class="warm-tips" :span="13" :offset="4">
              <span
                >温馨提示：四个选项分别对应快捷键1/2/3/4。按"→"进入下一题。</span
              >
              <i class="iconfont icon-naozhong"></i>
              <span class="tips-times">{{ djs_num }}秒</span>
            </el-col>
          </el-row>
          <div class="speed-main-contains__content">
            <div class="content-box">
              <span class="content-box__chinese">
                <i
                  class="fa fa-volume-up"
                  @click="audioPlay_3(now_words.audio_link, now_words.id)"
                ></i>
              </span>
            </div>
            <ul class="content-list">
              <li
                class="content-list__item"
                v-for="(item, index) in now_words.shiyi_zyy"
                :key="index"
              >
                <label>
                  <span
                    class="input-box"
                    @click.stop="checkItemZyy02(item, index)"
                  >
                    <input
                      type="radio"
                      :name="item.id"
                      :disabled="is_disable"
                    />
                    <span class="i-radio" v-if="item.is_xz == 1"></span>
                  </span>
                  <span :class="['item-answer', { itemCheck: item.is_xz }]">
                    <span class="item-english">{{ item.text }}</span>
                    <!--<i class="fa fa-check"></i><i class="fa fa-close"></i>-->
                  </span>
                </label>
              </li>
            </ul>
          </div>
        </div>
        <div class="speed-main-contains" v-if="study_mk_child == 1">
          <el-row class="speed-main-contains__title">
            <el-col class="title-total" :span="6">
              <span class="title-total-english">听选中文</span>
              <span
                ><span class="title-total-num">{{ now_words_index + 1 }}</span
                ><span class="title-total-sum">/{{ words_num }}</span></span
              >
            </el-col>
            <el-col class="warm-tips" :span="13" :offset="4">
              <span
                >温馨提示：四个选项分别对应快捷键1/2/3/4。按"→"进入下一题。</span
              >
              <i class="iconfont icon-naozhong"></i>
              <span class="tips-times">{{ djs_num }}秒</span>
            </el-col>
          </el-row>
          <div class="speed-main-contains__content">
            <div class="content-box">
              <span class="content-box__text">
                <i
                  class="fa fa-volume-up"
                  @click="audioPlay_3(now_words.audio_link, now_words.id)"
                ></i>
              </span>
            </div>
            <ul class="content-list" v-if="nianji_level==0">
              <li
                class="content-list__item"
                v-for="(item, index) in now_words.shiyi_dx"
                :key="index"
              >
                <label>
                  <span
                    class="input-box"
                    @click.stop="checkItem02(item, index)"
                  >
                    <input type="radio" :name="item.id" />
                    <span class="i-radio" v-if="item.is_xz == 1"></span>
                  </span>
                  <span :class="['item-answer', { itemCheck: item.is_xz }]"
                    ><span>{{ item.text }}</span></span
                  >
                </label>
              </li>
            </ul>
              <ul class="content-list baby-c" v-if="nianji_level==1">
                  <li
                          class="content-list__item"
                          v-for="(item, index) in now_words.shiyi_dx"
                          :key="index"
                  >
                      <label>
                          <img :class="[{'baby-checked':item.is_xz == 1}]" :src="audioUrl + item.img_link" alt="" @error="imgError">
                          <div>
                  <span
                          class="input-box"
                          @click.stop="checkItem02(item, index)"
                  >
                    <input type="radio" :name="item.id" />
                    <span class="i-radio" v-if="item.is_xz == 1"></span>
                  </span>
                          <span :class="['item-answer']"
                          ><span>{{ item.text }}</span></span
                          >
                          </div>
                      </label>
                      <!--<i class="fa fa-check" v-if="item.is_true == 1 && item.is_xz == 1"></i>
                      <i class="fa fa-close" v-if="item.is_true == 0 && item.is_xz == 1"></i>-->
                  </li>
              </ul>
          </div>
        </div>
      </el-main>

      <el-main class="speed-main" v-if="study_mk[now_study_mk] == 3">
        <div class="speed-main-total">
          <span class="total-num"
            >第 <span>{{ now_words_index + 1 }}</span> 题</span
          >
          /
          <span class="totla-sum"
            >共 <span>{{ words_num }}</span> 题</span
          >
        </div>
        <div class="speed-main-contains">
          <el-row class="speed-main-contains__title">
            <el-col class="title-total" :span="3">
              <span class="title-total-english">听写</span>
              <span
                ><span class="title-total-num">{{ now_words_index + 1 }}</span
                ><span class="title-total-sum">/{{ words_num }}</span></span
              >
            </el-col>
            <el-col class="warm-tips" :span="13" :offset="7">
              <span
                >温馨提示：点击“CTRL”再次播放，点击“回车键”进入到下一个单词。</span
              >
              <i class="iconfont icon-naozhong"></i>
              <span class="tips-times">{{ djs_num }}秒</span>
            </el-col>
          </el-row>
          <div class="speed-main-contains__box">
            <div class="box-meaning">
              <el-button class="mBtn" @click="set_yyts()">
                <template v-if="yyts">词义隐藏</template>
                <template v-else>词义提示</template>
              </el-button>
              <span class="mWord" v-if="yyts">{{ now_words.analysis }}</span>
              <img :src="audioUrl + now_words.img_link" alt="" @error="imgError" v-if="yyts">
            </div>
            <div class="box-write">
              <i
                class="fa fa-headphones"
                @click="audioPlay_3(now_words.audio_link, now_words.id)"
              ></i>
              <div class="box-text">
                <input
                  type="text"
                  autocomplete="off"
                  class="spell-txt"
                  ref="pinInput"
                  v-model="px_answer"
                  v-on:input="pin_input(px_answer)"
                />
              </div>
              <el-button class="next" @click="spellNext()" v-if="btn_show === 1"
                >下一个</el-button
              >
              <!--<i
                  class="fa fa-check"
                  v-if="btn_show == 0 && moxie_answer == 1"
                ></i>
                <i
                  class="iconfont icon-V1"
                  v-if="btn_show == 0 && moxie_answer == 2"
                ></i>-->
            </div>
          </div>
        </div>
      </el-main>
      <el-main class="speed-main" v-if="study_mk[now_study_mk] == 4">
        <div class="speed-main-total">
          <span class="total-num"
            >第 <span>{{ now_words_index + 1 }}</span> 题</span
          >
          /
          <span class="totla-sum"
            >共 <span>{{ words_num }}</span> 题</span
          >
        </div>
        <div class="speed-main-contains">
          <el-row class="speed-main-contains__title">
            <el-col class="title-total" :span="3">
              <span class="title-total-english">默写</span>
              <span
                ><span class="title-total-num">{{ now_words_index + 1 }}</span
                ><span class="title-total-sum">/{{ words_num }}</span></span
              >
            </el-col>
            <el-col class="warm-tips" :span="13" :offset="7">
              <span
                >温馨提示：点击“CTRL”再次播放，点击“回车键”进入到下一个单词。</span
              >
              <i class="iconfont icon-naozhong"></i>
              <span class="tips-times">{{ djs_num }}秒</span>
            </el-col>
          </el-row>
          <div class="speed-main-contains__box">
            <div class="box-meaning">
              <el-button class="mBtn" @click="set_yyts()">
                <template v-if="yyts">词义隐藏</template>
                <template v-else>词义提示</template>
              </el-button>
              <span class="mWord" v-if="yyts">{{ now_words.analysis }}</span>
              <img :src="audioUrl + now_words.img_link" alt="" @error="imgError" v-if="yyts">
            </div>
            <div class="box-write">
              <i
                class="fa fa-headphones"
                @click="audioPlay_3(now_words.audio_link, now_words.id)"
              ></i>
              <div class="box-text">
                <input
                  type="text"
                  autocomplete="off"
                  class="spell-txt"
                  ref="pinInput"
                  v-model="px_answer"
                  v-on:input="pin_input(px_answer)"
                />
              </div>
              <el-button
                class="next"
                @click="spellNext()"
                v-cloak
                v-show="btn_show === 1"
                >下一个</el-button
              >
              <!--<i
                  class="fa fa-check"
                  v-if="btn_show == 0 && moxie_answer == 1"
                ></i>
                <i
                  class="iconfont icon-V1"
                  v-if="btn_show == 0 && moxie_answer == 2"
                ></i>-->
            </div>
          </div>
        </div>
      </el-main>
      <el-main class="speed-result" v-if="study_mk[now_study_mk] == 5">
        <div class="result">
          <span class="result-title">测试结果</span>
          <div class="result-content">
            <div class="result-content__right">
              <div class="right-title">
                <img src="~@/assets/images/study/page.png" alt="" />
                <span>共{{ timuNum }}题</span>
              </div>
              <div class="right-content">
                <span>{{ modeTypeName2[modeType - 1] }}</span>
              </div>
              <div class="right-footer">
                <img src="~@/assets/images/study/page.png" alt="" />
                <span>
                  <span class="f-check">正确{{ rightCount }}题,</span>
                  <span class="f-error">错误{{ errorCount }}题</span>
                </span>
              </div>
            </div>
            <div class="result-content__left">
              <div class="left-score">
                <div class="score-info">
                  <span>测试分数</span>
                  <span>{{ scorse }}</span>
                  <span>分</span>
                </div>
                <div class="gem-info">
                  <span>学习奖励</span>
                  <span>{{ baoshi_num }}</span>
                  <img src="~@/assets/images/study/gold-logo.png" alt="宝石" />
                </div>
              </div>
              <div class="left-btn">
                <template v-if="scorse < 100">
                  <span @click="showError">查看错误</span>
                  <span @click="resetTest()">重新测试</span>
                </template>
                <template v-else>
                  <span v-if="testType == 7" @click="zhongjiTest">终极挑战</span>
                  <span @click="closeDialog()">保存退出</span>
                </template>
              </div>
            </div>
          </div>
          <img
            v-if="scorse >= 100"
            class="result-img1"
            src="~@/assets/images/study/pass.gif"
            alt="恭喜您通关！"
          />
          <img
            v-else
            class="result-img2"
            src="~@/assets/images/study/fail.gif"
            alt="未通关，继续加油哦！"
          />
        </div>
      </el-main>
    </template>

    <template v-if="show_daan == 1">
      <!--查看错误-->
      <el-main class="speed-main" v-if="study_mk[now_study_mk] == 1">
        <div class="speed-main-total">
          <span class="total-num"
            >第 <span>{{ error_index + 1 }}</span> 题</span
          >
          /
          <span class="total-sum"
            >共 <span>{{ error_lists.length }}</span> 题</span
          >
        </div>
        <div
          class="speed-main-contains"
          v-if="now_words_error.timu_type == 'yyz'"
        >
          <el-row class="speed-main-contains__title">
            <el-col class="title-total" :span="6">
              <span class="title-total-english">英译中</span>
              <span
                ><span class="title-total-num">{{ error_index + 1 }}</span
                ><span class="title-total-sum"
                  >/{{ error_lists.length }}</span
                ></span
              >
            </el-col>
            <!--<el-col class="warm-tips" :span="13" :offset="4">
                <span
                  >温馨提示：四个选项分别对应快捷键1/2/3/4。按"→"进入下一题。</span
                >
                <i class="iconfont icon-naozhong"></i>
                <span class="tips-times">{{ djs_num }}秒</span>
              </el-col>-->
          </el-row>
          <div class="speed-main-contains__content">
            <div class="content-box">
              <span class="content-box__text">{{ now_words_error.name }}</span>
            </div>
            <ul class="content-list" v-if="nianji_level==0">
              <li
                class="content-list__item"
                v-for="(item, index) in now_words_error.shiyi_dx"
                :key="index"
              >
                <label>
                  <span class="input-box">
                    <input
                      type="radio"
                      :name="item.id"
                      disabled
                      :checked="item.is_xz"
                    />
                    <span class="i-radio" v-if="item.is_xz"></span>
                  </span>
                  <span
                    :class="[
                      'item-answer',
                      { itemCheck: item.is_true },
                      { failBg: item.is_true == 0 && item.is_xz },
                    ]"
                    ><span>{{ item.text }}</span>
                    <i class="fa fa-check" v-if="item.is_true == 1"></i>
                    <i
                      class="fa fa-close"
                      v-if="now_words_error.is_right == 0 && item.is_xz"
                    ></i>
                  </span>
                </label>
              </li>
              <!--<li class="content-list__item">
                    <label>
                      <span class="input-box">
                        <input type="radio" name="fruit" />
                        <i class="i-radio"></i>
                      </span>
                      <span class="item-answer failBg">B. <span>香蕉</span></span>
                    </label>
                  </li>-->
            </ul>

            <!--幼儿-->
              <ul class="content-list baby-c" v-if="nianji_level==1">
                  <li
                          class="content-list__item"
                          v-for="(item, index) in now_words_error.shiyi_dx"
                          :key="index"
                  >
                      <label>
                          <img :class="[{'baby-checked':item.is_xz == 1}]" :src="audioUrl + item.img_link" alt="" @error="imgError">
                          <div>
                          <span class="input-box">
                            <input type="radio" :name="item.id" />
                            <span class="i-radio" v-if="item.is_xz == 1"></span>
                          </span>
                                      <span :class="['item-answer']">
                                      <span>{{ item.text }}</span>
                          </span>
                          </div>
                      </label>
                      <i class="fa fa-check" v-if="item.is_true == 1"></i>
                      <i class="fa fa-close" v-if="item.is_true == 0 && item.is_xz == 1"></i>
                  </li>
              </ul>

            <div class="check-btn">
              <el-button type="success" @click="lastError">上一题</el-button
              ><el-button type="success" @click="nextError">下一题</el-button>
            </div>
          </div>
        </div>

        <div
          class="speed-main-contains"
          v-if="now_words_error.timu_type == 'zyy'"
        >
          <el-row class="speed-main-contains__title">
            <el-col class="title-total" :span="6">
              <span class="title-total-english">中译英</span>
              <span
                ><span class="title-total-num">{{ error_index + 1 }}</span
                ><span class="title-total-sum"
                  >/{{ error_lists.length }}</span
                ></span
              >
            </el-col>
          </el-row>
          <div class="speed-main-contains__content">
            <div class="content-box">
              <span class="content-box__chinese">{{
                now_words_error.analysis
              }}</span>
            </div>
            <ul class="content-list">
              <li
                class="content-list__item"
                v-for="(item, index) in now_words_error.shiyi_zyy"
                :key="index"
              >
                <label>
                  <span
                    class="input-box"
                    @click.stop="checkItemZyy(item, index)"
                  >
                    <input
                      type="radio"
                      :name="item.id"
                      disabled
                      :checked="item.is_xz"
                    />
                    <span class="i-radio" v-if="item.is_xz"></span>
                  </span>
                  <span
                    :class="[
                      'item-answer',
                      { itemCheck: item.is_true },
                      { failBg: item.is_true == 0 && item.is_xz },
                    ]"
                    ><span>{{ item.text }}</span>
                    <i class="fa fa-check" v-if="item.is_true == 1"></i>
                    <i
                      class="fa fa-close"
                      v-if="now_words_error.is_right == 0 && item.is_xz"
                    ></i>
                  </span>
                </label>
              </li>
            </ul>
            <div class="check-btn">
              <el-button type="success" @click="lastError">上一题</el-button>
              <el-button
                type="success"
                @click="nextError"
                v-if="error_index < error_lists.length-1"
                >下一题</el-button
              >
              <el-button type="success" @click="showDaan" v-else
                >查看结果</el-button
              >
            </div>
          </div>
        </div>
      </el-main>
      <el-main class="speed-main" v-if="study_mk[now_study_mk] == 2">
        <div class="speed-main-total">
          <span class="total-num"
            >第 <span>{{ error_index + 1 }}</span> 题</span
          >
          /
          <span class="total-sum"
            >共 <span>{{ error_lists.length }}</span> 题</span
          >
        </div>
        <div
          class="speed-main-contains"
          v-if="now_words_error.timu_type == 'zyy'"
        >
          <el-row class="speed-main-contains__title">
            <el-col class="title-total" :span="6">
              <span class="title-total-english">听选英文</span>
              <span
                ><span class="title-total-num">{{ error_index + 1 }}</span
                ><span class="title-total-sum"
                  >/{{ error_lists.length }}</span
                ></span
              >
            </el-col>
          </el-row>
          <div class="speed-main-contains__content">
            <div class="content-box">
              <span class="content-box__chinese">
                <i
                  class="fa fa-volume-up"
                  @click="audioPlay_3(now_words_error.audio_link, now_words_error.id)"
                ></i>
              </span>
            </div>
            <ul class="content-list">
              <li
                class="content-list__item"
                v-for="(item, index) in now_words_error.shiyi_zyy"
                :key="index"
              >
                <label>
                  <span class="input-box">
                    <input
                      type="radio"
                      :name="item.id"
                      disabled
                      :checked="item.is_xz"
                    />
                    <span class="i-radio" v-if="item.is_xz == 1"></span>
                  </span>
                  <span
                    :class="[
                      'item-answer',
                      { itemCheck: item.is_true },
                      { failBg: item.is_true == 0 && item.is_xz },
                    ]"
                    ><span>{{ item.text }}</span>
                    <i class="fa fa-check" v-if="item.is_true == 1"></i>
                    <i
                      class="fa fa-close"
                      v-if="now_words_error.is_right == 0 && item.is_xz"
                    ></i>
                  </span>
                </label>
              </li>
            </ul>            
            <div class="check-btn">
              <el-button type="success" @click="lastError">上一题</el-button>
              <el-button
                type="success"
                @click="nextError"
                v-if="error_index < error_lists.length - 1"
                >下一题</el-button
              >
              <el-button type="success" @click="showDaan" v-else
                >查看结果</el-button
              >
            </div>
          </div>
        </div>
        <div
          class="speed-main-contains"
          v-if="now_words_error.timu_type == 'yyz'"
        >
          <el-row class="speed-main-contains__title">
            <el-col class="title-total" :span="6">
              <span class="title-total-english">听选中文</span>
              <span
                ><span class="title-total-num">{{ error_index + 1 }}</span
                ><span class="title-total-sum"
                  >/{{ error_lists.length }}</span
                ></span
              >
            </el-col>
          </el-row>
          <div class="speed-main-contains__content">
            <div class="content-box">
              <span class="content-box__text">
                <i
                  class="fa fa-volume-up"
                  @click="
                    audioPlay_3(now_words_error.audio_link, now_words_error.id)
                  "
                ></i>
              </span>
            </div>
            <ul class="content-list" v-if="nianji_level==0">
              <li
                class="content-list__item"
                v-for="(item, index) in now_words_error.shiyi_dx"
                :key="index"
              >
                <label>
                  <span class="input-box">
                    <input
                      type="radio"
                      :name="item.id"
                      disabled
                      :checked="item.is_xz"
                    />
                    <span class="i-radio" v-if="item.is_xz == 1"></span>
                  </span>
                  <span
                    :class="[
                      'item-answer',
                      { itemCheck: item.is_true },
                      { failBg: item.is_true == 0 && item.is_xz },
                    ]"
                    ><span>{{ item.text }}</span>
                    <i class="fa fa-check" v-if="item.is_true == 1"></i>
                    <i
                      class="fa fa-close"
                      v-if="now_words_error.is_right == 0 && item.is_xz"
                    ></i>
                  </span>
                </label>
              </li>
            </ul>
            <!--幼儿-->
            <ul class="content-list baby-c" v-if="nianji_level==1">
                  <li
                          class="content-list__item"
                          v-for="(item, index) in now_words_error.shiyi_dx"
                          :key="index"
                  >
                      <label>
                          <img :class="[{'baby-checked':item.is_xz == 1}]" :src="audioUrl + item.img_link" alt="" @error="imgError">
                          <div>
                  <span
                          class="input-box"
                  >
                    <input type="radio" :name="item.id" />
                    <span class="i-radio" v-if="item.is_xz == 1"></span>
                  </span>
                          <span :class="['item-answer']"
                          ><span>{{ item.text }}</span></span
                          >
                          </div>
                      </label>
                      <i class="fa fa-check" v-if="item.is_true == 1"></i>
                      <i class="fa fa-close" v-if="item.is_true == 0 && item.is_xz == 1"></i>
                  </li>
              </ul>

            <div class="check-btn">
              <el-button type="success" @click="lastError">上一题</el-button>
              <el-button
                type="success"
                @click="nextError"
                v-if="error_index < error_lists.length - 1"
                >下一题</el-button
              >
              <el-button type="success" @click="showDaan" v-else
                >查看结果</el-button
              >
            </div>
          </div>
        </div>
      </el-main>

      <el-main class="speed-main" v-if="study_mk[now_study_mk] == 3">
        <div class="speed-main-total">
          <span class="total-num"
            >第 <span>{{ error_index + 1 }}</span> 题</span
          >
          /
          <span class="totla-sum"
            >共 <span>{{ error_lists.length }}</span> 题</span
          >
        </div>
        <div class="speed-main-contains">
          <el-row class="speed-main-contains__title">
            <el-col class="title-total" :span="3">
              <span class="title-total-english">听写</span>
              <span
                ><span class="title-total-num">{{ error_index + 1 }}</span
                ><span class="title-total-sum"
                  >/{{ error_lists.length }}</span
                ></span
              >
            </el-col>
          </el-row>
          <div class="speed-main-contains__box">
            <div class="box-meaning">
              <el-button class="mBtn" @click="set_yyts()">
                <template v-if="yyts">词义隐藏</template>
                <template v-else>词义提示</template>
              </el-button>
              <span class="mWord" v-if="yyts">{{
                now_words_error.analysis
              }}</span>
                <img :src="audioUrl + now_words.img_link" alt="" @error="imgError" v-if="yyts">
            </div>
            <div class="box-write">
              <i
                class="fa fa-headphones"
                @click="
                  audioPlay_3(now_words_error.audio_link, now_words_error.id)
                "
              ></i>
              <div class="box-text">
                <input
                  type="text"
                  autocomplete="off"
                  class="spell-txt"
                  ref="pinInput"
                  v-model="px_answer"
                />
              </div>
              <i class="fa fa-check" v-if="now_words_error.is_right == 1"></i>
              <i
                class="iconfont icon-V1"
                v-if="now_words_error.is_right == 0"
              ></i>
            </div>
            <div class="phonetic" v-if="moxie_answer == 2">
              <span class="phonetic-word">{{ now_words_error.name }}</span>
              <span class="phonetic-symbols" v-if="now_words_error.ipa"
                >[{{ now_words_error.ipa }}]
              </span>
            </div>
            <div class="check-btn">
              <el-button type="success" @click="lastError">上一题</el-button>
              <el-button
                type="success"
                @click="nextError"
                v-if="error_index < error_lists.length - 1"
                >下一题</el-button
              >
              <el-button type="success" @click="showDaan" v-else
                >查看结果</el-button
              >
            </div>
          </div>
        </div>
      </el-main>
      <el-main class="speed-main" v-if="study_mk[now_study_mk] == 4">
        <div class="speed-main-total">
          <span class="total-num"
            >第 <span>{{ error_index + 1 }}</span> 题</span
          >
          /
          <span class="totla-sum"
            >共 <span>{{ error_lists.length }}</span> 题</span
          >
        </div>
        <div class="speed-main-contains">
          <el-row class="speed-main-contains__title">
            <el-col class="title-total" :span="3">
              <span class="title-total-english">默写</span>
              <span
                ><span class="title-total-num">{{ error_index + 1 }}</span
                ><span class="title-total-sum"
                  >/{{ error_lists.length }}</span
                ></span
              >
            </el-col>
          </el-row>
          <div class="speed-main-contains__box">
            <div class="box-meaning">
              <el-button class="mBtn" @click="set_yyts()">
                <template v-if="yyts">词义隐藏</template>
                <template v-else>词义提示</template>
              </el-button>
              <span class="mWord" v-if="yyts">{{
                now_words_error.analysis
              }}</span>
                <img :src="audioUrl + now_words_error.img_link" alt="" @error="imgError" v-if="yyts">
            </div>
            <div class="box-write">
              <i
                class="fa fa-headphones"
                @click="
                  audioPlay_3(now_words_error.audio_link, now_words_error.id)
                "
              ></i>
              <div class="box-text">
                <input
                  type="text"
                  autocomplete="off"
                  class="spell-txt"
                  ref="pinInput"
                  v-model="px_answer"
                  v-on:input="pin_input(px_answer)"
                />
              </div>
              <i class="fa fa-check" v-if="now_words_error.is_right == 1"></i>
              <i
                class="iconfont icon-V1"
                v-if="now_words_error.is_right == 0"
              ></i>
            </div>
            <div class="phonetic" v-if="moxie_answer == 2">
              <span class="phonetic-word">{{ now_words_error.name }}</span>
              <span class="phonetic-symbols" v-if="now_words_error.ipa"
                >[{{ now_words_error.ipa }}]
              </span>
            </div>
            <div class="check-btn">
              <el-button type="success" @click="lastError">上一题</el-button>
              <el-button
                type="success"
                @click="nextError"
                v-if="error_index < error_lists.length - 1"
                >下一题</el-button
              >
              <el-button type="success" @click="showDaan" v-else
                >查看结果</el-button
              >
            </div>
          </div>
        </div>
      </el-main>
      <el-main class="speed-result" v-if="study_mk[now_study_mk] == 5">
        <div class="result">
          <span class="result-title">测试结果</span>
          <div class="result-content">
            <div class="result-content__right">
              <div class="right-title">
                <img src="~@/assets/images/study/page.png" alt="" />
                <span>共{{ timuNum }}题</span>
              </div>
              <div class="right-content">
                <span>{{ modeTypeName2[modeType - 1] }}</span>
              </div>
              <div class="right-footer">
                <img src="~@/assets/images/study/page.png" alt="" />
                <span>
                  <span class="f-check">正确{{ rightCount }}题,</span>
                  <span class="f-error">错误{{ errorCount }}题</span>
                </span>
              </div>
            </div>
            <div class="result-content__left">
              <div class="left-score">
                <div class="score-info">
                  <span>测试分数</span>
                  <span>{{ scorse }}</span>
                  <span>分</span>
                </div>
                <div class="gem-info">
                  <span>学习奖励</span>
                  <span>{{ baoshi_num }}</span>
                  <img src="~@/assets/images/study/gold-logo.png" alt="宝石" />
                </div>
              </div>
              <div class="left-btn">
                <template v-if="scorse < 100">
                  <span @click="showDaan">查看错误</span>
                  <span @click="resetTest()">重新测试</span>
                </template>
                <template v-else>
                  <span v-if="testType == 7">终极挑战</span>
                  <span @click="closeDialog()">保存退出</span>
                </template>
              </div>
            </div>
          </div>
          <img
            v-if="scorse >= 100"
            class="result-img1"
            src="~@/assets/images/study/pass.gif"
            alt="恭喜您通关！"
          />
          <img
            v-else
            class="result-img2"
            src="~@/assets/images/study/fail.gif"
            alt="未通关，继续加油哦！"
          />
        </div>
      </el-main>
    </template>
  </el-container>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import $ from "jquery";

import {imgError,nianjiLevel} from "@/utils/utils";

let key_code = {
  13: "回车",
  17: "ctrl",
  32: " ",
  65: "a",
  66: "b",
  67: "c",
  68: "d",
  69: "e",
  70: "f",
  71: "g",
  72: "h",
  73: "i",
  74: "j",
  75: "k",
  76: "l",
  77: "m",
  78: "n",
  79: "o",
  80: "p",
  81: "q",
  82: "r",
  83: "s",
  84: "t",
  85: "u",
  86: "v",
  87: "w",
  88: "x",
  89: "y",
  90: "z",
  190: ".",
  222: "'",
  1048: ")",
  1049: "!",
  1057: "(",
  1191: "?",
};

export default {
  name: "speed_challenge",
  props: {
    testData: { type: Object },
    course: { type: Object },
    danyuan: { type: Object },
    testTypeName: { type: String, default: "" },
    testType: { type: Number, default: 7 }, //7极速挑战，8终极挑战
    modeType: { type: Number, default: 1 }, //1记忆，2词听，3听写，4默写
    audioUrl: { type: String, default: "http://manage.xmy.xyz" },
  },
  data() {
    return {
        playState: 0,
        leter_serial: ["A", "B", "C", "D", "D"],
        study_mk: [1, 2, 3, 4, 5, 6], //1-
        modeTypeName: ["智能翻译", "智能听选", "智能听写", "智能默写"],
        modeTypeName2: ["翻译", "听选", "听写", "默写"],
        now_study_mk: 0,
        study_mk_child: 0,
        now_words: null, //当前单词
        now_words_index: 0, //当前单词索引（所有单词）
        words_num: 0,
        zyy_lists_daan: [],
        yyz_lists_daan: [],
        error_lists: [],
        error_index: 0,
        now_words_error: {},
        is_disable: false,
        action_status: 1,
        kaoshi_status: 0,
        djs_obj: 1, //倒计时对象
        djs_count: 5, //答错后倒计时5秒
        djs_num: 3,
        djs_state: 1, //倒计时状态
        djs_stop: 0,
        time_out_obj: "",
        is_xuewan: 0, //是否学完
        audio_obj: [],
        yyts: false, //听写词义隐藏/显示
        moxie_answer: 0, //拼写结果 0:未选 1：对 2-错
        moxie_next_btn: 0, //默写环节，下一个按钮，点击次数，第一次确认答案，第二次到下一个
        btn_show: 1, //1显示，不显示
        px_answer: "",
        qzfx_level: 0, //是否要强制拼写
        px_old_ans_arr: [],
        yongshi: 0,
        yongshi_time: "",
        djs_obj_zys:"",//总用时对象
        src: require("../../assets/audio/next.mp3"),
        show_daan: 0, //是否查看答案
        chooseAudios: {
          src: require("../../assets/audio/check.wav"),
          errSrc: require("../../assets/audio/error.mp3"),
        },
          audioImg: {
          play: require("../../assets/images/study/exitmusic03.png"),
          paused: require("../../assets/images/study/exitmusic01.png"),
        },
        resultAudios: {
          jxnl: require("../../assets/audio/jxnl.mp3"),
          nzb: require("../../assets/audio/nzb.mp3"),
        },
        baoshi_num: 0,
        key_state: 0, //快捷键未按下
        d_code: "",
        imgError:"",
        nianji_level:0,//年纪等级，0默认，1少儿
    };
  },
  created() {
    this.imgError = imgError;
    this.nianji_level = nianjiLevel(this.user,this.course,0);

    this.initData();
    this.setDjsZys();
    ////console.log("--------------");
  },
  mounted() {
    this.changeVolume();
    let step = this.study_mk[this.now_study_mk];
    if (step == 3 || step == 4) {
      this.$refs["pinInput"].focus();
    }
    //键盘事件
    /*var key_state = 0; //未按下
    var d_code = "";
    let that = this;
    $(document).keydown(function (event) {
      if (key_state != 1) {
        key_state = 1;
        d_code = event.keyCode;
      }
    });
    $(document).keyup(function (event) {
      if (event.keyCode == d_code) {
        key_state = 0;
        d_code = "";
      }
      ////console.log("event", event.keyCode);
      that.key_event(event.keyCode, d_code);
    });*/

    // 添加 resize 绑定事件
    window.addEventListener("keyup", this.key_up, false);
    window.addEventListener("keydown", this.key_down, false);
  },
  computed: {
    ...mapState({
      token: (state) => state.user.token,
      user: (state) => state.user.user,
    }),
    rightCount() {
      let right_count = 0;
      this.yyz_lists_daan.forEach((item, index) => {
        if (item.is_right == 1) {
          right_count++;
        }
      });
      if (this.modeType == 1 || this.modeType == 2) {
        this.zyy_lists_daan.forEach((item, index) => {
          if (item.is_right == 1) {
            right_count++;
          }
        });
      }
      return right_count;
    },
    errorCount() {
      let error_count = 0;
      this.yyz_lists_daan.forEach((item, index) => {
        if (item.is_right == 0) {
          error_count++;
        }
      });
      if (this.modeType == 1 || this.modeType == 2) {
        this.zyy_lists_daan.forEach((item, index) => {
          if (item.is_right == 0) {
            error_count++;
          }
        });
      }
      return error_count;
    },
    errorLists() {
      let errors = [];
      if(this.modeType == 2){
        this.zyy_lists_daan.forEach((item, index) => {
          if (item.is_right == 0) {
            errors.push(item.id);
            this.error_lists.push(item);
          }
        });
      }

      this.yyz_lists_daan.forEach((item, index) => {
        if (item.is_right == 0) {
          errors.push(item.id);
          this.error_lists.push(item);
        }
      });

      if (this.modeType == 1) {
        this.zyy_lists_daan.forEach((item, index) => {
          if (item.is_right == 0) {
            errors.push(item.id);
            this.error_lists.push(item);
          }
        });
      }
      ////console.log("this.error_lists",this.error_lists);
      return errors;
    },
    rightLists() {
      let right = [];
      this.yyz_lists_daan.forEach((item, index) => {
        if (item.is_right == 1) {
          right.push(item.id);
        }
      });
      if (this.modeType == 1 || this.modeType == 2) {
        this.zyy_lists_daan.forEach((item, index) => {
          if (item.is_right == 1) {
            right.push(item.id);
          }
        });
      }
      return right;
    },
    scorse() {
      let scor = 0;
      if (this.timuNum != 0) {
        scor = Math.round(Math.floor((this.rightCount / this.timuNum) * 100));
      }

      ////console.log("yyz_lists_daan");
      ////console.log(this.yyz_lists_daan);
      ////console.log(this.zyy_lists_daan);
      return scor;
    },
    timuNum() {
      let timu_num = 0;
      if (this.modeType == 1 || this.modeType == 2) {
        timu_num = this.yyz_lists_daan.length + this.zyy_lists_daan.length;
      } else {
        timu_num = this.yyz_lists_daan.length;
      }
      return timu_num;
    },
  },
  methods: {
      // 背景音乐
    audioControl() {
      let audioImg = this.$refs.audioImg;
      let audio = this.$refs.audio;
      if (this.playState === 0) {
        audioImg.style.backgroundImage = "url(" + this.audioImg.paused + ")";
        audio.pause();
        this.playState = 1;
      } else {
        audioImg.style.backgroundImage = "url(" + this.audioImg.play + ")";
        audio.play();
        this.playState = 0;
      }
    },
    fadeIns(arg) {
      let dContent = document.querySelector(arg);
      if(dContent != null){
        dContent.classList.add("fadeIns");
      }
    },
    removeAnimate(arg) {
      let dContent = document.querySelector(arg);
      if(dContent != null){
        dContent.classList.remove("fadeIns");
        //console.log(dContent.classList);
      }
    },
    // 定义逻辑函数
    keyLogic(events) {
      ////console.log("keyLogic");
      ////console.log(events.keyCode);
    },
    // 播放器音量
    changeVolume() {
      let audio = document.querySelector("audio");
      audio.volume = 0.1;
    },
    initData() {
      let that = this;
      let test_data = this.testData;
      this.now_study_mk = this.modeType - 1; ////modeType：1记忆，2词听，3听写，4默写
      this.zyy_lists_daan = test_data.zyy_lists.map((item, index, arr) => {
        for (var i = 0; i < item.shiyi_zyy.length; i++) {
          item.shiyi_zyy[i].is_xz = 0;
        }
        for (var i = 0; i < item.shiyi_dx.length; i++) {
          item.shiyi_dx[i].is_xz = 0;
        }
        let temp_item = {
          id: item.id,
          analysis: item.analysis,
          name: item.name,
          audio_link: item.audio_link,
          img_link:item.img_link,
          daan: "",
          shiyi: item.shiyi,
          shiyi_dx: item.shiyi_dx,
          shiyi_zyy: item.shiyi_zyy,
          is_right: 0,
          is_show: 0,
          timu_type: "zyy",
        };
        return temp_item;
      });

      this.yyz_lists_daan = test_data.yyz_lists.map((item, index, arr) => {
        for (var i = 0; i < item.shiyi_zyy.length; i++) {
          item.shiyi_zyy[i].is_xz = 0;
        }
        for (var i = 0; i < item.shiyi_dx.length; i++) {
          item.shiyi_dx[i].is_xz = 0;
        }
        let temp_item = {
          id: item.id,
          analysis: item.analysis,
          name: item.name,
          audio_link: item.audio_link,
          img_link:item.img_link,
          daan: "",
          shiyi: item.shiyi,
          shiyi_dx: item.shiyi_dx,
          shiyi_zyy: item.shiyi_zyy,
          is_right: 0,
          is_show: 0,
          timu_type: "yyz",
        };
        return temp_item;
      });

      this.words_num = this.yyz_lists_daan.length;
      if(this.modeType == 2){
          this.now_words = this.zyy_lists_daan[this.now_words_index];
      }else{
          this.now_words = this.yyz_lists_daan[this.now_words_index];
      }
      this.resetWordOrder();

      //console.log("this.now_words",this.now_words.name);

      this.now_study_mk = this.modeType - 1;

      if (this.modeType == 3) {
        this.yyts = false;
        this.audioPlay_3(this.now_words.audio_link, this.now_words.id);
      } else if (this.modeType == 4) {
        this.yyts = true;
        //this.audioPlay_3(this.now_words.audio_link, this.now_words.id);
      } else if (this.modeType == 2) {
        this.audioPlay_3(this.now_words.audio_link, this.now_words.id);
      }
      var djs_num = this.getWordTime();
      this.setDjs(djs_num);
    },
    getWordTime(){
      var djs_num = 0;
      var words_lenght = this.now_words.name.length;
      if(this.testType == 7){
        if (this.modeType == 1) {
            this.djs_count = 5; //倒计时3秒
        }else if(this.modeType == 2){
            this.djs_count = 5; //倒计时3秒
            djs_num = this.djs_count + 1; //默认加一秒
        }else if(this.modeType == 3){
            djs_num = Math.ceil(words_lenght * 1.5) + 1; //默认加一秒
        }else if(this.modeType == 4){
            djs_num = Math.ceil(words_lenght * 1.5); //默认加一秒
        }
      }else{
        if (this.modeType == 1) {
            this.djs_count = 3; //倒计时3秒
        }else if(this.modeType == 2){
            this.djs_count = 3; //倒计时3秒
            djs_num = this.djs_count + 1; //默认加一秒
        }else if(this.modeType == 3){
            djs_num = words_lenght + 1; //默认加一秒
        }else if(this.modeType == 4){
            djs_num = words_lenght; //默认加一秒
        }
      }
      return djs_num;
    },
    checkItem(item, index) {
      ////console.log("this.now_words_index++;");
      this.chooseAudio();
      //单选
      //this.xuexi_step++;
      var shiyi_dx = this.now_words.shiyi_dx;
      for (var i = 0; i < shiyi_dx.length; i++) {
        this.now_words.shiyi_dx[i].is_xz = 0;
      }

      if (index != -1) {
        this.now_words.shiyi_dx[index].is_xz = 1;
        if (item.is_true == 1) {
          this.now_words.is_right = 1;
          ////console.log("ringht");
        } else {
          this.now_words.is_right = 0;
          ////console.log("error");
        }
        this.now_words.is_show = 1;
        //this.djs_stop = 1;
      }
      //this.djs_stop = 1;
      //let that = this;
      if (this.time_out_obj) {
        clearTimeout(this.time_out_obj);
      }
      this.time_out_obj = setTimeout(() => {
        this.now_words_index++;
        this.now_words = this.yyz_lists_daan[this.now_words_index];
        if (this.now_words_index == this.words_num) {
          this.now_words_index = 0;
          this.now_words = this.zyy_lists_daan[this.now_words_index];
          this.study_mk_child++;
        }
        let step = this.study_mk[this.now_study_mk];
        if (step == 2 || step == 3) {
          this.audioPlay_3(this.now_words.audio_link, this.now_words.id);
        }

        //重新排序
        this.resetWordOrder();
        var djs_num = this.getWordTime();
        this.setDjs(djs_num);
      }, 600);
    },
    checkItemZyy(item, index) {
      ////console.log("this.now_words_index++;");
      this.chooseAudio();
      //单选
      //this.xuexi_step++;
      var shiyi_zyy = this.now_words.shiyi_zyy;
      for (var i = 0; i < shiyi_zyy.length; i++) {
        this.now_words.shiyi_zyy[i].is_xz = 0;
      }

      if (index != -1) {
        this.now_words.shiyi_zyy[index].is_xz = 1;
        if (item.is_true == 1) {
          ////console.log("ringht");
          this.now_words.is_right = 1;
        } else {
          ////console.log("error");
        }
      }
      if (this.now_words_index == this.words_num - 1) {
        this.is_disable = true;
        this.is_xuewan = 1;
        this.djs_stop = 1;
        this.saveData();
        return;
      }
      //this.djs_stop = 1;
      if (this.time_out_obj) {
        clearTimeout(this.time_out_obj);
      }
      this.time_out_obj = setTimeout(() => {
        this.now_words_index++;
        this.now_words = this.zyy_lists_daan[this.now_words_index];
        let step = this.study_mk[this.now_study_mk];
        if (step == 2) {
          this.audioPlay_3(this.now_words.audio_link, this.now_words.id);
        }
        //重新排序
        this.resetWordOrder();
        var djs_num = this.getWordTime();
        this.setDjs(djs_num);
      }, 600);
    },
    checkItem02(item, index) {
      ////console.log("this.now_words_index++;");
      this.chooseAudio();
      //单选
      //this.xuexi_step++;
      var shiyi_dx = this.now_words.shiyi_dx;
      for (var i = 0; i < shiyi_dx.length; i++) {
        this.now_words.shiyi_dx[i].is_xz = 0;
      }

      if (index != -1) {
        this.now_words.shiyi_dx[index].is_xz = 1;
        if (item.is_true == 1) {
          this.now_words.is_right = 1;
          ////console.log("right");
        } else {
          this.now_words.is_right = 0;
          ////console.log("error");
        }
        this.now_words.is_show = 1;
        //this.djs_stop = 1;
      }
      //this.djs_stop = 1;
      //let that = this;
      if (this.time_out_obj) {
        clearTimeout(this.time_out_obj);
      }
      if (this.now_words_index == this.words_num - 1) {
        this.is_disable = true;
        this.is_xuewan = 1;
        this.djs_stop = 1;
        this.saveData();
        return;
      }

      this.time_out_obj = setTimeout(() => {
        this.now_words_index++;
        this.now_words = this.yyz_lists_daan[this.now_words_index];
        let step = this.study_mk[this.now_study_mk];
        if (step == 2) {
          this.audioPlay_3(this.now_words.audio_link, this.now_words.id);
        }
        //重新排序
        this.resetWordOrder();
        var djs_num = this.getWordTime();
        this.setDjs(djs_num);
      }, 600);
    },
    checkItemZyy02(item, index) {
      ////console.log("this.now_words_index++;");
      this.chooseAudio();
      //单选
      //this.xuexi_step++;
      var shiyi_zyy = this.now_words.shiyi_zyy;
      for (var i = 0; i < shiyi_zyy.length; i++) {
        this.now_words.shiyi_zyy[i].is_xz = 0;
      }

      if (index != -1) {
        this.now_words.shiyi_zyy[index].is_xz = 1;
        if (item.is_true == 1) {

          ////console.log("this.now_words.name",this.now_words.name);
          ////console.log("right");
          this.now_words.is_right = 1;
        } else {
          ////console.log("error");
        }
      }
      //this.djs_stop = 1;
      if (this.time_out_obj) {
        clearTimeout(this.time_out_obj);
      }
      this.time_out_obj = setTimeout(() => {
        this.now_words_index++;
        this.now_words = this.zyy_lists_daan[this.now_words_index];
        if (this.now_words_index == this.words_num) {
          this.now_words_index = 0;
          this.now_words = this.yyz_lists_daan[this.now_words_index];
          this.study_mk_child++;
        }
        //this.resetWordOrder();
        ////console.log("this_zyy_lists_daan",this.zyy_lists_daan);
        let step = this.study_mk[this.now_study_mk];
        if (step == 2) {
          this.audioPlay_3(this.now_words.audio_link, this.now_words.id);
        }
        //重新排序
        this.resetWordOrder();
        var djs_num = this.getWordTime();
        this.setDjs(djs_num);
      }, 600);
    },
    resetWordOrder() {
      ////console.log("this_now_words_old",this.now_words);
      //重新排序
      var shiyi = this.now_words.shiyi;
      var newarr = [];
      while (shiyi.length > 0) {
        var len = parseInt(Math.random() * shiyi.length);
        newarr.push(shiyi[len]);
        shiyi.splice(len, 1);
      }
      this.now_words.shiyi = newarr;

      //重新排序
      var shiyi_dx = this.now_words.shiyi_dx;
      //let item = shiyi_dx.splice(shiyi_dx.length - 1, 1);
      var newarr02 = [];
      while (shiyi_dx.length > 0) {
        var len = parseInt(Math.random() * shiyi_dx.length);
        newarr02.push(shiyi_dx[len]);
        shiyi_dx.splice(len, 1);
      }
      //newarr02.push(...item);
      this.now_words.shiyi_dx = newarr02;

      //重新排序
      var shiyi_zyy = this.now_words.shiyi_zyy;
      //let item03 = shiyi_zyy.splice(shiyi_zyy.length - 1, 1);
      ////console.log("item03",item03);
      var newarr03 = [];
      while (shiyi_zyy.length > 0) {
        var len = parseInt(Math.random() * shiyi_zyy.length);
        newarr03.push(shiyi_zyy[len]);
        shiyi_zyy.splice(len, 1);
      }
      //newarr03.push(...item03);
      this.now_words.shiyi_zyy = newarr03;
      ////console.log("this_now_words_new",this.now_words);
    },
    spellNext() {
        this.fadeIns('.box-meaning');
        this.fadeIns('.box-write i');
        this.fadeIns('.box-text');
      if (this.px_answer == "") {
        return;
      }
      this.toSpellNext();
    },
    toSpellNext() {
      if (this.px_is_t()) {
        this.now_words.is_right = 1;
        this.moxie_answer = 1;
        ////console.log("this.now_words.name");
        ////console.log(this.now_words.name);
      } else {
        this.now_words.is_right = 0;
        this.moxie_answer = 2;
      }
      //this.btn_show = 0;
      this.px_answer = "";
      if (this.now_words_index >= this.words_num - 1) {
        ////console.log("saveData");
        this.now_words_index = this.words_num - 1;
        this.is_disable = true;
        this.is_xuewan = 1;
        this.djs_stop = 1;
        this.saveData();
        return;
      }
      this.djs_stop = 1;
      if (this.study_mk[this.now_study_mk] == 3) {
        this.yyts = false;
      }

      /*if (this.time_out_obj) {
        clearTimeout(this.time_out_obj);
      }
      this.time_out_obj = setTimeout(() => {
      }, 1000);*/
      this.now_words_index++;
      this.now_words = this.yyz_lists_daan[this.now_words_index];
      ////console.log("this.yyz_lists_daan",this.yyz_lists_daan);
      this.$refs["pinInput"].focus();
      let step = this.study_mk[this.now_study_mk];
      if (step == 3) {
        this.audioPlay_3(this.now_words.audio_link, this.now_words.id);
      }
      this.btn_show = 1;
      this.moxie_answer = 0;
      //var words_lenght = this.now_words.name.length;
      var djs_num = this.getWordTime();
      ////console.log("this.now_words",this.now_words.name);
      this.setDjs(djs_num);
    },
    nextWords() {
      if (this.now_words_index == this.words_num - 1) {
        this.is_disable = true;
        this.is_xuewan = 1;
        this.djs_stop = 1;
        return 0;
      }
      return 1;
    },
    actionSave() {
      this.djs_stop = 1;
      this.$confirm("确定要交卷吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //this.djs_state = 0;
          //this.clearDjs();
          ////console.log("this.yyz_lists_daan", this.yyz_lists_daan);
          //this.saveData();
        })
        .catch(() => {
          this.djs_stop = 0;
        });
    },
    saveData() {
      ////console.log("yyz_lists_daan",this.yyz_lists_daan);
      if (this.action_status == 0) return;
      //this.getBaoshi();
      this.action_status = 0;
      ////console.log();

      let param_data = [];
      if (this.modeType == 1 || this.modeType == 2) {
        param_data = [...this.yyz_lists_daan, ...this.zyy_lists_daan];
      } else {
        param_data = this.yyz_lists_daan;
      }

      //this.scorse = Math.round((this.rightCount / this.timuNum) * 100);
      //this.showDaan(this.isShowType);

      let data = {
        user_id:this.user.id,
        token: this.token,
        scorse: this.scorse,
        test_type: this.testType,
        test_type_name: this.testTypeName,
        dadui_num: this.rightCount,
        course_id: this.course.id,
        danyuan_id: this.danyuan.id,
        danyuan_id_str: "",
        dacuo_num: this.errorCount,
        all_words_num: this.timuNum,
        yongshi: this.yongshi,
        yongshi_time: this.yongshi_time,
        type: this.modeType,
        data: param_data,
        error_lists: this.unique(this.errorLists),
        right_lists: this.unique(this.rightLists),
      };
      this.axios
        .post("/api/test_center/save_test", data,{
					loading:true
				})
        .then((res) => {
          ////console.log(res);
          if (res.data.status_code == 200) {
            this.baoshi_num = res.data.data.integral;
            this.showDaan();
            if (this.scorse >= 100) {
              this.resultAudio();
            } else {
              this.resultAudio(0);
            }
            this.kaoshi_status = 1;
            //this.$message('提交成功');
            /*this.$message({
              message: "提交成功，测试完成",
              offset: 300,
              type: "success",
              duration: "3000",
            });*/
            //this.emitClose(1, 1);
          } else if (res.data.status_code == 202) {
          } else {
            this.$message(res.data.msg);
          }
        })
        .catch((err) => {
          //this.loading = false;
          this.hideLoading();
          this.action_status = 1;
        });
      //this.$emit('changeTitle');
    },
    getBaoshi() {
      let data = {
        token: this.token,
        scorse: this.scorse,
        test_type: this.testType,
        course_id: this.course.id,
        danyuan_id: this.danyuan.id,
        type: this.modeType,
        get_baoshi_type: 2,
      };
      this.axios
        .post("/api/test_center/get_baoshi", data)
        .then((res) => {
          ////console.log(res);
          if (res.data.status_code == 200) {
            this.baoshi_num = res.data.data.integral;
          } else if (res.data.status_code == 202) {
          } else {
            this.$message(res.data.msg);
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    showDaan() {
      this.show_daan = 0;
      this.now_study_mk = 4;
    },
    showError() {
      ////console.log(this.error_lists);
      this.now_study_mk = this.modeType - 1;
      this.show_daan = 1;
      this.error_index = 0;
      this.errorLists;
      if (this.error_lists.length > 0) {
        this.now_words_error = this.error_lists[this.error_index];
        this.px_answer = this.now_words_error.daan;
      }
      ////console.log("now_words_error", this.now_words_error);
    },
    lastError() {
      //上一个
      if (this.error_index > 0) {
        this.error_index--;
        this.now_words_error = this.error_lists[this.error_index];
        this.px_answer = this.now_words_error.daan;
      }
    },
    nextError() {
      //下一个
      if (this.error_lists.length > this.error_index + 1) {
        this.error_index++;
        this.now_words_error = this.error_lists[this.error_index];
        this.px_answer = this.now_words_error.daan;
      }
    },
    /**
     * [study_djs 倒计时]
     * @param  {Number} value [description]
     * @return {[type]}       [description]
     */
    setDjs(value = 0) {
      var that = this;
      if (value > 0) {
        that.djs_num = value;
      }
      if (this.djs_obj) {
        clearInterval(this.djs_obj);
        if (value > 0) {
          that.djs_num = value;
        } else {
          that.djs_num = that.djs_count;
        }
      }
      that.djs_state = 1;
      that.djs_stop = 0;
      ////console.log("djs_stop", that.djs_stop);
      this.djs_obj = setInterval(function () {
        if (that.djs_state != 1) {
          clearInterval(that.djs_obj);
          return false;
        }
        if (that.djs_stop == 0) {
          if (that.djs_num > 0) {
            that.djs_num--;
          } else {
            //执行事件
            //that.now_words_index++;
             clearInterval(that.djs_obj);
            if (that.study_mk[that.now_study_mk] == 1) {
              if (that.study_mk_child == 0) {
                that.checkItem("", -1);
              } else {
                that.checkItemZyy("", -1);
              }
            } else if (that.study_mk[that.now_study_mk] == 2) {
              if (that.study_mk_child == 0) {
                that.checkItemZyy02("", -1);
              } else {
                that.checkItem02("", -1);
              }
            } else if (that.study_mk[that.now_study_mk] == 3) {
              that.toSpellNext();
            } else if (that.study_mk[that.now_study_mk] == 4) {
              that.toSpellNext();
            }
            if (that.is_xuewan == 0) {
              //that.setDjs();
            }
          }
          ////console.log("倒计时：" + that.djs_num);
        }
      }, 1000);
    },
    unique(arr) {
      const seen = new Map();
      return arr.filter((a) => !seen.has(a) && seen.set(a, 1));
    },
    clearDjs() {
      if (this.djs_obj) {
        clearInterval(this.djs_obj);
      }
    },
    setDjsZys() {
      let that = this;
      this.djs_obj_zys = setInterval(function () {
        that.zj_yongshi();
      }, 1000);
    },
    zj_yongshi() {
      var that = this;
      that.yongshi++;
      var hour = Math.floor(that.yongshi / 3600);
      var minute = Math.floor((that.yongshi - hour * 3600) / 60);
      var second = that.yongshi - hour * 3600 - minute * 60;
      if (hour > 0) {
        that.yongshi_time = hour + "时" + minute + "分" + second + "秒";
      } else {
        that.yongshi_time = minute + "分" + second + "秒";
      }

      ////console.log(that.yongshi_time);
    },
    // chooseAudio
    chooseAudio(type = 1) {
      let audio = new Audio();
      audio.controls = false;
      let src = this.chooseAudios.src;
      if (type === 0) {
        src = this.chooseAudios.errSrc;
      }
      audio.src = src;
      audio.volume = 0.5;
      audio.play();
      ////console.log("chooseAudio");
    },
    resultAudio(type = 1) {
      let audio = new Audio();
      audio.controls = false;
      let src = this.resultAudios.nzb;
      if (type === 0) {
        src = this.resultAudios.jxnl;
      }
      audio.src = src;
      audio.play();
    },
    /**
     * [audioPlay 播放音频-α音乐波]
     * @param  {[type]} audioLink [音频链接]
     * @param  {[type]} id        [音频地址索引]
     * @param  {[type]} audio_play[播放状态]
     * @return {[type]}           [description]
     */
    audioPlay(audioLink, id, audio_play) {
      ////console.log(this.audioUrl + audioLink);
      var that = this;
      var audio = that.audio_obj[id];
      if (!audio) {
        audio = new Audio();
        audio.src = this.audioUrl + audioLink;
        audio.loop = true;
        that.audio_obj[id] = audio;
      }
      if (audio_play == 0) {
        this.audio_play = 1;
        audio.volume = 0.1;
        audio.play(); //播放
      } else {
        this.audio_play = 0;
        audio.pause(); //暂停
      }
    },
    /**
     * [audioPlay_2 播放音频-单词]
     * @param  {[type]} audioLink [description]
     * @param  {[type]} id        [description]
     * @return {[type]}           [description]
     */
    audioPlay_2(audioLink, id) {
      try {
        ////console.log("播放音频");
        // ////console.log(audioLink)
        var that = this;
        // if(that.audio_obj[id]){
        //     return false;
        // }
        $.get(audioLink, function (data) {
          if (data) {
            var audio = new Audio();
            // ////console.log(audio)
            that.audio_obj[id] = audio;
            audio.src = audioLink;
            audio.play(); //播放
            //监听播放结束
            audio.addEventListener(
              "ended",
              function () {
                that.audio_obj[id] = null;
              },
              false
            );
          }
        });
      } catch (err) {
        ////console.log("播放音频失败");
      }
    },
    audioPlay_3(audioLink, id) {
      try {
        ////console.log("播放音频");
        var that = this;
        var audio = that.audio_obj[id];
        if (!audio) {
          audio = new Audio();
          that.audio_obj[id] = audio;
        }

        audio.src = this.audioUrl + audioLink;
        audio.play(); //播放
        //监听播放结束
        audio.addEventListener(
          "ended",
          function () {
            that.audio_obj[id] = null;
          },
          false
        );
      } catch (err) {
        ////console.log("播放音频失败");
      }
    },
    set_yyts() {
      this.yyts = !this.yyts;
    },
    pin_input(value) {
      /*var pin_study_rec = this.pin_study_rec[this.pin_words.id]||{qzfx_level:0,ture_num:0,error_num:0,lx_t_num:0,n_qzfx_level:0,};
          var qzfx_level = pin_study_rec.qzfx_level||0;
          if(qzfx_level==0){return false;}*/
        this.removeAnimate('.box-meaning');
        this.removeAnimate('.box-write i');
        this.removeAnimate('.box-text');
      this.now_words.daan = value;
    },
    /**
     * [in_array 字符串是否在数组内]
     * @param  {[type]} v   [字符串]
     * @param  {[type]} arr [数组]
     * @return {[type]}     [返回位置  -1不在数组内]
     */
    in_array(v, arr) {
      for (var i = 0; i < arr.length; i++) {
        if (v == arr[i]) {
          return i;
        }
      }
      return -1;
    },
    /**
     * [px_is_t 判断拼写是否正确]
     * @return {[type]} [true-错 false-对]
     */
    px_is_t() {
      var that = this;
      if (this.trim(this.px_answer) == this.now_words.name) {
        return true;
      }
      return false;
    },
    isNumber(obj) {
      return typeof obj === "number" && !isNaN(obj);
    },
    trim(str) {
      return str.replace(/(^\s*)|(\s*$)/g, "");
    },
    closeDialog() {
      if (this.kaoshi_status == 0) {
        this.djs_stop = 1;
        this.$confirm("您还没有交卷，确定要返回吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.kaoshi_status = 1;
            this.djs_state = 0;
            this.clearDjs();
            this.$emit("close");
          })
          .catch(() => {
            this.djs_stop = 0;
          });
      } else {
        this.djs_state = 0;
        this.clearDjs();
        this.$emit("close");
      }
    },
    resetTest() {
      this.$emit("close", 2);
    },
    zhongjiTest() {
      this.$emit("close", 3);
    }, //监听键盘事件
    key_down(event) {
      if (this.key_state != 1) {
        this.key_state = 1;
        this.d_code = event.keyCode;
      }
    },
    key_up(event) {
      ////console.log("code:", code);
      if (event.keyCode == this.d_code) {
        this.key_state = 0;
        this.d_code = "";
      }

      var code = event.keyCode;
      if (this.d_code == 16) {
        code = 1000 + code;
      } else if (this.d_code && this.d_code != 18) {
        //禁止其他组合键(不包括ctrl）
        return false;
      }

      let step = this.study_mk[this.now_study_mk];
      if (step == 1) {
        var xuanxiang = [49, 50, 51, 52]; //1、2、3、4
        let is_at = 0; //判断是否1，2，3，4
        xuanxiang.forEach((item) => {
          if (item === code) {
            is_at = 1;
          }
        });
        let i = 0;
        if (is_at) {
          switch (code) {
            case 49:
              i = 0;
              break;
            case 50:
              i = 1;
              break;
            case 51:
              i = 2;
              break;
            case 52:
              i = 3;
              break;
            default:
              break;
          }
          if (this.study_mk_child == 0 && this.show_daan == 0) {
            if (this.now_words != null) {
              let shiyi = this.now_words.shiyi_dx;
              shiyi.forEach((item, index) => {
                if (index == i) {
                  this.checkItem(item, index);
                }
              });
            }
          } else if (this.study_mk_child == 1 && this.show_daan == 0) {
            if (this.now_words != null) {
              let shiyi = this.now_words.shiyi_zyy;
              shiyi.forEach((item, index) => {
                if (index == i) {
                  this.checkItemZyy(item, index);
                }
              });
            }
          }
        }
      }else if (step == 2) {
        var xuanxiang = [49, 50, 51, 52]; //1、2、3、4
        let is_at = 0; //判断是否1，2，3，4
        xuanxiang.forEach((item) => {
          if (item === code) {
            is_at = 1;
          }
        });
        let i = 0;
        if (is_at) {
          switch (code) {
            case 49:
              i = 0;
              break;
            case 50:
              i = 1;
              break;
            case 51:
              i = 2;
              break;
            case 52:
              i = 3;
              break;
            default:
              break;
          }
         if (this.study_mk_child == 0 && this.show_daan == 0) {
            if (this.now_words != null) {
              let shiyi = this.now_words.shiyi_zyy;
              shiyi.forEach((item, index) => {
                if (index == i) {
                  this.checkItemZyy02(item, index);
                }
              });
            }
          }
          else if (this.study_mk_child == 1 && this.show_daan == 0) {
            if (this.now_words != null) {
              let shiyi = this.now_words.shiyi_dx;
              shiyi.forEach((item, index) => {
                if (index == i) {
                  this.checkItem02(item, index);
                }
              });
            }
          }
        }
      }else if (step == 3 || step == 4) {
        if (code == 13) {
          //回车
          if (this.show_daan == 0) {
            this.spellNext();
          }
        }
      }

      if (code == 17) {
        if (step == 2 || step == 3) {
          if (this.now_words != null) {
            this.audioPlay_3(this.now_words.audio_link, this.now_words.id);
          }
        }
      }
    },
  },
  destroyed() {
    //this.now_words = null;
    window.removeEventListener("keyup", this.key_up);
    window.removeEventListener("keydown", this.key_down);
    if (this.djs_obj) {
      clearInterval(this.djs_obj);
    }

    if (this.djs_obj_zys) {
       clearInterval(this.djs_obj_zys);
    }
  },
};
</script>

<style lang="scss">
@import "~@/assets/css/challenge.scss";
</style>
