<template>
    <div @click="isDisplay = false">
        <!-- <el-dialog
            :visible.sync="videoView"
            width="40%"
            class="video-dialog">
            <video width="768" height="442" muted autoplay loop>
            <source :src="videoUrl" type="video/mp4">
        </video>
        </el-dialog>-->
        <div class="st-main" @click="isDisplay = false">
            <!-- 通知公告 -->
            <div v-if="noticeData.length !== 0 || noTestData.length !== 0" class="global-notice" @click.stop.prevent="noticeVisible = true">
<!--                <img :src="noticeImg" alt="" style="width: 24px; height: 24px;">-->
                <div class="notice-content">
                    <template v-for="item in noticeData">
                        <div :key="item.id" v-if="noticeData.length !== 0" class="notice-tips">
                            <span class="course-name">课程：{{ item.name }}</span>
                            <el-divider direction="vertical" />
                            <span v-if="item.unitLearnTestCount > 0">学习：</span>
                            <template v-for="b_item in item.unitLearnTestList">
                                <div :key="b_item.id">
                                <span v-if="b_item.already_list.length !== 0">{{ b_item.name }}：</span>
                                <template v-for="c_item in item.unitList">
                                    <div v-if="b_item.already_list.indexOf(c_item.id) !== -1" :key="c_item.id">
                                        <span>{{ c_item.name }}、</span>
                                    </div>
                                </template>
                                </div>
                            </template>
                            <span v-if="item.unitLeakFillingCount > 0">查漏补缺：</span>
                            <template v-for="d_item in item.unitLeakFillingList">
                                <div :key="d_item.name">
                                    <span v-if="d_item.already_list.length !== 0">{{ d_item.name }}：</span>
                                    <template v-for="e_item in item.unitList">
                                        <div v-if="d_item.already_list.indexOf(e_item.id) !== -1" :key="e_item.id">
                                            <span>{{ e_item.name }}、</span>
                                        </div>
                                    </template>
                                </div>
                            </template>
                        </div>
                    </template>
                    <div v-show="noticeData.length === 0 && noTestData.length > 0" class="notice-tips" style="text-align: center">
                        <span class="notice-tips__txt">有未完成任务！！！</span>
                    </div>
                </div>
                <span>......</span>
            </div>
            <div class="st-container">
                <div class="main-l">
                    <div class="user-info">
                        <div class="
								user-img
								text-center
								d-flex
								justify-content-center
							">
                            <div class="Uimg_cover"></div>
                            <div class="
									Uimg
									d-flex
									justify-content-center
									align-items-center
								" @click="avatarVisible = true">
                                <img class="avatar-img" :src="user.avatar" />
                                <img class="avatar-box" :src="user.avatar_box" alt="" v-if="user.avatar_box" />
                                <!--                                <template v-for="(item, index) in boxSrc">-->
                                <!--                                    <img class="avatar-box" :key="index" :src="item.src" alt="">-->
                                <!--                                </template>-->
                            </div>
                        </div>
                        <div class="user-name">
                            <span class="Uname">{{ user.truename }}({{ eyes ? user.username : '✱✱✱✱✱✱' }})</span>
                            <i :class="['fa', eyes ? 'fa-eye' : 'fa-eye-slash']" @click="changeEyes"></i>
                        </div>
                        <div class="user-level">
                            <img :src="user.level_img" alt="我的段位" @click="getUserInfo" title="点击刷新" />
                            <span class="l-username">{{
                                    user.level_name
                            }}</span>
                            <span class="user-stars" v-if="user.start >= 6">✯x{{ user.start }}</span>
                            <span class="user-stars" v-else v-for="index of user.start" :key="index">✯</span>
                        </div>
                        <div class="user-progress">
                            <el-progress :percentage="jinDu" :stroke-width="13" :show-text="textShow"></el-progress>
                            <div class="u-info">
                                <span class="u-info-num">{{
                                        user.baoshi || 0
                                }}</span>/<span class="u-info-total" v-if="user.level">{{ user.level.end_inter
}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="ai-review">
                        <div class="re-btn" style="display: none">
                            <el-row>
                                <el-button type="primary" @click="transVisible = true">一键消灭错词
                                </el-button>
                            </el-row>
                            <el-dialog class="transition" :visible.sync="transVisible" width="4.8rem"
                                :close-on-press-escape="false">
                                <trans @close="handleClose" />
                            </el-dialog>
                            <ul class="re-list">
                                <li class="rl-item">
                                    <span class="item-dot">●</span>
                                    <span class="item-text">错词学习</span>
                                    <span class="item-total">（3）</span>
                                </li>
                                <li class="rl-item">
                                    <span class="item-dot">●</span>
                                    <span class="item-text">错词通关</span>
                                    <span class="item-total">（5）</span>
                                </li>
                            </ul>
                        </div>
                        <div class="re-btn">
                            <el-row>
                                <el-button type="primary" @click="clkFuxiNav('', -1)">一键智能复习
                                </el-button>
                            </el-row>
                        </div>
                        <ul class="re-list">
                            <li class="rl-item" v-for="(item, index) in nav_fuxi" :key="index"
                                @click="clkFuxiNav(item, index)">
                                <!--v-if="item.count>0"-->
                                <span class="item-dot">●</span>
                                <span class="item-text">{{ item.name }}</span>
                                <span class="item-total">（{{ item.count }}）</span>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="main-r" v-if="page_index == 1">
                    <div class="ai-learn-area">
                        <div class="area-container" @click="listShow = false">
                            <p class="area-header">智能学习区</p>
                            <div class="area-r">
                                <div class="area-course">
                                    <p class="course-title">
                                        <span v-for="(
												item, index
											) in courseDingyueLists" :key="index" v-if="item.id == thisCourseId" @click="checkCourse(item)">{{ item.name
											}}</span>
                                        <!--<span></span>-->
                                    </p>
                                </div>
                                <div class="course-change" @click="coVisible = true">
                                    <span class="change-btn">切换课程</span>
                                    <i class="iconfont icon-change_fill"></i>
                                </div>
                                <el-dialog :visible.sync="coVisible" :close-on-click-modal="close" :show-close="close"
                                    :width="cWidth" :close-on-press-escape="false">
                                    <coChange @close="changeClose" @changCourse="changCourse"
                                        :course-list="courseDingyueLists" :now-course-id="thisCourseId" />
                                </el-dialog>
                                <div class="area-progress" @click="caretChange" @click.stop="display">
                                    <span class="learn-remember">{{
                                            course_jilu_now.name
                                    }}</span>
                                    <span class="learn-times">（第<span class="times-num">{{
                                            course_jilu_now.xuexi_num
                                    }}</span>遍）</span>
                                    <span class="times-text">进度<span class="times-p">{{
                                            course_jilu_now.xuexi_word_num
                                    }}%</span></span>
                                    <span class="times-pn">(<span>{{
                                            course_jilu_now.xuexi_word_num
                                    }}</span>/<span>{{
        course_jilu_now.all_word_num
}}</span>)</span>
                                    <i class="el-icon-caret-bottom" v-show="!caretShow"></i>
                                    <i class="el-icon-caret-top" v-show="caretShow"></i>
                                    <div class="learn-progress">
                                        <el-progress :percentage="course_jilu_now.jindu" :stroke-width="8"
                                            :text-inside="inside" :show-text="textShow" :color="pColor"></el-progress>
                                    </div>
                                    <ul class="area-progress-list" v-show="isDisplay">
                                        <li class="a-item" v-for="(item, index) in course_jilu" :key="index"
                                            @click="clkCourseJindu(index)">
                                            <span class="a-item-title">{{
                                                    item.name
                                            }}</span>
                                            <span class="a-item-num">（第{{
                                                    item.xuexi_num
                                            }}遍）</span>
                                            <el-progress :percentage="item.percentage" :height="150"
                                                :text-inside="!inside"></el-progress>
                                            <span class="a-item-total">（
                                                <span class="t-num">{{
                                                        item.xuexi_word_num
                                                }}</span>/<span class="t-total">{{
        item.all_word_num
}}</span>
                                                ）</span>
                                            <el-button type="text" @click="anewStudy(index)" v-if="item.is_xuewan == 1">
                                                再学一遍
                                            </el-button>
                                        </li>
                                        <el-button type="text" class="a-btn">查看课程详细进度
                                        </el-button>
                                    </ul>
                                </div>
                                <div class="test-center" @click="toTestCenter()">
                                    <span class="test-text">测试中心</span>
                                </div>
                                <!-- <router-link :to="{name:'study_test_center'}" class="test-center">
                                                                                  <span class="test-text">测试中心</span>
                                                                                </router-link> -->
                            </div>
                        </div>
                        <div class="study-container">
                            <div class="study-l" @click="listShow = false">
                                <div class="l-title">
                                    <span>单元</span>
                                    <div class="t-progress">
                                        <span>进度</span>
                                        <el-switch class="t-switch" :width="pWidth" v-model="value"
                                            active-color="#6eb5ea" inactive-color="#eee" @change="progressDisplay">
                                        </el-switch>
                                        <i class="iconfont icon-wenhao1"></i>
                                    </div>
                                </div>
                                <div class="s-l-container">
                                    <ul class="study-list">
                                        <li class="s-item" :class="[
                                            item.id == thisDanyuanId
                                                ? 'itemColor'
                                                : '',
                                        ]" v-for="(
												item, index
											) in courseDanyuanLists" :key="index" @click="checkDanyuan(item, index)">
                                            <span v-if="item.is_tast > 0" class="test-txt">任务</span>
                                            <div class="s-item-container">
                                                <div class="s-title">
                                                    <span>{{ item.name }}</span>
                                                </div>
                                                <div class="s-progress">
                                                    <template v-for="(
															item_02, index_02
														) in item.jindu"><i class="
																fa fa-circle-o
																test-error
															" :key="index_02" v-if="
															    item_02.is_xuewan ==
															    0 &&
															    item_02.right_word_num ==
															    0
															"></i>
                                                        <i class="fa fa-adjust" :key="index_02" v-if="
                                                            item_02.is_xuewan ==
                                                            0 &&
                                                            item_02.right_word_num >
                                                            0
                                                        "></i>
                                                        <i class="
																fa fa-circle
																test-error
															" :key="index_02" v-if="
															    item_02.is_xuewan ==
															    1 &&
															    item_02.ceshi_num ==
															    0
															"></i>
                                                        <i class="
																fa
																fa-check-circle
															" :key="index_02" v-if="
															    item_02.is_xuewan ==
															    1 &&
															    item_02.fen >=
															    90 &&
															    item_02.ceshi_num >
															    0 &&
															    item_02.ks_level ==
															    1
															"></i>
                                                        <i class="
																fa
																fa-times-circle
															" style="
																font-size: 0.15rem;
															" :key="index_02" v-if="
															    item_02.is_xuewan ==
															    1 &&
															    item_02.fen <
															    90 &&
															    item_02.ceshi_num >
															    0 &&
															    item_02.ks_level ==
															    1
															"></i>
                                                        <i class="
																iconfont
																icon-shandian
															" :key="index_02" v-if="
															    item_02.is_xuewan ==
															    1 &&
															    item_02.ceshi_num >
															    0 &&
															    item_02.ks_level ==
															    2
															"></i>
                                                        <i class="
																iconfont
																icon-huangguan
															" :key="index_02" v-if="
															    item_02.is_xuewan ==
															    1 &&
															    item_02.ceshi_num >
															    0 &&
															    item_02.ks_level ==
															    3
															"></i>
                                                    </template>
                                                    <el-divider direction="vertical"></el-divider>
                                                    <template v-for="(
															item_03, index_03
														) in item.liju_jindu">
                                                        <i class="
																fa fa-circle-o
																test-error
															" :key="'b_' + index_03" v-if="
															    item_03.is_xuewan ==
															    0 &&
															    item_03.right_word_num ==
															    0
															"></i>
                                                        <i class="fa fa-adjust" :key="'b_' + index_03" v-if="
                                                            item_03.is_xuewan ==
                                                            0 &&
                                                            item_03.right_word_num >
                                                            0
                                                        "></i>
                                                        <i class="
																fa fa-circle
																test-error
															" :key="'b_' + index_03" v-if="
															    item_03.is_xuewan ==
															    1 &&
															    item_03.ceshi_num ==
															    0
															"></i>
                                                        <i class="
																fa
																fa-check-circle
															" :key="'b_' + index_03" v-if="
															    item_03.is_xuewan ==
															    1 &&
															    item_03.fen >=
															    90 &&
															    item_03.ceshi_num >
															    0 &&
															    item_03.ks_level ==
															    1
															"></i>
                                                        <i class="
																fa
																fa-times-circle
															" style="
																font-size: 0.15rem;
															" :key="'b_' + index_03" v-if="
															    item_03.is_xuewan ==
															    1 &&
															    item_03.fen <
															    90 &&
															    item_03.ceshi_num >
															    0 &&
															    item_03.ks_level ==
															    1
															"></i>
                                                        <i class="
																iconfont
																icon-shandian
															" :key="'b_' + index_03" v-if="
															    item_03.is_xuewan ==
															    1 &&
															    item_03.ceshi_num >
															    0 &&
															    item_03.ks_level ==
															    2
															"></i>
                                                        <i class="
																iconfont
																icon-huangguan
															" :key="'b_' + index_03" v-if="
															    item_03.is_xuewan ==
															    1 &&
															    item_03.ceshi_num >
															    0 &&
															    item_03.ks_level ==
															    3
															"></i>
                                                    </template>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="study-r">
                                <div class="choose-moudle">
                                    <div class="moudle-title">
                                        <div class="title-l">
                                            <span>选择模块</span>
                                            <i class="iconfont icon-wenhao1" @click="listShow = !listShow"></i>
                                            <div class="title-l__content" v-show="listShow">
                                                <div class="
														title-l__content--info
													">
                                                    <span class="info-text">打开进度后，单元名称后面的图标表示8个模块的学习状态，如图所示：</span>
                                                    <div class="info-state">
                                                        <span><i class="
																	fa
																	fa-circle-o
																"></i>未学</span>
                                                        <span><i class="
																	fa fa-adjust
																"></i>正在学</span>
                                                        <span><i class="
																	fa fa-circle
																"></i>未测</span>
                                                        <span><i class="
																	fa
																	fa-check-circle
																"></i>通过</span>
                                                        <span><i class="
																	fa
																	fa-times-circle
																"></i>未通过</span>
                                                        <span><i class="
																	iconfont
																	icon-shandian
																"></i>极速挑战</span>
                                                        <span><i class="
																	iconfont
																	icon-huangguan
																"></i>终极挑战</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="title-r">
                                            <span>学习方法</span>
                                            <i class="el-icon-caret-bottom"></i>
                                        </div>
                                    </div>
                                    <div class="moudle-practice" @click="listShow = false">
                                        <template v-if="currentCourse.banben_id == 7" tag="拼读环节">
                                            <template v-for="(item, i) in xuexi_jilu" v-if="i==1 || i==2">
                                                <div :class="['p-remeber p-item moudle-dialog', item.is_xuewan ? 'moudle-border' : '',]"
                                                    @click="danciMokuai(item, i)" v-if="i == xuexi_jilu_index" :key="i">
                                                    <span v-if="item.is_tast > 0" class="test-txt">任务</span>
                                                    <div class="r-content" v-if="
                                                        item.ceshi_num > 0 &&
                                                        item.ks_level == 1
                                                    ">
                                                        <div class="r-result" v-if="item.score >= 90">
                                                            <span class="r-logo">
                                                                <i class="
                                                                        iconfont
                                                                        icon-duigou1
                                                                    "></i>
                                                            </span>
                                                            <span class="r-text">{{
                                                                    item.score
                                                            }}分</span>
                                                        </div>
                                                        <div class="
                                                                r-result-score
                                                                error
                                                            " style="width: 1.2rem" v-else>
                                                            <span class="s-text">{{
                                                                    item.score
                                                            }}分</span>
                                                            <el-progress type="circle" class="large-circle" :percentage="
                                                                item.jindu
                                                            " :show-text="
        textShow
    " color="#65abf3"></el-progress>
                                                        </div>
                                                        <span class="c-text">{{
                                                                item.sort_title
                                                        }}</span>
                                                        <button class="reset-btn" style="right: 36%;font-size: 14px;"
                                                            @click.stop.prevent="anewLearn(i, 1)"
                                                            v-if="item.is_xuewan == 1">再学一遍
                                                        </button>
                                                    </div>
                                                    <div class="
                                                            i-content i-content--top
                                                        " v-else-if="
                                                            (item.is_xuewan == 0 ||
                                                                item.ceshi_num ==
                                                                0) &&
                                                            item.ks_level == 1
                                                        ">
                                                        <div class="i-result" style="width: 1.2rem">
                                                            <el-progress type="circle" class="large-circle" :percentage="
                                                                item.jindu
                                                            " :show-text="
        textShow
    " color="#65abf3"></el-progress>
                                                            <div class="
                                                                    s-text
                                                                    c-text
                                                                ">
                                                                <span class="r-num">{{
                                                                        item.right_word_num
                                                                }}</span>/<span class="r-total">{{
            item.word_num
    }}</span>
                                                            </div>
                                                        </div>
                                                        <span class="i-text">{{
                                                                item.sort_title
                                                        }}</span>
                                                        <button class="reset-btn" style="right: 36%;font-size: 14px;"
                                                            @click.stop.prevent="anewLearn(i, 1)"
                                                            v-if="item.is_xuewan == 1">再学一遍
                                                        </button>
                                                    </div>
                                                    <div class="r-content" v-if="item.ks_level == 2">
                                                        <div class="s-result" style="
                                                                display: flex;
                                                                align-items: center;
                                                                justify-content: center;
                                                            ">
                                                            <i class="
                                                                    iconfont
                                                                    icon-shandian1
                                                                " style="
                                                                    width: 1.2rem;
                                                                    height: 1.2rem;
                                                                "></i>
                                                        </div>
                                                        <span class="i-text" style="
                                                                font-size: 0.24rem;
                                                                margin-top: 30px;
                                                            ">{{
                                                                    item.sort_title
                                                            }}</span>
                                                        <button class="reset-btn" @click.stop.prevent="anewLearn(i, 1)"
                                                            v-if="item.is_xuewan == 1">再学一遍
                                                        </button>
                                                    </div>
                                                    <div class="r-content" v-if="item.ks_level == 3">
                                                        <div class="s-result" style="
                                                                display: flex;
                                                                align-items: center;
                                                                justify-content: center;
                                                            ">
                                                            <i class="
                                                                    iconfont
                                                                    icon-huangguan1
                                                                " style="
                                                                    width: 1.2rem;
                                                                    height: 1.2rem;
                                                                "></i>
                                                        </div>
                                                        <span class="i-text" style="margin-top: 30px">{{
                                                                item.sort_title
                                                        }}</span>
                                                        <button class="reset-btn" @click.s.stop.prevent="anewLearn(i, 1)"
                                                            v-if="item.is_xuewan == 1">再学一遍
                                                        </button>
                                                    </div>
                                                </div>
                                                <div :class="[
                                                    'p-item',
                                                    'little-img',
                                                    'moudle-width',
                                                    item.is_xuewan
                                                        ? 'moudle-border'
                                                        : '',
                                                ]" @click="danciMokuai(item, i)" v-if="i != xuexi_jilu_index">
                                                    <span v-if="item.is_tast > 0" class="test-txt">任务</span>
                                                    <div class="i-content">
                                                        <div class="i-result" v-if="
                                                            (item.is_xuewan ==
                                                                0 ||
                                                                item.ceshi_num ==
                                                                0) &&
                                                            item.ks_level == 1
                                                        ">
                                                            <el-progress type="circle" class="small-circle" :percentage="
                                                                item.jindu
                                                            " :show-text="
        textShow
    " color="#65abf3"></el-progress>
                                                            <div class="s-text">
                                                                <span class="r-num">{{
                                                                        item.right_word_num
                                                                }}</span>/<span class="r-total">{{
            item.word_num
    }}</span>
                                                            </div>
                                                        </div>
                                                        <div class="r-content" v-if="
                                                            item.is_xuewan ==
                                                            1 &&
                                                            item.ceshi_num >
                                                            0 &&
                                                            item.ks_level == 1
                                                        ">
                                                            <div class="r-result" v-if="
                                                                item.score >= 90
                                                            ">
                                                                <span class="r-logo"><i class="
                                                                            iconfont
                                                                            icon-duigou1
                                                                        "></i></span>
                                                                <span class="r-text">{{
                                                                        item.score
                                                                }}分</span>
                                                            </div>

                                                            <div class="
                                                                    r-result-score
                                                                " style="
                                                                    width: 0.88rem;
                                                                    height: 0.88rem;
                                                                " v-else>
                                                                <el-progress type="circle" class="circle" :percentage="
                                                                    item.jindu
                                                                " :show-text="
        textShow
    " color="#65abf3"></el-progress>
                                                                <div class="s-text">
                                                                    <span class="
                                                                            r-num
                                                                        ">{{
                                                                                item.score
                                                                        }}分</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="r-content" v-if="
                                                            item.ks_level == 2
                                                        ">
                                                            <div class="s-result" style="
                                                                    display: flex;
                                                                    align-items: center;
                                                                    justify-content: center;
                                                                ">
                                                                <i class="
                                                                        iconfont
                                                                        icon-shandian1
                                                                    " style="
                                                                        width: 0.88rem;
                                                                        height: 0.88rem;
                                                                    "></i>
                                                            </div>
                                                        </div>
                                                        <div class="r-content" v-if="
                                                            item.ks_level == 3
                                                        ">
                                                            <div class="s-result" style="
                                                                    display: flex;
                                                                    align-items: center;
                                                                    justify-content: center;
                                                                ">
                                                                <i class="
                                                                        iconfont
                                                                        icon-huangguan1
                                                                    " style="
                                                                        width: 0.88rem;
                                                                        height: 0.88rem;
                                                                    "></i>
                                                            </div>
                                                        </div>
                                                        <span class="i-text">{{
                                                                item.sort_title
                                                        }}</span>
                                                        <button class="reset-btn reset-btn__baby" @click.stop.prevent="anewLearn(i, 1)"
                                                            v-if="item.is_xuewan == 1">再学一遍
                                                        </button>
                                                    </div>
                                                </div>
                                            </template>
                                        </template>
                                        <template v-for="(item, i) in xuexi_jilu" v-else>
                                            <div :class="['p-remeber p-item moudle-dialog', item.is_xuewan ? 'moudle-border' : '',]"
                                                @click="danciMokuai(item, i)" v-if="i == xuexi_jilu_index" :key="i">
                                                <span v-if="item.is_tast > 0" class="test-txt">任务</span>
                                                <div class="r-content" v-if="
                                                    item.ceshi_num > 0 &&
                                                    item.ks_level == 1
                                                ">
                                                    <div class="r-result" v-if="item.score >= 90">
                                                        <span class="r-logo">
                                                            <i class="
																	iconfont
																	icon-duigou1
																"></i>
                                                        </span>
                                                        <span class="r-text">{{
                                                                item.score
                                                        }}分</span>
                                                    </div>
                                                    <div class="
															r-result-score
															error
														" style="width: 1.2rem" v-else>
                                                        <span class="s-text">{{
                                                                item.score
                                                        }}分</span>
                                                        <el-progress type="circle" class="large-circle" :percentage="
                                                            item.jindu
                                                        " :show-text="
    textShow
" color="#65abf3"></el-progress>
                                                    </div>
                                                    <span class="c-text">{{
                                                            item.sort_title
                                                    }}</span>
                                                    <button class="reset-btn" style="right: 36%;font-size: 14px;"
                                                        @click.stop.prevent="anewLearn(i, 1)"
                                                        v-if="item.is_xuewan == 1">再学一遍
                                                    </button>
                                                </div>
                                                <div class="
														i-content i-content--top
													" v-else-if="
													    (item.is_xuewan == 0 ||
													        item.ceshi_num ==
													        0) &&
													    item.ks_level == 1
													">
                                                    <div class="i-result" style="width: 1.2rem">
                                                        <el-progress type="circle" class="large-circle" :percentage="
                                                            item.jindu
                                                        " :show-text="
    textShow
" color="#65abf3"></el-progress>
                                                        <div class="
																s-text
																c-text
															">
                                                            <span class="r-num">{{
                                                                    item.right_word_num
                                                            }}</span>/<span class="r-total">{{
        item.word_num
}}</span>
                                                        </div>
                                                    </div>
                                                    <span class="i-text">{{
                                                            item.sort_title
                                                    }}</span>
                                                    <button class="reset-btn" style="right: 36%;font-size: 14px;"
                                                        @click.stop.prevent="anewLearn(i, 1)"
                                                        v-if="item.is_xuewan == 1">再学一遍
                                                    </button>
                                                </div>
                                                <div class="r-content" v-if="item.ks_level == 2">
                                                    <div class="s-result" style="
															display: flex;
															align-items: center;
															justify-content: center;
														">
                                                        <i class="
																iconfont
																icon-shandian1
															" style="
																width: 1.2rem;
																height: 1.2rem;
															"></i>
                                                    </div>
                                                    <span class="i-text" style="
															font-size: 0.24rem;
															margin-top: 30px;
														">{{
														        item.sort_title
														}}</span>
                                                    <button class="reset-btn" @click.stop.prevent="anewLearn(i, 1)"
                                                        v-if="item.is_xuewan == 1">再学一遍
                                                    </button>
                                                </div>
                                                <div class="r-content" v-if="item.ks_level == 3">
                                                    <div class="s-result" style="
															display: flex;
															align-items: center;
															justify-content: center;
														">
                                                        <i class="
																iconfont
																icon-huangguan1
															" style="
																width: 1.2rem;
																height: 1.2rem;
															"></i>
                                                    </div>
                                                    <span class="i-text" style="margin-top: 30px">{{
                                                            item.sort_title
                                                    }}</span>
                                                    <button class="reset-btn" @click.s.stop.prevent="anewLearn(i, 1)"
                                                        v-if="item.is_xuewan == 1">再学一遍
                                                    </button>
                                                </div>
                                            </div>
                                            <div :class="['p-item', 'little-img', item.is_xuewan ? 'moudle-border' : '']" @click="danciMokuai(item, i)" v-if="i !== xuexi_jilu_index">
                                                <span v-if="item.is_tast > 0" class="test-txt">任务</span>
                                                <div class="i-content">
                                                    <div class="i-result" v-if="
                                                        (item.is_xuewan === 0 || item.ceshi_num === 0) && item.ks_level === 1 ">
                                                        <el-progress type="circle" class="small-circle" :percentage="item.jindu" :show-text="textShow" color="#65abf3"></el-progress>
                                                        <div class="s-text">
                                                            <span class="r-num">
																{{ item.right_word_num }}
															</span>/
															<span class="r-total">
																{{ item.word_num }}
															</span>
                                                        </div>
                                                    </div>
                                                    <div class="r-content" v-if="item.is_xuewan === 1 && item.ceshi_num > 0 && item.ks_level === 1">
                                                        <div class="r-result" v-if="item.score >= 90">
                                                            <span class="r-logo">
																<i class="iconfont icon-duigou1"></i>
															</span>
                                                            <span class="r-text">
																{{ item.score }}分
															</span>
                                                        </div>

                                                        <div class="r-result-score" style="
																width: 0.88rem;
																height: 0.88rem;
															" v-else>
                                                            <el-progress type="circle" class="circle" :percentage="item.jindu" :show-text="textShow" color="#65abf3"></el-progress>
                                                            <div class="s-text">
                                                                <span class="r-num">
																	{{ item.score }}分
																</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="r-content" v-if="item.ks_level === 2">
                                                        <div class="s-result" style="
																display: flex;
																align-items: center;
																justify-content: center;
															">
                                                            <i class="iconfont icon-shandian1" style="
																	width: 0.88rem;
																	height: 0.88rem;
																"></i>
                                                        </div>
                                                    </div>
                                                    <div class="r-content" v-if="item.ks_level === 3">
                                                        <div class="s-result" style="
																display: flex;
																align-items: center;
																justify-content: center;
															">
                                                            <i class="iconfont icon-huangguan1" style="
																	width: 0.88rem;
																	height: 0.88rem;
																"></i>
                                                        </div>
                                                    </div>
                                                    <span class="i-text">
														{{ item.sort_title }}
													</span>
                                                    <button class="reset-btn" @click.stop.prevent="anewLearn(i, 1)" v-if="item.is_xuewan === 1">
														再学一遍
                                                    </button>
                                                </div>
                                            </div>
                                            <i class="iconfont icon-youzhijiantou1" v-if="i < 3" />
                                        </template>
                                    </div>
                                    <el-divider><i class="el-icon-caret-bottom"></i></el-divider>
                                    <div class="moudle-sentence">
                                        <div class="s-group">
                                            <template v-for="(item, index) in sentence">
                                                <div :class="['item', item.is_xuewan == 1 ? 'item-check' : '', index === 0 ? 'g-container' : 'item-box']"
                                                    @click="get_senIndex(index)" :key="index">
                                                    <span v-if="item.is_tast > 0" class="test-txt">任务</span>
                                                    <p class="g-text item-text" v-if="currentCourse.banben_id != 7">
                                                        {{ item.title }}
                                                    </p>
                                                    <p class="g-progress item-progress">
                                                        <span class="g-current item-current" v-if="currentCourse.banben_id != 7">
                                                            {{ item.has }}
                                                        </span>
                                                        <span v-if="currentCourse.banben_id != 7">/</span>
                                                        <span class="g-total item-total" v-if="currentCourse.banben_id != 7">
                                                            {{ item.total }}
                                                        </span>
                                                    </p>
                                                    <div class="g-check" v-if="item.is_xuewan == 1">
                                                        <i class="iconfont icon-duigou1"></i>
                                                    </div>
                                                    <button class="reset-btn" v-if="item.is_xuewan == 1"
                                                        @click.stop="anewLearn(index, 2)">再学一遍</button>
                                                </div>
                                                <i class="iconfont icon-youzhijiantou1" v-if="index < 3"></i>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="main-r" v-if="page_index == 2">
                    <div class="re-cursve">
                        <div class="re-cursve__title">
                            <span>记忆曲线</span>
                        </div>
                        <div class="re-cursve__content">
                            <div class="re-cursve__info">
                                <span>智能复习以德国心理学家赫尔曼·艾宾浩斯(HermannEbbinghaus)的遗忘曲线为理论基础，辅助记忆。</span>
                            </div>
                            <div class="re-cursve__img">
                                <img src="@/assets/images/study/ebbing.png" alt="记忆曲线" />
                            </div>
                            <div class="re-cursve__btn">
                                <!--<el-button class="navbar-btn">开始复习</el-button>-->
                            </div>
                            <div style="
									height: 0.01rem;
									border-bottom: 0.01rem dotted
										rgb(165, 157, 157);
									margin-top: 0.2rem;
								"></div>
                            <div class="re-cursve__footer">
                                <!--<span>你约有<span class="f-num">76</span>个单词需要复习</span>-->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="main-r" v-if="page_index == 3">
                    <div class="re-cursve">
                        <div class="re-cursve__title">
                            <span>单词量测试</span>
                        </div>
                        <div class="re-cursve__content">
                            <div class="re-cursve__info">
                                <span>针对每个学段进行单词量测试，来检测每个学段您掌握的单词量情况</span>
                            </div>
                            <div class="re-cursve__main">
                                <div mark="测试选项" id="wordTest" class="dclcs-main">
                                    <div style="
											color: rgb(135, 80, 60);
											font-weight: 700;
											font-size: 0.2rem;
											text-align: center;
										">
                                        请选择测试项目
                                    </div>
                                    <div class="dclcs-options">
                                        <div mark="测试方式：" class="dclcs-option dclcs-way">
                                            <label data-val="0"><input type="radio" name="way" data-val="1"
                                                    checked="checked" class="dclcs-way-lab" /><span>学段测试</span></label>
                                            <label data-val="1"><input type="radio" name="way" data-val="4"
                                                    class="dclcs-way-lab" /><span>学年测试</span></label>
                                            <label data-val="2"><input type="radio" name="way" data-val="3"
                                                    class="dclcs-way-lab" /><span>分册测试</span></label>
                                            <label data-val="3"><input type="radio" name="way" data-val="2"
                                                    class="dclcs-way-lab" /><span>竞赛测试</span></label>
                                        </div>
                                        <div mark="测试学段：" class="dclcs-option dclcs-period">
                                            <label data-val="0" class="
													both-period-lab
													both-period-lab-xx
												" style="margin-right: 0.03rem"><input type="radio" name="period" checked="checked" class="period-radio" />
                                                <!---->
                                                <span>小学</span>
                                            </label><label data-val="1" class="
													both-period-lab
													both-period-lab-xx
												" style="margin-right: 0.03rem">
                                                <!---->
                                                <input type="radio" name="period" class="period-radio" />
                                                <span>初中</span>
                                            </label><label data-val="2" class="
													both-period-lab
													both-period-lab-xx
												" style="margin-right: 0.03rem">
                                                <!---->
                                                <input type="radio" name="period" class="period-radio" />
                                                <span>高中</span>
                                            </label><label data-val="3" class="
													both-period-lab
													both-period-lab-xx
												" style="margin-right: 0.03rem">
                                                <!---->
                                                <input type="radio" name="period" class="period-radio" />
                                                <span>大学</span>
                                            </label><label data-val="4" class="
													both-period-lab
													both-period-lab-xx
												" style="margin-right: 0.03rem">
                                                <!---->
                                                <input type="radio" name="period" class="period-radio" />
                                                <span>四级</span>
                                            </label><label data-val="5" class="
													both-period-lab
													both-period-lab-xx
												" style="margin-right: 0.03rem">
                                                <!---->
                                                <input type="radio" name="period" class="period-radio" />
                                                <span>六级</span>
                                            </label><label data-val="6" class="
													both-period-lab
													both-period-lab-xx
												" style="margin-right: 0.03rem">
                                                <!---->
                                                <input type="radio" name="period" class="period-radio" />
                                                <span>托福</span>
                                            </label><label data-val="7" class="
													both-period-lab
													both-period-lab-xx
												" style="margin-right: 0.03rem">
                                                <!---->
                                                <input type="radio" name="period" class="period-radio" />
                                                <span>雅思</span>
                                            </label>
                                        </div>
                                        <div mark="测试版本：" class="dclcs-option dclcs-version">
                                            <select class="dclcs-version-select" style="
													height: 0.3rem;
													width: 80%;
												">
                                                <option value="0" data-version="0" selected="selected" class="default">
                                                    请选择
                                                </option>
                                                <option class="cs-version" value="227">
                                                    【三上】词汇
                                                </option>
                                                <option class="cs-version" value="226">
                                                    【四上】词汇
                                                </option>
                                                <option class="cs-version" value="225">
                                                    【五上】词汇期末
                                                </option>
                                                <option class="cs-version" value="224">
                                                    【五上】词汇期中
                                                </option>
                                                <option class="cs-version" value="223">
                                                    【六上】词汇期末
                                                </option>
                                                <option class="cs-version" value="222">
                                                    【六上】词汇期中
                                                </option>
                                                <option class="cs-version" value="193">
                                                    小学必备词汇八
                                                </option>
                                                <option class="cs-version" value="191">
                                                    小学必备词汇七
                                                </option>
                                                <option class="cs-version" value="195">
                                                    小学必备词汇六
                                                </option>
                                                <option class="cs-version" value="185">
                                                    小学必备词汇五
                                                </option>
                                                <option class="cs-version" value="186">
                                                    小学必备词汇四
                                                </option>
                                                <option class="cs-version" value="184">
                                                    小学必备词汇三
                                                </option>
                                                <option class="cs-version" value="190">
                                                    小学必备词汇二
                                                </option>
                                                <option class="cs-version" value="188">
                                                    小学必备词汇一
                                                </option>
                                                <option class="cs-version" value="153">
                                                    【外研版】小考高频词汇（一）
                                                </option>
                                                <option class="cs-version" value="152">
                                                    【外研版】小考高频词汇（二）
                                                </option>
                                                <option class="cs-version" value="151">
                                                    【外研版】小考高频词汇（三）
                                                </option>
                                                <option class="cs-version" value="143">
                                                    外研小学2013三年级上（三年级起点）
                                                </option>
                                                <option class="cs-version" value="142">
                                                    外研小学2013三年级下（三年级起点）
                                                </option>
                                                <option class="cs-version" value="141">
                                                    外研小学2013四年级上（三年级起点）
                                                </option>
                                                <option class="cs-version" value="140">
                                                    外研小学2013四年级下（三年级起点）
                                                </option>
                                                <option class="cs-version" value="139">
                                                    外研小学2013五年级上（三年级起点）
                                                </option>
                                                <option class="cs-version" value="138">
                                                    外研小学2013五年级下（三年级起点）
                                                </option>
                                                <option class="cs-version" value="137">
                                                    外研小学2013六年级上（三年级起点）
                                                </option>
                                                <option class="cs-version" value="136">
                                                    外研小学2013六年级下（三年级起点）
                                                </option>
                                                <option class="cs-version" value="134">
                                                    【小学外研版】sight words
                                                </option>
                                                <option class="cs-version" value="202">
                                                    少儿阅读必备（一）
                                                </option>
                                                <option class="cs-version" value="201">
                                                    枫叶国际词汇
                                                </option>
                                            </select>
                                        </div>
                                        <div mark="测试年级：" class="
												dclcs-option dclcs-grade
												hide
											">
                                            <select class="dclcs-grade-select" style="
													height: 0.3rem;
													width: 80%;
												">
                                                <option value="0" data-grade="0" selected="selected" class="default">
                                                    请选择
                                                </option>
                                                <option class="cs-version">
                                                    三年级
                                                </option>
                                            </select>
                                        </div>
                                        <div mark="测试教材：" class="dclcs-option dclcs-book hide">
                                            <select class="dclcs-book-select" style="
													height: 0.3rem;
													width: 80%;
												">
                                                <option value="0" data-book="0" selected="selected" class="default">
                                                    请选择
                                                </option>
                                            </select>
                                        </div>
                                        <div mark="测试类型：" class="dclcs-option dclcs-type">
                                            <label><input type="checkbox" name="type"
                                                    checked="checked" /><span>英译汉(选择题)</span></label>
                                            <label><input type="checkbox" name="type"
                                                    checked="checked" /><span>汉译英(选择题)</span></label>
                                            <label><input type="checkbox" name="type"
                                                    checked="checked" /><span>听写</span></label>
                                            <label><input type="checkbox" name="type" checked="checked" /><span>
                                                    中译英</span></label>
                                        </div>
                                        <div mark="题目数量：" class="dclcs-option dclcs-number">
                                            <label><input type="radio" name="number" data-val="100"
                                                    checked="checked" /><span>100</span></label>
                                            <label><input type="radio" name="number"
                                                    data-val="200" /><span>200</span></label>
                                            <label><input type="radio" name="number"
                                                    data-val="400" /><span>400</span></label>
                                        </div>
                                    </div>
                                    <!--<div id="dclcsStartBtn" class="custom-btn dclcs-start-btn">
                                      开始测试
                                    </div>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog width="1000px" v-if="dialogVisible" :visible.sync="dialogVisible"
            :close-on-click-modal="close" :show-close="close" :close-on-press-escape="false">
            <div class="dialog-title" slot="title">
                <div class="dialog-title__data">
                    <div class="dialog-title__time">
                        <img :src="cloakImg" alt="">
                        <span>{{ jsq_times }}</span>
                    </div>
                    <div class="dialog-title__person">
                        <img :src="avatarImg" alt="" style="border-radius: 50%">
                        <span>{{ trueName }}</span>
                    </div>
                </div>
                <div class="dialog-title__header">
                    <span>智能翻译</span>
                </div>
            </div>
            <ai-remeber @close="handleClose" title="hello!" :audio-url="audioUrl" :all-lists="allWords"
                :words-lists="currentWords" :course="currentCourse" :danyuan="currentDanyuan" :study-type="study_type"
                :review-type="review_type" :next-fuxi-type="nextFuxiType" ref="dialog_jiyi" />
        </el-dialog>
        <el-dialog
                width="1000px"
                v-if="ciVisible"
                :visible.sync="ciVisible"
                :close-on-click-modal="close"
                :show-close="close"
                :close-on-press-escape="false"
        >
            <div class="dialog-title" slot="title">
                <div class="dialog-title__data">
                    <div class="dialog-title__time">
                        <img :src="cloakImg" alt="">
                        <span>{{ jsq_times }}</span>
                    </div>
                    <div class="dialog-title__person">
                        <img :src="avatarImg" alt="" style="border-radius: 50%">
                        <span>{{ trueName }}</span>
                    </div>
                </div>
                <div class="dialog-title__header">
                    <span>智能听选</span>
                </div>
            </div>
                <aiBaby
                    @close="handleClose"
                    :audio-url="audioUrl"
                    :all-lists="allWords"
                    :words-lists="currentWords"
                    :course="currentCourse"
                    :danyuan="currentDanyuan"
                    :study-type="study_type"
                    :review-type="review_type"
                    :next-fuxi-type="nextFuxiType"
                    ref="dialog_citing"
                    v-if="currentCourse.banben_id == 7"
            />
            <aiListen
                    @close="handleClose"
                    :audio-url="audioUrl"
                    :all-lists="allWords"
                    :words-lists="currentWords"
                    :course="currentCourse"
                    :danyuan="currentDanyuan"
                    :study-type="study_type"
                    :review-type="review_type"
                    :next-fuxi-type="nextFuxiType"
                    ref="dialog_citing"
                    v-else
            />

        </el-dialog>
        <el-dialog width="1000px" v-if="writeVisible" :visible.sync="writeVisible"
            :close-on-click-modal="close" :show-close="close" :close-on-press-escape="false">
            <div class="dialog-title" slot="title">
                <div class="dialog-title__data">
                    <div class="dialog-title__time">
                        <img :src="cloakImg" alt="">
                        <span>{{ jsq_times }}</span>
                    </div>
                    <div class="dialog-title__person">
                        <img :src="avatarImg" alt="" style="border-radius: 50%">
                        <span>{{ trueName }}</span>
                    </div>
                </div>
                <div class="dialog-title__header">
                    <span>智能听写</span>
                </div>
            </div>

            <aiWritePindu
                v-if="currentCourse.banben_id == 7"
                @close="handleClose" :audio-url="audioUrl" :all-lists="allWords" :words-lists="currentWords"
                    :course="currentCourse" :danyuan="currentDanyuan" :study-type="study_type" :review-type="review_type"
                    :next-fuxi-type="nextFuxiType" ref="dialog_tingxie"
            />

            <aiWrite @close="handleClose" :audio-url="audioUrl" :all-lists="allWords" :words-lists="currentWords"
                :course="currentCourse" :danyuan="currentDanyuan" :study-type="study_type" :review-type="review_type"
                :next-fuxi-type="nextFuxiType" ref="dialog_tingxie" v-else/>
        </el-dialog>
        <el-dialog width="1000px" v-if="diVisible" :visible.sync="diVisible" :close-on-click-modal="close"
            :show-close="close" :close-on-press-escape="false">
            <div class="dialog-title" slot="title">
                <div class="dialog-title__data">
                    <div class="dialog-title__time">
                        <img :src="cloakImg" alt="">
                        <span>{{ jsq_times }}</span>
                    </div>
                    <div class="dialog-title__person">
                        <img :src="avatarImg" alt="" style="border-radius: 50%">
                        <span>{{ trueName }}</span>
                    </div>
                </div>
                <div class="dialog-title__header">
                    <span>智能默写</span>
                </div>
            </div>
            <aiDictation @close="handleClose" :audio-url="audioUrl" :all-lists="allWords" :words-lists="currentWords"
                :course="currentCourse" :danyuan="currentDanyuan" :study-type="study_type" :review-type="review_type"
                :next-fuxi-type="nextFuxiType" ref="dialog_moxie" />
        </el-dialog>
        <el-dialog class="unitTest" fullscreen v-if="reTestVisible" :visible.sync="reTestVisible" width="914px"
            :close-on-press-escape="false">
            <reTest @close="ceshiClose" @cuociGonggu="cuociGonggu" @againTest="againTest" :test-data="test_data" :test-type="test_type"
                :test-type-name="test_type_name[test_type - 1]" :danyuan-id="thisDanyuanId" :course-id="thisCourseId"
                :nianji-id="nianjiId" :audio-url="audioUrl" ref="dialog_jiyi_ceshi" />
        </el-dialog>
        <el-dialog class="unitTest" fullscreen v-if="liTestVisible" :visible.sync="liTestVisible" width="914px"
            :close-on-press-escape="false">
            <liTestPindu @close="ceshiClose" @cuociGonggu="cuociGonggu" @againTest="againTest" :audio-url="audioUrl" :test-data="test_data"
                :test-type="test_type" :test-type-name="test_type_name[test_type - 1]" :danyuan-id="thisDanyuanId"
                :course-id="thisCourseId" :nianji-id="nianjiId" ref="dialog_citing_ceshi" v-if="currentCourse.banben_id == 7" />
            <liTest @close="ceshiClose" @cuociGonggu="cuociGonggu" @againTest="againTest" :audio-url="audioUrl" :test-data="test_data"
                :test-type="test_type" :test-type-name="test_type_name[test_type - 1]" :danyuan-id="thisDanyuanId"
                :course-id="thisCourseId" :nianji-id="nianjiId" ref="dialog_citing_ceshi" v-else/>
        </el-dialog>
        <el-dialog class="unitTest" fullscreen v-if="wrTestVisible" :visible.sync="wrTestVisible" width="914px"
            :close-on-press-escape="false">
            <wrTest @close="ceshiClose" @cuociGonggu="cuociGonggu" @againTest="againTest" :audio-url="audioUrl" :test-data="test_data"
                :test-type="test_type" :test-type-name="test_type_name[test_type - 1]" :danyuan-id="thisDanyuanId"
                :course-id="thisCourseId" :nianji-id="nianjiId" ref="dialog_tingxie_ceshi" />
        </el-dialog>
        <el-dialog class="unitTest" fullscreen v-if="diTestVisible" :visible.sync="diTestVisible" width="914px"
            :close-on-press-escape="false">
            <diTest @close="ceshiClose" @cuociGonggu="cuociGonggu" @againTest="againTest" :audio-url="audioUrl" :test-data="test_data"
                :test-type="test_type" :test-type-name="test_type_name[test_type - 1]" :danyuan-id="thisDanyuanId"
                :course-id="thisCourseId" :nianji-id="nianjiId" ref="dialog_moxie_ceshi" />
        </el-dialog>
        <el-dialog class="speed-challenge" v-if="speedVisible" :visible.sync="speedVisible" width="1000px"
            :close-on-click-modal="close" :show-close="close" :close-on-press-escape="false">
            <speedChallengePindu @close="speedChallengeClose" width="1000px" :audio-url="audioUrl"
                :test-data="test_data" :test-type="test_type" :mode-type="type"
                :test-type-name="test_type_name[test_type - 1]" :course="currentCourse" :danyuan="currentDanyuan"
                ref="dialog_speed_challenge"
                v-if="currentCourse.banben_id == 7">
            </speedChallengePindu>
            <speedChallenge @close="speedChallengeClose" width="1000px" :audio-url="audioUrl" :test-data="test_data"
                :test-type="test_type" :mode-type="type" :test-type-name="test_type_name[test_type - 1]"
                :course="currentCourse" :danyuan="currentDanyuan" ref="dialog_speed_challenge"
                v-else>
            </speedChallenge>
        </el-dialog>
        <el-dialog :title="senTitle" width="1000px" v-if="senVisible" :visible.sync="senVisible"
            :close-on-click-modal="close" :close-on-press-escape="false" :show-close="close">
            <sentence @close="duanyuClose" :words-lists="duanyuList" :course="currentCourse" :danyuan="currentDanyuan"
                :study-type="study_type" :review-type="review_type" :next-fuxi-type="nextFuxiType" :module="senIndex"
                ref="dialog_liju" />
        </el-dialog>
        <el-dialog class="choose-modes" width="450px" style="margin-top: 12vh" :visible.sync="chooseVisible"
            v-if="chooseVisible" :close-on-click-modal="close" :show-close="close">
            <chooseMode @close="chooseModeClose" :ksLevel="ks_level" ref="dialog_choosemode" />
        </el-dialog>
        <!-- <el-dialog class="loaded-dialog" fullscreen :visible.sync="loadingVisible">
      <img src="~@/assets/images/study/transi.gif" alt="新赛季" />
    </el-dialog> -->
        <el-dialog class="update-avatar" :show-close="close" v-if="avatarVisible" :visible.sync="avatarVisible"
            width="855px">
            <update_avatar @close="avatarClose" />
        </el-dialog>
        <el-dialog
                class="notice-dialog"
                :visible.sync="noticeVisible"
                v-if="errorWordCount==0 && noticeVisible== true"
                width="45%"
                :show-close="false"
                :close-on-click-modal="false"
        >
            <global-notice @closeCards="closeCard" :data="noticeData" :data-list="noTestData" v-if="noticeVisible" />
        </el-dialog>

        <el-dialog
            :visible.sync="errorWordCount"
            :close-on-click-modal="false"
            :show-close="false"
            v-if="errorWordCount > 0"
            class="wrong-dialog"
            width="30%">
            <span>你还有<span style="margin: 0 10px; color: red">{{errorWordCount}}</span>个错词未消除，先完成错词消除，才能做其它学习</span>
            <router-link :to="{ name: 'study_ai_word_ai_word_wrong' }">
                <el-button type="primary" @click="dialogVisible = false">前往错词消除</el-button>
            </router-link>
        </el-dialog>

    </div>
</template>

<script>
import $ from "jquery";
import { mapMutations, mapState } from "vuex";
// 记忆模块
import aiRemeber from "@/components/ai_word_components/ai_remeber";
// 词听模块
import aiListen from "@/components/ai_word_components/ai_listen";
// 听写模块
import aiWrite from "@/components/ai_word_components/ai_write";
// 幼儿拼读听力
import aiBaby from "@/components/ai_word_components/ai_listen_pindu"
// 幼儿拼读听写
import aiWritePindu from "@/components/ai_word_components/ai_write_pindu"

// 默写模块
import aiDictation from "@/components/ai_word_components/ai_dictation";
import reTest from "@/components/ai_word_components/re_unit_test"; //记忆测试
import liTest from "@/components/ai_word_components/li_unit_test"; //词听测试
import liTestPindu from "@/components/ai_word_components/li_unit_test_pindu"; //词听测试
import wrTest from "@/components/ai_word_components/wr_unit_test"; //听写测试
import diTest from "@/components/ai_word_components/di_unit_test"; //默写测试

import chooseMode from "@/components/ai_word_components/choose_mode"; //默写测试

// 一键复习模块
import oneKey from "@/components/ai_word_components/one_key";
// 切换课程模块
import coChange from "@/components/ai_word_components/course_change";
import trans from "@/components/ai_word_components/trans";
//极速学习
import speedChallenge from "@/components/ai_word_components/speed_challenge";

//少儿拼读版极速学习
import speedChallengePindu from "@/components/ai_word_components/speed_challenge_pindu";

// 修改头像
import update_avatar from "@/views/study/update_avatar";
// 例句模块
import sentence from "@/components/sentence/sentence"
// 通知公告
import GlobalNotice from "@/views/study/GlobalNotice";

export default {
    components: {
        aiRemeber,
        aiListen,
        aiWrite,
        aiDictation,
        coChange,
        reTest,
        wrTest,
        liTest,
        diTest,
        oneKey,
        trans,
        speedChallenge,
        speedChallengePindu,
        chooseMode,
        update_avatar,
        sentence,
        aiBaby,
        liTestPindu,
        aiWritePindu,
        GlobalNotice
    },
    data() {
        return {
            cloakImg: require('@/assets/images/study/icons8-clock1.gif'),
            noticeImg: require('@/assets/images/study/notice.gif'),
            avatarImg: '',
            trueName: '',
            jsq_times: "00.00.00",
            escClose: false,
            noticeVisible: false,
            errorWordVisible:false,
            eyes: false,
            senTitle: '',
            senIndex: 0,
            senVisible: false,
            avatarVisible: false,
            loadingVisible: false,
            groupVisible: false,
            listeningVisible: false,
            translateVisible: false,
            dictationVisible: false,
            speedVisible: false, //极速模式
            dialogVisible: false, // 记忆模块
            ciVisible: false, // 词听模块
            writeVisible: false, // 听写模块
            diVisible: false, // 默写模块
            chooseVisible: false,

            transVisible: false, //一键消灭错词

            reTestVisible: false, //记忆测试
            liTestVisible: false, //词听测试
            wrTestVisible: false, //听写测试
            diTestVisible: false, //默写测试
            videoView: false, // 圣诞视频

            test_type: 6, //"今日已学测试", "已学测试", "单元测试", "全词测试",'错词测试','学习测试','极速挑战','终极挑战'
            test_type_name: [
                "今日已学测试",
                "已学测试",
                "单元测试",
                "全词测试",
                "错词测试",
                "学习测试",
                "极速挑战",
                "终极挑战",
            ],

            listShow: false,
            audioUrl: "", //音频域名地址
            // 一键复习页面
            courseTitle: "智能记忆",
            // 进度条显示文字内置在进度条内
            inside: true,
            pColor: "#7fb8f2",
            // 开关按钮
            value: true,
            // 开关按钮的宽度
            pWidth: 25,
            // 进度条文字是否显示
            textShow: false,
            customColors: "#feb661",
            // 学习进度的显示
            isDisplay: false,
            percentage: "",
            // 各模块弹框是否关闭
            close: false,
            // 控制弹框是否全屏
            fullScreen: true,
            coVisible: false,
            cWidth: "784",
            //订阅课程
            courseDingyueLists: [],
            //当前课程单元
            courseDanyuanLists: [],
            //当前课程id
            thisCourseId: 0,
            //当前课程下标
            thisCourseIndex: 0,
            //当前单元id
            thisDanyuanId: 0,
            //当前单元的下标
            thisDanyuanIndex: 0,
            //为了弹窗加载判断是不是第一次
            dialog_num: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            caretShow: true,
            course_jilu: [], //课程学习进度
            course_jilu_now: {}, //当前进度
            xuexi_jilu: [
                {
                    is_tast:0,
                    score: 0,
                    jindu: 0,
                    title: "智能翻译",
                    sort_title: "翻译",
                    word_num: 0,
                    right_word_num: 0,
                    xuexi_num: 1,
                    ceshi_num: 0,
                    ks_level: 1,

                },
                {
                    score: 0,
                    jindu: 0,
                    title: "智能听选",
                    sort_title: "听选",
                    word_num: 0,
                    right_word_num: 0,
                    ceshi_num: 1,
                    ks_level: 1,
                },
                {
                    score: 0,
                    jindu: 0,
                    title: "智能听写",
                    sort_title: "听写",
                    word_num: 0,
                    right_word_num: 0,
                    ceshi_num: 1,
                    ks_level: 1,
                },
                {
                    score: 0,
                    jindu: 0,
                    title: "智能默写",
                    sort_title: "默写",
                    word_num: 0,
                    right_word_num: 0,
                    ceshi_num: 1,
                    ks_level: 1,
                },
            ], //学习情况
            nav_fuxi: [],
            xuexi_jilu_index: 0,
            currentCourse: {},
            currentDanyuan: {},
            currentWords: [], //当前单元单词
            allWords: [],
            type: 1, //0其它，1记忆，2词听，3听写，4默写
            mode_type: 1, //1学习,2测试,3单元检测，4听写，5中译英，6英译中
            is_pass: 1, //是否学习过
            study_type: 1, //类型，1学习，2复习，3错词
            review_type: 1, ////复习类型，1复习，2一键复习
            nextFuxiType: { id: 1, name: "无", count: 0, last_type: -1 },
            test_data: [],
            ks_level: 1, //
            levelIndex: 6,
            level_img: [
                { src: require("../../assets/images/study/qingtong.png") },
                { src: require("../../assets/images/study/baiyin.png") },
                { src: require("../../assets/images/study/huangjin.png") },
                { src: require("../../assets/images/study/bojin.png") },
                { src: require("../../assets/images/study/zuanshis.png") },
                { src: require("../../assets/images/study/xingyao.png") },
                { src: require("../../assets/images/study/wangzhe.png") },
                { src: require("../../assets/images/study/rongyao.png") },
            ],
            videoUrl: require("../../assets/video/1.mp4"),
            sentence: [
                {
                    title: '例句翻译',
                    has: 0,
                    total: 0,
                    is_tast: 0
                },
                {
                    title: '例句听选',
                    has: 0,
                    total: 0,
                    is_tast: 0
                },
                {
                    title: '例句听写',
                    has: 0,
                    total: 0,
                    is_tast: 0
                },
                {
                    title: '例句默写',
                    has: 0,
                    total: 0,
                    is_tast: 0
                }
            ],
            duanyuList: [],
            noticeData: [],
            noTestData: [],
            testData: [],
            animate: true,
            intervalId: null
        };
    },
    beforeCreate() {
    },
    created() {
        this.jsq()
        //console.log("created--------------------------");
        // this.videoView = true;
        setTimeout(() => {
            this.videoView = false;
        }, 3000);
        this.getDingyue();
        this.getFuxi();
        this.getUserInfo();
        this.audioUrl = this.$conf.audio_url;
        this.getNotice();
        if(this.errorWordCount>0){
            //this.errorWordVisible = true;
        } 
        //this.getErrorWordCount();        
        // this.$nextTick(() => {
        //     this.scroll()
        //     this.intervalId = setInterval(this.scroll, 16000)
        // })
    },
    mounted() {
        if (this.loginCount === 0) {
            this.loadingVisible = true;
            this.$store.commit("setLoginCount");
            setTimeout(() => {
                this.loadingVisible = false;
            }, 3000);
        }
        // setTimeout(() => {
        //     console.log('滑块元素------', this.$refs.slides.clientWidth)
        //     this.scroll()
        //     this.intervalId = setInterval(this.scroll, 601000)
        // }, 2000)
    },
    updated() {
        let sTitle = document.querySelectorAll(".s-title");
        let reg = /[\u4e00-\u9fa5]/;
        // let reg = /[A-z]/;
        for (let i = 0; i < sTitle.length; i++) {
            if (reg.test(sTitle[i].innerHTML)) {
                sTitle[i].style.fontSize = ".18rem";
            } else {
                sTitle[i].style.fontSize = ".2rem";
            }
        }
    },
    destroyed() {
        clearInterval(this.intervalId)
    },
    computed: {
        ...mapState({
            token: (state) => state.user.token,
            user: (state) => state.user.user,
            loginCount: (state) => state.user.loginCount,
            page_index: (state) => state.common.page_index,
            noticeCount:(state) => state.user.noticeCount,
            errorWordCount:(state) => state.user.errorWordCount,
        }),
        // 计算属性获取等级
        jinDu: function () {
            // `this` 指向 vm 实例
            var jd = 0;
            if (this.user.level != undefined) {
                var baoshi = this.user.baoshi || 0;
                var upper_exp = this.user.level.end_inter;
                var lower_exp = this.user.level.start_inter;
                var all = upper_exp - lower_exp;
                var j = baoshi - lower_exp;
                //console.log("upper_exp：" + upper_exp + "lower_exp：" + lower_exp);
                if (all <= 0) {
                    jd = 0;
                } else if (j > all) {
                    jd = 100;
                } else {
                    jd = Math.round((j / all) * 100);
                }
            }
            /*if(upper_exp>0){
                        jd = Math.round((lower_exp / upper_exp) * 100);
                      }
                      if(jd<0){
                        jd = 0;
                      }else if(jd>100){
                        jd = 100;
                      }*/
            return jd;
        },
        nianjiId: function () {
            return parseInt(this.currentCourse.nianji_id);
        }
    },
    methods: {
        closeCard(val) {
            this.noticeVisible = val
        },
        //封装一个处理单位数字的函数
        showNum(num) {
            if (num < 10) {
                return "0" + num;
            }
            return num;
        },
        jsq() {
            //点击开始建 开始计数
            let that = this;
            var uid = this.user.id;
            //console.log("uid:" + uid);
            var s = window.sessionStorage.getItem("jqx_count" + uid);
            var count = 0;
            if (s) {
                //console.log("sessionStorage:" + s);
                count = s;
            }
            var timer = null; //timer变量记录定时器setInterval的返回值
            timer = setInterval(function () {
                count++;
                window.sessionStorage.setItem("jqx_count" + uid, count);
                //console.log(count);
                // 需要改变页面上时分秒的值
                //console.log($("id_S"));
                var times_str = "";
                times_str += that.showNum(parseInt(count / 60 / 60)) + ":";
                times_str += that.showNum(parseInt(count / 60) % 60) + ":";
                times_str += that.showNum(count % 60);
                //console.log(times_str);
                that.jsq_times = times_str;
            }, 1000);
        },
        changeEyes() {
            this.eyes = !this.eyes;
        },
        get_senIndex(index) {
            //return;
            this.senIndex = index;
            //console.log(this.senIndex);
            this.getDuanyu(this.senIndex + 1);
            if (index === 0) {
                this.senTitle = '例句翻译';
            } else if (index === 1) {
                this.senTitle = '例句听选';
            } else if (index === 2) {
                this.senTitle = '例句听写';
            } else {
                this.senTitle = '例句默写';
            }
        },
        getDuanyu(p_type = 0, dialog_type = 1) {
            let type = p_type != 0 ? p_type : this.type;
            //获取短语
            let data = {
                token: this.token,
                danyuan_id: this.thisDanyuanId,
                type: type,
                study_type: this.study_type,
                mode_type: this.mode_type,
                is_pass: this.is_pass,
            };
            this.axios.post("/api/duanyu/get_dy_list", data, { loading: true }).then((res) => {
                //console.log(res);
                if (res.data.status_code == 200) {
                    this.duanyuList = res.data.data.lists;
                    if (this.duanyuList.length > 0) {
                        this.senVisible = true;
                    } else {
                        this.$message("没有可学习的例句！");
                    }

                } else if (res.data.status_code == 202) {
                } else {
                    this.$message(res.data.msg);
                }
            }).catch((err) => {
                this.loading = false;
            });
        },
        duanyuClose() {
            console.log("duanyuClose");
            this.senVisible = false;
            this.getDanyuan(1);
        },
        getDingyue() {
            //获取订阅课程
            this.loading = true;
            this.axios
                .post("/api/course/get_dingyue", { token: this.token })
                .then((res) => {
                    //console.log(res);
                    var data = res.data;
                    if (data.status_code == 200) {
                        this.courseDingyueLists = data.data;
                        let course_id = 0;
                        this.courseDingyueLists.forEach((item, index) => {
                            if (item.xuexi_status == 1) {
                                course_id = item.id;
                                this.thisCourseIndex = index;
                            }
                        });
                        if (course_id == 0) {
                            course_id = this.courseDingyueLists[0]["id"];
                        }
                        this.thisCourseId = course_id;
                        this.currentCourse =
                            this.courseDingyueLists[this.thisCourseIndex];
                        //console.log("currentCourse");
                        //console.log(this.currentCourse);
                        this.getDanyuan();
                    } else if (res.data.status_code == 202) {
                    } else {
                        //this.$message(res.data.msg)
                    }
                })
                .catch((err) => {
                    //this.loading = false
                });
        },
        getDanyuan(isReLoading = 0) {
            console.log('isReLoading', isReLoading);
            //获取单元
            this.loading = true;
            //console.log("thisCourseId" + this.thisCourseId);
            this.axios
                .post("/api/course/get_danyuan", {
                    token: this.token,
                    course_id: this.thisCourseId,
                })
                .then((res) => {
                    //console.log(res);
                    var data = res.data;
                    if (data.status_code == 200) {
                        this.courseDanyuanLists = data.data.lists;
                        this.course_jilu = data.data.jindu;
                        this.course_jilu_now = this.course_jilu[0];
                        if (isReLoading === 0) {
                            this.thisDanyuanIndex = 0;
                            this.thisDanyuanId =
                                this.courseDanyuanLists[0]["id"];
                        }

                        this.setXuexiJilu();
                        //this.getWords();//获取单词
                        //console.log("getdanyuan");
                        //console.log(this.courseDanyuanLists);
                    } else if (res.data.status_code === 202) {
                    } else {
                        //this.$message(res.data.msg)
                    }
                })
                .catch((err) => {
                    //this.loading = false
                });
        },
        getWords(p_type = 0, dialog_type = 1) {
            let type = p_type != 0 ? p_type : this.type;
            this.loading = true;
            this.axios
                .post(
                    "/api/course/get_word",
                    {
                        token: this.token,
                        danyuan_id: this.thisDanyuanId,
                        type: type,
                        study_type: this.study_type,
                        mode_type: this.mode_type,
                        is_pass: this.is_pass,
                    },
                    {
                        loading: true,
                    }
                )
                .then((res) => {
                    //console.log(res);
                    var data = res.data;
                    if (data.status_code == 200) {
                        this.currentWords = data.data.lists;
                        this.allWords = data.data.all_lists;
                        if (this.currentWords.length > 0) {
                            //console.log(this.currentWords);
                            if (dialog_type == 1) {
                                this.showDialog();
                            }
                        } else {
                            if (this.study_type == 1) {
                                this.$message("没有可学习的单词");
                            } else if (this.study_type == 2) {
                                this.$message("没有可复习的单词");
                            } else {
                            }
                        }
                        //console.log("getdanyuan");
                        //console.log(this.currentWords);
                    } else if (res.data.status_code == 202) {
                    } else {
                        //this.$message(res.data.msg)
                    }
                })
                .catch((err) => {
                    //this.loading = false
                });
        },
        getFuxi() {
            this.loading = true;
            this.axios
                .post("/api/learn/get_fuxi", {
                    token: this.token,
                    danyuan_id: this.thisDanyuanId,
                    type: this.type,
                    mode_type: this.mode_type,
                    is_pass: this.is_pass,
                })
                .then((res) => {
                    //console.log(res);
                    var data = res.data;
                    if (data.status_code == 200) {
                        this.nav_fuxi = data.data;
                        //console.log(this.nav_fuxi);
                    } else if (res.data.status_code == 202) {
                    } else {
                        //this.$message(res.data.msg)
                    }
                })
                .catch((err) => {
                    //this.loading = false
                });
        },
        getTestWords(dialog_type = 1) {
            let than = this;
            this.loading = true;
            //let danyuan_ids = this.danyuanCheckStr;
            let params = {
                token: this.token,
                type: this.type,
                course_id: this.thisCourseId,
                test_type: this.test_type,
                danyuan_ids: this.thisDanyuanId,
            };
            //console.log("getTestWords");
            this.axios
                .post("/api/test_center/get_word", params, {
                    loading: true,
                })
                .then((res) => {
                    //console.log(res);
                    var data = res.data;
                    if (data.status_code == 200) {
                        this.test_data = data.data;
                        //console.log("test_data");
                        //console.log(this.test_data);
                        if (dialog_type == 1) {
                            this.showCeshiDialog();
                        } else {
                            this.showJisuDialog(); //极速测试
                        }
                    } else if (res.data.status_code == 202) {
                    } else {
                        //this.$message(res.data.msg)
                    }
                })
                .catch((err) => {
                    //this.loading = false
                });
        },
        getWordsCuociGonggu(words_ids) {
            let than = this;
            this.loading = true;
            let params = {
                token: this.token,
                type: this.type,
                words_ids: words_ids,
                study_type: this.study_type,
            };
            //console.log(params);
            this.axios
                .post("/api/course/get_word", params, {
                    loading: true,
                })
                .then((res) => {
                    //console.log(res);
                    var data = res.data;
                    if (data.status_code == 200) {
                        this.allWords = data.data.all_lists;
                        this.currentWords = data.data.lists;
                        this.showCuociGongguDialog();
                    } else if (res.data.status_code == 202) {
                    } else {
                        //this.$message(res.data.msg)
                    }
                })
                .catch((err) => {
                    //this.loading = false
                });
        },
        getUserInfo() {
            let than = this;
            this.loading = true;
            //let danyuan_ids = this.danyuanCheckStr;
            let params = {
                token: this.token,
            };
            //console.log(params);
            this.axios
                .post("/api/user/get_user_info", params)
                .then((res) => {
                    let data = res.data;
                    //console.log("get_user_info");
                    console.log(data, "用户信息");
                    this.avatarImg = data.data.avatar
                    this.trueName = data.data.truename
                    if (data.status_code == 200) {
                        //console.log(data.data);
                        this.$store.commit("setUser", data.data);
                    } else if (res.data.status_code == 202) {
                    } else {
                        //this.$message(res.data.msg)
                    }
                })
                .catch((err) => {
                    //this.loading = false
                });
        },
        clkFuxiNav(item, type) {
            if (type != -1) {
                this.study_type = 2;
                this.review_type = 1; ////复习类型，1复习，2一键复习
                this.type = type + 1;
                if (item.count > 0) {
                    this.getWords();
                    //console.log(item);
                }
            } else {
                this.study_type = 2;
                this.review_type = 2;
                var fuxi_item = this.getNextFuxiItem(0);
                if (fuxi_item != "") {
                    this.type = fuxi_item.id;
                    this.setNextFuxiItem(this.type);
                    this.getWords();
                }
                //console.log(fuxi_item);
            }
        },
        getNextFuxiItem(index) {
            //一键智能复习
            for (var i = 0; i < this.nav_fuxi.length; i++) {
                if (this.nav_fuxi[i].count > 0 && this.nav_fuxi[i].id > index) {
                    //console.log("index", this.nav_fuxi[i].id);
                    return this.nav_fuxi[i];
                }
            }
            return "";
        },
        setNextFuxiItem(index, type = -1) {
            var fuxi_item = this.getNextFuxiItem(index);
            if (fuxi_item != "") {
                this.nextFuxiType = fuxi_item;
                this.nextFuxiType.last_type = type;
            } else {
                this.nextFuxiType = {
                    id: 1,
                    name: "无",
                    count: 0,
                    last_type: -1,
                };
            }
        },
        setCourseStatus() {
            let data = { token: this.token, course_id: this.thisCourseId };
            this.axios
                .post("/api/course/set_course_status", data)
                .then((res) => {
                    //console.log(res);
                    var data = res.data;
                    if (data.status_code == 200) {
                    } else if (res.data.status_code == 202) {
                    } else {
                        //this.$message(res.data.msg)
                    }
                })
                .catch((err) => {
                    //this.loading = false
                });
        },
        setXuexiJilu() {
            //设置学习信息
            let danyuan_info = this.courseDanyuanLists[this.thisDanyuanIndex];
            this.currentDanyuan = danyuan_info;
            let jindu = danyuan_info.jindu;
            jindu.forEach((item, index) => {
                this.xuexi_jilu[index].is_tast = item.is_tast
                this.xuexi_jilu[index].score = item.fen;
                this.xuexi_jilu[index].jindu = item.word_num
                        ? (item.right_word_num / item.word_num) * 100
                        : 0;
                this.xuexi_jilu[index].word_num = danyuan_info.word_num;
                this.xuexi_jilu[index].right_word_num = item.right_word_num;
                this.xuexi_jilu[index].is_xuewan = item.is_xuewan;
                this.xuexi_jilu[index].ceshi_num = item.ceshi_num;
                this.xuexi_jilu[index].ks_level = item.ks_level;
            });

            let liju_jindu = danyuan_info.liju_jindu;

            liju_jindu.forEach((item, index) => {
                this.sentence[index].is_tast = item.is_tast
                this.sentence[index].score = item.fen;
                this.sentence[index].jindu = item.word_num
                        ? (item.right_word_num / item.duanyu_num
                ) * 100
                        : 0;
                this.sentence[index].total = danyuan_info.duanyu_num;
                this.sentence[index].has = item.right_word_num;
                this.sentence[index].is_xuewan = item.is_xuewan;
                this.sentence[index].ceshi_num = item.ceshi_num;
                this.sentence[index].ks_level = item.ks_level;
            });
        },
        checkCourse(item) {
            //选择课程
            //console.log(item);
        },
        checkDanyuan(item, index) {
            //选择单元
            this.thisDanyuanId = item.id;
            this.thisDanyuanIndex = index;
            this.setXuexiJilu();
            //this.getWords();//获取单词
            //console.log(this.courseDanyuanLists[this.thisDanyuanIndex]);
        },
        clkStudyModel(index) {
            //this.xuexi_jilu_index = index;
        },
        clkCourseJindu(index) {
            this.course_jilu_now = this.course_jilu[index];
        },
        anewStudy(index) {
            //console.log("ok");
            let name = this.course_jilu[index].name;
            this.$confirm("确定要重新学习“" + name + "”吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.anewStudyDo(index + 1);
            }).catch(() => {
            });
        },
        anewStudyDo(type) {
            let data = {
                token: this.token,
                course_id: this.thisCourseId,
                type: type,
            };
            this.axios
                .post("/api/course/anew_study", data)
                .then((res) => {
                    //console.log(res);
                    var data = res.data;
                    if (data.status_code == 200) {
                        this.getDanyuan(1);
                    } else if (res.data.status_code == 202) {
                    } else {
                        this.$message.error({
                            message: res.data.msg,
                            offset: 300,
                        });
                    }
                })
                .catch((err) => {
                    //this.loading = false
                });
        },
        anewLearn(index,word_type=1) {
            let name = this.courseDanyuanLists[this.thisDanyuanIndex].name;
            //let name = this.course_jilu[index].name;
            this.$confirm("确定要重新学习“" + name + "”吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.anewLearnDo(index + 1, word_type);
                })
                .catch(() => {
                });
        },
        anewLearnDo(type, word_type) {
            console.log("thisCourseId:" + this.thisCourseId + "thisDanyuanId：" + this.thisDanyuanId);
            let data = {
                token: this.token,
                course_id: this.thisCourseId,
                danyuan_id: this.thisDanyuanId,
                type: type,
                word_type: word_type
            };
            this.axios
                .post("/api/course/anew_learn", data)
                .then((res) => {
                    //console.log(res);
                    var data = res.data;
                    if (data.status_code == 200) {
                        this.getDanyuan(1);
                    } else if (res.data.status_code == 202) {
                    } else {
                        this.$message.error({
                            message: res.data.msg,
                            offset: 300,
                        });
                    }
                })
                .catch((err) => {
                    //this.loading = false
                });
        },
        danciMokuai(item, index) {
            this.study_type = 1;
            this.type = ++index;
            if (item.is_xuewan == 1 && item.score != 100) {
                //this.getWords();
                this.getTestWords();
            } else if (item.is_xuewan == 1 && item.score == 100) {
                this.ks_level = item.ks_level;
                this.chooseVisible = true;
            } else {
                this.getWords();
            }
        },
        showDialog() {
            switch (this.type) {
                case 1:
                    this.dialogVisible = true;
                    this.xuexi_jilu_index = index;
                    break;
                case 2:
                    this.ciVisible = true;
                    this.xuexi_jilu_index = index;
                    break;
                case 3:
                    this.writeVisible = true;
                    this.xuexi_jilu_index = index;
                    break;
                case 4:
                    this.diVisible = true;
                    this.xuexi_jilu_index = index;
                    break;
                default:
                    break;
            }
        },
        showJisuDialog() {
            this.speedVisible = true;
        },
        speedChallengeClose(type = 0) {
            let that = this;
            if (type == 2) {
                this.speedVisible = false;
                setTimeout(function () {
                    //that.speedVisible = true;//重新测试
                    that.chooseModeClose(2);
                }, 100);
            }
            if (type == 3) {
                this.speedVisible = false;
                setTimeout(function () {
                    //that.speedVisible = true;//极速挑战
                    that.chooseModeClose(3);
                }, 100);
            } else {
                this.speedVisible = false;
            }
            this.getDanyuan(1);
            this.getFuxi();
            this.getUserInfo();
        },
        handleClose(id, is_close = 0, close_type = 0) {
            //close_type 1 关闭并测试，2智能复习关闭并到下一个复习
            switch (id) {
                case 1:
                    this.$refs.dialog_jiyi.closeDialog();
                    break;
                case 2:
                    this.$refs.dialog_citing.closeDialog();
                    break;
                case 3:
                    //console.log("dialog_tingxie");
                    this.$refs.dialog_tingxie.closeDialog();
                    break;
                case 4:
                    //console.log("dialog_moxie");
                    this.$refs.dialog_moxie.closeDialog();
                    break;
                case 5:
                    break;
            }

            if (is_close == 1) {
                this.dialogVisible = false;
                this.ciVisible = false;
                this.writeVisible = false;
                this.diVisible = false;
            }
            //console.log("跳转到测试" + is_goto_test);
            if (is_close == 1 && close_type == 1) {
                this.getTestWords();
            } else if (is_close == 1 && close_type == 2) {
                //继续复习
                if (this.nextFuxiType.count > 0) {
                    var fuxi_item = this.getNextFuxiItem(this.type);
                    if (fuxi_item != "") {
                        this.type = fuxi_item.id;
                        this.setNextFuxiItem(this.type, this.type);
                        this.getWords();
                    }
                }
            }

            this.getDanyuan(1);
            this.getFuxi();
            this.getUserInfo();
        },
        showCeshiDialog() {
            this.test_type = 6;
            //显示弹窗
            let test_data = this.test_data;
            //console.log(test_data);
            if (this.type == 1) {
                this.reTestVisible = true;
                //this.$refs.dialog_jiyi_ceshi.initData(test_data);
            } else if (this.type == 2) {
                this.liTestVisible = true; //词听
                //this.$refs.dialog_citing_ceshi.initData(test_data);
            } else if (this.type == 3) {
                this.wrTestVisible = true; //听写
                //this.$refs.dialog_tingxie_ceshi.initData(test_data);
            } else if (this.type == 4) {
                this.diTestVisible = true; //默写
                //this.$refs.dialog_moxie_ceshi.initData(test_data);
            }
        },
        ceshiClose() {
            //关闭弹窗
            this.reTestVisible = false;
            this.liTestVisible = false; //词听
            this.wrTestVisible = false; //听写
            this.diTestVisible = false; //默写
            this.getDanyuan(1);
            this.getFuxi();
            this.getUserInfo();
            //this.getWords();
        },
        againTest(){
            //关闭弹窗
            this.reTestVisible = false;
            this.liTestVisible = false; //词听
            this.wrTestVisible = false; //听写
            this.diTestVisible = false; //默写
            setTimeout(()=>{
                this.getTestWords();
            },300);
        },
        changeClose() {
            this.coVisible = false;
        },
        chooseModeClose(level) {
            //console.log("level", level);
            this.chooseVisible = false;

            //选择项
            if (level == 1) {
                this.ks_level = level;
                this.test_type = 6; //普通模式
                this.getTestWords();
            } else if (level == 2) {
                this.ks_level = level;
                this.test_type = 7; //极速挑战
                this.getTestWords(2);
            } else if (level == 3) {
                this.ks_level = level;
                this.test_type = 8; //终极挑战
                this.getTestWords(2);
            }
        },
        cuociGonggu(errorLists) {
            //console.log("errorLists", errorLists);
            this.study_type = 3;
            this.getWordsCuociGonggu(errorLists);
        },
        showCuociGongguDialog() {
            //显示弹窗
            if (this.type == 1) {
                this.dialogVisible = true;
            } else if (this.type == 2) {
                this.ciVisible = true; //词听
            } else if (this.type == 3) {
                this.writeVisible = true; //听写
            } else if (this.type == 4) {
                this.diVisible = true; //默写
            }
        },
        changCourse(id) {
            this.coVisible = false;
            this.thisCourseId = id;
            let than = this;
            this.courseDingyueLists.forEach((item, index) => {
                if (item.id == than.thisCourseId) {
                    //console.log("id" + item.id + "course-id" + than.thisCourseId);
                    than.currentCourse = than.courseDingyueLists[index];
                    //console.log("currentCourse");
                    //console.log(than.currentCourse);
                }
            });
            this.getDanyuan();
            this.setCourseStatus();
        },
        avatarClose(type = 0) {
            this.avatarVisible = false;
            if (type === 1) {
                this.getUserInfo();
            }
        },
        display() {
            this.isDisplay = !this.isDisplay;
        },
        Close(done) {
            this.$confirm("确认关闭？")
                .then((_) => {
                    done();
                })
                .catch((_) => {
                });
        },
        caretChange() {
            this.caretShow = !this.caretShow;
        },
        toTestCenter() {
            this.$router.push({
                name: "study_test_center",
                query: {
                    course_id: this.currentCourse.id,
                    banben_id:this.currentCourse.banben_id,
                    course_name: this.currentCourse.name
                },
            });
        },
        progressDisplay() {
            //console.log(111);
            if ($(".t-switch").hasClass("is-checked")) {
                $(".s-progress").css("visibility", "hidden");
            } else {
                $(".s-progress").css("visibility", "visible");
            }
        },
        getNotice() {
            this.axios.get('/api/xmy_notice/study_mission', {
                params: {
                    token: this.token
                }
            }).then(res => {
                console.log('通知公告：', res.data.data)
                this.testData = res.data.data
                this.noticeData = res.data.data.courseList
                this.noTestData = res.data.data.beforeCourseList
                console.log('未完成任务：', this.noticeData.length === 0 && this.noTestData.length > 0)
                if (this.noticeData.length > 0) {
                    if(this.noticeCount == 0){
                        this.noticeVisible = true
                    }
                    this.$store.commit("setNoticeCount",this.user.id);
                }
            })
        },
        getErrorWordCount(){
            this.axios
                    .post('/api/course/get_error_word_count', {
                        token: this.token
                    })
                    .then(res => {
                        let data = res.data.data;
                        this.$store.commit("setErrerWordCount",{uid:this.user.id,errorWordCount:data.error_word_count});
                        if(data.error_word_count == 0){
                            
                        }
                        console.log("data",data);
                    })
        },
        // scroll() {
        //     this.animate = true
        //     const slides = this.$refs.slides
        //     setTimeout(() => {
        //         let width = slides.clientWidth
        //         let marginWidth = width + 100
        //         slides.style.marginLeft = '-' + marginWidth + 'px'
        //     }, 500)
        //     setTimeout(() => {
        //         slides.style.marginLeft = '0'
        //         this.animate = false
        //     }, 600000)
        // }
    }
}
</script>

<style lang="scss">
@import "~@/assets/css/ai_word.scss";

@media (min-width: 12rem) {

    .container,
    .header {
        width: 11.7rem;
    }
}
@keyframes changeOpacity {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 100%;
    }
    50% {
        opacity: 0;
    }
    75% {
        opacity: 100%;
    }
    100% {
        opacity: 0;
    }

}
.notice-tips {
    .course-name {
        margin-left: 20px;
    }
    .el-divider--vertical {
        height: 2em;
    }
    &:first-child {
        .course-name {
            margin-left: 0;
        }
    }
}
.notice-tips__txt {
    animation: changeOpacity 4s infinite linear !important;
}

.dialog-title {
    display: flex;
    &__data {
        flex: 1;
        display: flex;
        align-items: center;
        text-align: left;
    }
    &__time, &__person {
        padding: 2px 8px;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        background-color: #fff;
        img {
            margin-right: 5px;
            width: 20px;
            height: 20px;
            vertical-align: -4px;
        }
    }
    &__person {
        margin-left: 6px;
    }
    &__header {
        flex: 1;
        text-align: left;
    }
}
.large-circle>.el-progress-circle {
    width: 1.2rem !important;
    height: 1.2rem !important;
}

.small-circle>.el-progress-circle {
    width: 0.88rem !important;
    height: 0.88rem !important;
}

.video-dialog {
    &>.el-dialog {
        .el-dialog__body {
            height: 400px;
        }

        .el-dialog__header {
            padding: 0;
            height: 0;
        }
    }
}
.notice-dialog {
    .el-dialog {
        border: 18px solid #99C1F1;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
     .el-dialog__header {
        padding: 0;
        height: 0;
         border-bottom: none;
         //border-top-left-radius: 10px;
         //border-top-right-radius: 10px;
    }
    .el-dialog__body {

        border-bottom-left-radius: 8px;
        border-top-right-radius: 8px;
    }
}
.wrong-dialog {
    .el-dialog__header {
        height: 0;
    }
    .el-dialog__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 200px;
        border-radius: 8px;

        .el-button {
            margin-top: 40px;
        }
    }
    .el-dialog__footer {
        padding: 0;
        height: 0;
    }
}
</style>
