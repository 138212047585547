<template>
    <div>
        <div class="trans">
            <div class="trans-container">
                <div class="trans-img">
                    <img src="@/assets/images/study/congratulations.gif" alt="恭喜你">
                    <span class="trans-img__text">"<span>记忆</span>环节"复习完成，是否进入"<span>词听</span>环节"?</span>
                </div>
                <div class="trans-btn">
                    <el-button class="btn-check">是</el-button>
                    <el-button class="btn-error">否</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
.trans {
    width: 480px;
    height: auto;
    &-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #f6f6f6;

        .trans-img {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: auto;
            background-color: #fff;

            img {
                padding: 20px 0;
                width: 135px;
                height: 165px;
            }
            &__text {
                padding-bottom: 30px;
                color: #333;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 2px;
            }
        }
        .trans-btn {
            height: 68px;
            line-height: 68px;
            .el-buttton {
                border: 0 !important;
            }
            .btn-check {
                padding: 9px 25px;
                margin-right: 85px;
                color: #fff;
                border: 1px solid #2bc2a1;
                background: #2bc2a1;
                transition-duration: .4s;
                &:hover {
                    color: #2bc2a1;
                    background: #fff;
                }
            }
            .btn-error {
                padding: 9px 25px;
                color: #fff;
                border: 1px solid #f27130;
                background: #f27130;
                transition-duration: .4s;
                &:hover {
                    color: #f27130;
                    background: #fff;
                }
            }
        }
    }
}
</style>